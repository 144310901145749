import React, {useEffect, useLayoutEffect, useState} from "react";
import SCREENS from "../utils/Screens";
import {
    AppBar,
    Autocomplete,
    Box,
    Button,
    Container,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {updateSite} from "../graphql/base/mutations";
import {
    UpdateSiteMutation,
    UpdateSiteMutationVariables,
    Site,
} from "../@dexbasal";
import {ArrowBack} from "@mui/icons-material";
import {NavClickHandler, NetworkAlertHandler, SiteHandler} from "../utils/Interfaces";
import NetworkError from "./Alerts/NetworkError";
import {checkUniqueSiteDisplayId, UNIQUE_ID} from "../utils/UniqueId";
import {trackError, trackScreen} from "../analytics/ReactAnalytics";
import DexbasalAPI from "../api/DexbasalApi";
import {
    DEFAULT_TIMEZONES,
    getBrowserTimezone,
    isValidTimezone,
} from "../utils/Timezones";

interface IProps {
    api: DexbasalAPI,
    debugApi: boolean,
    maxHeight: number,
    networkError: boolean,
    onNavClick: NavClickHandler,
    onNetworkError: NetworkAlertHandler,
    onSiteUpdate: SiteHandler,
    site: Site | undefined,
}

const UpdateSite: React.FunctionComponent<IProps> = (props) => {
    const {api, debugApi, maxHeight, networkError, onNavClick, onNetworkError: handleNetworkError, onSiteUpdate} = props;
    const [site, setSite] = useState<Site|undefined>(props.site)
    const [formTimezone, setFormTimezone] = useState<string>(props.site ? props.site.timezone : getBrowserTimezone())
    const [validTimezone, setValidTimezone] = useState<boolean>(true)
    const [activeStep, setActiveStep] = useState<number>(0)
    const [displayId, setDisplayId] = useState<string>(props.site ? props.site.displayId : "");
    const [validDisplayId, setValidDisplayId] = useState<boolean>(false);
    const [uniqueDisplayId, setUniqueDisplayId] = useState<UNIQUE_ID>(UNIQUE_ID.UNKNOWN);
    const [displayIdHelperText, setDisplayIdHelperText] = useState<string>(
      'Minimum of 4 characters.'
    )

    useEffect(() => {
        trackScreen({screen: SCREENS.UPDATE_SITE})
    }, [])

    const update = (formEvent: HTMLButtonElement) => {
        // do nothing with invalid data
        if (!validDisplayId || !(uniqueDisplayId === UNIQUE_ID.UNIQUE)) {
            return false
        }

        if (!props.site) {
            return false
        }

        let variables: UpdateSiteMutationVariables = {
            input: {
                id: props.site.id,
                displayId: displayId,
                timezone: formTimezone,
            }
        }

        const query = api.invoke<UpdateSiteMutation, UpdateSiteMutationVariables>(updateSite, variables)
        query.then((response) => {
            handleNetworkError(false)
            const updatedSite = response.data.updateSite!;
            onSiteUpdate(updatedSite)
            setSite(updatedSite)
            setActiveStep(activeStep + 1)
        }).catch((e) => {
            formEvent.disabled = false;

            if(debugApi) {
                console.error('Error during updateSite from updateSite.', e);
            }
            handleNetworkError(true)
            trackError("updateSite", {function: "updateSite"});
        })
    }

    const steps = [
        {
            label: 'Edit Site\'s Display ID',
            content: <Box>
                <TextField
                    fullWidth
                    autoFocus
                    id={'displayId'}
                    label={'Site display ID'}
                    type={'text'}
                    margin={'dense'}
                    error={!validDisplayId}
                    helperText={displayIdHelperText}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                        setDisplayId(e.target.value)
                        setUniqueDisplayId(UNIQUE_ID.UNKNOWN)
                    }}
                    value={displayId}
                />
                <Button
                    disabled={!validDisplayId || uniqueDisplayId === UNIQUE_ID.CHECKING}
                    variant={'contained'}
                    onClick={async () => {
                        if(uniqueDisplayId === UNIQUE_ID.CHECKING) {
                            return;
                        }
                        setUniqueDisplayId(UNIQUE_ID.CHECKING);
                        const isUnique = await checkUniqueSiteDisplayId(displayId, handleNetworkError, debugApi);
                        if((isUnique || site?.displayId === displayId) && validDisplayId) {
                            // Set unique flag and move to next step
                            setUniqueDisplayId(UNIQUE_ID.UNIQUE)
                            setActiveStep(activeStep + 1)
                        } else {
                            // Update the form
                            setUniqueDisplayId(UNIQUE_ID.DUPLICATE);
                            setDisplayIdHelperText('This display ID already exists!  Please modify the text.')
                        }
                    }}
                    >
                    Next
                </Button>
            </Box>
        },{
            label: 'Select Timezone',
            content: <Box>
                <Autocomplete freeSolo
                              sx={{ mb: 2 }}
                              renderInput={(params) => <TextField {...params} label="Time Zone" />}
                              onChange={(event: any, newValue) => {
                                  if (newValue) {
                                      setFormTimezone(newValue)
                                      setValidTimezone(isValidTimezone(newValue))
                                  }
                              }}
                              value={formTimezone}
                              onInputChange={(event: any, newValue) => {
                                  setFormTimezone(newValue)
                                  setValidTimezone(isValidTimezone(newValue))
                              }}
                              options={DEFAULT_TIMEZONES}/>
                <Button
                    disabled={!validTimezone}
                    variant={'contained'}
                    onClick={(event) => {
                        event.currentTarget.disabled = true
                        update(event.currentTarget)
                        onNavClick(SCREENS.MANAGE_SITES)
                    }}>
                    Update Site
                </Button>
                <Button
                    variant={'outlined'}
                    sx={{
                        marginLeft: 2,
                    }}
                    onClick={() => {
                        setActiveStep(activeStep - 1)
                    }}
                >Back</Button>
            </Box>
        },
    ]

    useLayoutEffect(() => {
        setValidDisplayId((displayId.length >= 4) && (uniqueDisplayId !== UNIQUE_ID.DUPLICATE))
        if(uniqueDisplayId !== UNIQUE_ID.DUPLICATE) {
            setDisplayIdHelperText('Minimum of 4 characters.')
        }

    }, [displayId, setValidDisplayId, uniqueDisplayId])

    return (
        <Container disableGutters={true} maxWidth={false}>
            <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
                <Toolbar>
                    <Button
                        id={'backButton'}
                        variant={'outlined'}
                        color={'inherit'}
                        sx={{marginRight: 2}}
                        startIcon={<ArrowBack/>}
                        onClick={() => onNavClick(SCREENS.MANAGE_SITES)}>
                        Back</Button>
                    <Typography variant={'h6'} sx={{flexGrow: .85}}>
                        {SCREENS.UPDATE_SITE} {site && site.displayId}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container component={'form'} maxWidth={'xs'} sx={{marginTop: 2}} style={{maxHeight: maxHeight === -1 ? 'none' : maxHeight, overflow:'auto'}}>
                <Stepper activeStep={activeStep} orientation={'vertical'}>
                    {steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>
                                {step.content}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>

                <NetworkError show={networkError}/>
            </Container>
        </Container>
    )

}

export default UpdateSite
