import {API} from "aws-amplify";
import {
  GraphQLResponse,
  SitesByDisplayIdQuery,
  SitesByDisplayIdQueryVariables,
  SubjectsByDisplayIdQuery,
  SubjectsByDisplayIdQueryVariables
} from "../@dexbasal";
import {sitesByDisplayId, subjectsByDisplayId} from "../graphql/base/queries";
import {NetworkAlertHandler,} from "./Interfaces";
import {trackError} from "../analytics/ReactAnalytics";

export enum UNIQUE_ID {
  UNKNOWN,
  CHECKING,
  UNIQUE,
  DUPLICATE
}

export async function checkUniqueDisplayId(
  displayId: string,
  networkError: NetworkAlertHandler,
  debugApi: boolean
):Promise<boolean> {
  let variables:SubjectsByDisplayIdQueryVariables = {
    displayId: displayId
  }

  const query = API.graphql({query: subjectsByDisplayId, variables}) as Promise<GraphQLResponse<SubjectsByDisplayIdQuery>>
  return await query.then((response) => {
    networkError(false);

    return !(response.data == null || response.data.subjectsByDisplayId == null || response.data.subjectsByDisplayId.items.length > 0);
  }).catch((e) => {
    if(debugApi) {
      console.error('Error during subjectsByDisplayId from checkDisplayIdForUniqueness.', e);
    }
    networkError(true);
    trackError("subjectsByDisplayId", {function: "checkUniqueDisplayId"});

    return false;
  })
}

export async function checkUniqueSiteDisplayId(
    displayId: string,
    networkError: NetworkAlertHandler,
    debugApi: boolean
):Promise<boolean> {
  let variables:SitesByDisplayIdQueryVariables = {
    displayId: displayId
  }

  const query = API.graphql({query: sitesByDisplayId, variables}) as Promise<GraphQLResponse<SitesByDisplayIdQuery>>
  return await query.then((response) => {
    networkError(false);

    return !(response.data == null || response.data.sitesByDisplayId == null || response.data.sitesByDisplayId.items.length > 0);
  }).catch((e) => {
    if(debugApi) {
      console.error('Error during sitesByDisplayId from checkDisplayIdForUniqueness.', e);
    }
    networkError(true);
    trackError("sitesByDisplayId", {function: "checkUniqueSiteDisplayId"});

    return false;
  })
}
