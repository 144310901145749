import React, {useEffect, useState} from "react";
import {
    GetApiRedirectMutation,
    GetApiRedirectMutationVariables,
    GetSubjectQuery,
    GetSubjectQueryVariables,
    Subject
} from "../../@dexbasal";
import {Button} from "@mui/material";
import {getApiRedirect} from "../../graphql/base/mutations";
import {getSubject} from "../../graphql/base/queries";
import {NetworkAlertHandler, SubjectHandler} from "../../utils/Interfaces";
import {trackError} from "../../analytics/ReactAnalytics";
import DexbasalAPI from "../../api/DexbasalApi";

interface IProps {
    subject?: Subject,
    onNetworkAlert: NetworkAlertHandler
    displayText?: string,
    onSubjectUpdate: SubjectHandler,
    debugApi: boolean,
    api: DexbasalAPI
}

const DexcomRegistration: React.FunctionComponent<IProps> = (props) => {
    const {api} = props;
    const defaultDisplayText = props.displayText || 'Register';
    const [enabled, setEnabled] = useState<boolean>(props.subject ? (props.subject.apiStatus !== "CONFIRMED") : false)
    const [displayText, setDisplayText] = useState<string>(defaultDisplayText)
    const [subject, setSubject] = useState<Subject|undefined>(props.subject)

    useEffect(() => {
        setSubject(props.subject)
    }, [props.subject])

    const onRegistrationClick = () => {
        if (!subject) {
            return false
        }

        props.onNetworkAlert(false);
        setEnabled(false)
        setDisplayText('Please wait...')

        const printErr = (e: Error) => {
            props.onNetworkAlert(true);
            if(props.debugApi) {
                console.error(e, e.stack);
            }
            setEnabled(true);
            trackError("networkError", {function: "onRegistrationClick", name: e.name, message: e.message});
            setDisplayText(defaultDisplayText);
        }

        api.invoke<GetApiRedirectMutation, GetApiRedirectMutationVariables>(getApiRedirect, { id: subject.id, returnUrl: window.location.href.split('/').slice(2).shift(), debug: props.debugApi}).then((redirectResult) => {
            if(redirectResult.data?.getApiRedirect && redirectResult.data?.getApiRedirect !== "error") {
                api.invoke<GetSubjectQuery, GetSubjectQueryVariables>(getSubject, {id: subject.id}).then((subjectResult) => {
                    if(subjectResult.data?.getSubject && redirectResult.data?.getApiRedirect) {
                        props.onSubjectUpdate(subjectResult.data?.getSubject);
                        window.location.href = redirectResult.data?.getApiRedirect;
                    } else {
                        console.error(subjectResult);
                        printErr(new Error('failed to redirect after getting updated Subject'));
                    }
                }).catch((e) => printErr(e));
            } else {
                console.error(redirectResult)
                printErr(new Error('getApiRedirect failed'));
                return;
            }
        }).catch((e) => printErr(e));
    }

    return (
      <Button
        disabled={!enabled}
        variant={'contained'}
        onClick={onRegistrationClick}
      >
          {displayText}
      </Button>
    )

}

export default DexcomRegistration
