import React from "react";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import {Stack} from "@mui/material";

interface IProps {
  length: number,
  index: number
}

const Dots: React.FunctionComponent<IProps> = (props) => {
  const size = 11;

  const renderDots = () => {
    let dots = [];

    for(let i = 0; i < props.length; i++) {
      if(i === props.index) {
        dots.push(<FiberManualRecordIcon color={'action'} key={i} sx={{fontSize: size}}/>)
      } else {
        dots.push(<FiberManualRecordOutlinedIcon color={'action'} key={i} sx={{fontSize: size}}/>)
      }
    }

    dots = dots.reverse()
    return dots
  }

  return (
    <Stack
        direction={'row'}
        spacing={4}
        alignItems={'center'}
        justifyContent={'center'}
        mt={2}>
      {renderDots()}
    </Stack>
  );
}

export default Dots
