import {
  AlgorithmVersion,
  ApiStatus,
  Egv,
  GlucoseUnit,
  Recommendation,
  ResultReason,
  Subject,
  TitrationStatus,
  TitratorResultsSource
} from "../@dexbasal";

export function mgdlToMmol (value: number | null | undefined) {
   if (value !== null && value !== undefined) {
       return mgdlToMmolSafe(value);
   } else {
       return value
   }
}

export function convertEgvSafe(mgdlValue: number, unit: GlucoseUnit) {
  return unit === GlucoseUnit.MGDL ? mgdlValue : mgdlToMmolSafe(mgdlValue);
}

export function mgdlToMmolSafe(value: number) {
  return parseFloat((value / 18.02).toFixed(1))
}

export function transformMgdlEgvRecordToMmol (egvRecord: Egv): Egv {
  let mmolValues;
  if(egvRecord.unit === "mg/dL" && egvRecord.value !== null && egvRecord.value !== undefined
  ) {
    mmolValues = {
      unit: "mmol/L",
      value: mgdlToMmol(egvRecord.value)
    }
    if(egvRecord.trendRate !== null && egvRecord.trendRate !== undefined) {
      mmolValues = {
        trendRate: mgdlToMmol(egvRecord.trendRate),
        rateUnit: "mmol/L/min",
        ...mmolValues
      }
    }
  } else {
    mmolValues = {
      unit: egvRecord.unit,
      value: egvRecord.value,
      trendRate: egvRecord.trendRate,
      rateUnit: egvRecord.rateUnit
    }
  }
    return {...egvRecord,...mmolValues}
}

export function utcSecondsSinceEpoch(d?: Date) {
  const now = d || new Date()
  const utcMillisecondsSinceEpoch = now.getTime();
  return Math.round(utcMillisecondsSinceEpoch / 1000);
}

export function timezoneOffsetInSeconds(d: Date) {
  return d.getTimezoneOffset() * 60
}

/**
 * this should only be used to create startDate or endDate parameters for
 * outgoing Dexcom API calls. https://developer.dexcom.com/get-egvs
 * @param time in UTC second since epoch
 * @return String in the form 2021-07-02T13:06:53
 */
export function dateTimeFromUtcSecondsSinceEpoch(time: number): string {
  const date = new Date(time*1000);
  // 2021-07-02T13:06:53.422Z -> 2021-07-02T13:06:53
  return date.toISOString().slice(0, -5);
}

/**
 * this should only be used for mock functions that need to mimic the form in
 * which v3 API returns SystemTime
 * @param time in UTC second since epoch
 * @return String in the form 2022-02-09T15:46:48Z
 */
export function utcSystemTimeFromUtcSecondsSinceEpoch(time: number): string {
  const date = new Date(time*1000);
  return date.toISOString().slice(0, -5) + "Z";
}

/**
 * this should only be used for mock functions that need to mimic the form in
 * which v3 API returns DisplayTime
 * @param time in UTC second since epoch
 * @return String in the form 2022-02-01T16:30:02+01:00
 */
export function displayTimeFromUtcSecondsSinceEpoch(time: number): string {
  const date = new Date(time*1000);
  return date.toISOString().slice(0, -5) + "+01:00";
}

/**
 * this should only be used for mock functions that need to mimic the form in
 * which v3 API returns dataRange returns systemTime and displayTime
 * @param time in UTC second since epoch
 * @return String in the form 2022-02-01T16:30:02
 */
export function dataRangeTimeFromUtcSecondsSinceEpoch(time: number): string {
  return dateTimeFromUtcSecondsSinceEpoch(time);
}

// this should only be called for Dexcom systemTime because that is in UTC
export function dateTimeStringToSecondsSinceEpoch(dateTime?: string | null): number {
  if(dateTime) {
    return Math.round(new Date(dateTime).getTime() / 1000);
  }
  return 0;
}

export function dateTimeStringToSecondsSinceEpochAws(dateTime: string): number {
  return Math.round(new Date(dateTime).getTime() / 1000)
}

export function threeDayWindowOrGreater(time?: number | null) {
  const minWindow = utcSecondsSinceEpoch() - 60 * 60 * 24 * 3;
  if (time && time < minWindow) {
    return time;
  }
  return minWindow;
}

export function getRecommendation(subject: Subject): Recommendation | undefined {
  if (subject.recommendations && (subject.recommendations?.items !== null && subject.recommendations?.items?.length > 0)) {
    if (subject.recommendations.items[0] === null) {
      return undefined
    } else {
      return subject.recommendations.items[0];
    }
  }
  return undefined;
}

/**
 * evaluate if a recommendation review is needed based on if there is a timeConfirmed
 * @param recommendation to evaluate
 */
export function recommendationNeedsReview(recommendation: Recommendation | undefined): boolean {
  if(isOptimizeWarmup(recommendation)) {  //If we're in warmup, it's really not a reviewable recommendation
    return false
  } else {
    return recommendation !== undefined && !(typeof recommendation.timeConfirmed === "number")
  }
}

const isOptimizeWarmup = (recommendation: Recommendation | undefined) => {
  if(recommendation && recommendation.algorithm.version === AlgorithmVersion.OPTIMIZE_V_1) {
    const terminated = recommendation.reasons ? recommendation.reasons.filter(r => r === ResultReason.TERMINATED).length > 0 : false
    const nullDose = recommendation.baseDose === null
    if(!terminated && nullDose && (recommendation.resultSource !== TitratorResultsSource.SYSTEM_ERROR)) {
      return true
    }
  }
  return false
}

/**
 * get the appropriate review button action label given a recommendation
 * @param subject subject to evaluate for action label
 */
export function getReviewButtonActionLabel(subject: Subject): string {
  const recommendation = getRecommendation(subject);
  switch (subject.titrationStatus) {
    case TitrationStatus.TITRATING:
      if(subject.apiStatus === ApiStatus.PENDING) {
        return 'register with dexcom'
      }

      if(recommendation && isOptimizeWarmup(recommendation)) {
        return 'pending'
      }

      return recommendationNeedsReview(recommendation) ? 'review' : (recommendation ? 'confirmed' : 'pending')
    case TitrationStatus.COMPLETE:
      return recommendationNeedsReview(recommendation) ? 'review' : 'N/A'
    case TitrationStatus.DISQUALIFIED:
      return 'N/A'
  }
}

export function getVerbosity(searchParams: URLSearchParams): string {
  const verbosityParam = searchParams.get('amplify')
  if(verbosityParam) {
    const verbosityLevel = verbosityParam.toUpperCase().toString();
    if(verbosityLevel === "ERROR" || verbosityLevel === "WARN" || verbosityLevel === "INFO" || verbosityParam === "DEBUG" || verbosityLevel === "VERBOSE") {
      return verbosityLevel
    } else {
      console.log("Invalid Amplify logger parameter!")
      return "WARN";
    }
  }

  return "NONE"
}
