import {
    AppBar,
    Button,
    Container,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Toolbar,
    Typography
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import SCREENS from "../utils/Screens";
import {ArrowBack} from "@mui/icons-material";
import {addUserToGroup, createUser, getUser, sendWarningEmail, sendWelcomeEmail, setUserPassword} from "../utils/Staff";
import {Site} from "../@dexbasal";
import {UserHandler} from "../utils/Interfaces";

interface IProps {
    loadSites: () => void
    maxHeight: number
    onUserAdded: UserHandler
    onNavClick: (screen: SCREENS) => void
    staffGroups: string[]
    sites: Site[] | undefined
}

const isOperator = (group: string) => group === "DexBasalStaff"

const AddUser: React.FunctionComponent<IProps> = (props) => {
    const [registerGroup, setRegisterGroup] = useState('');
    const [registerEmail, setRegisterEmail] = useState('');
    const [registerError, setRegisterError] = useState('');
    const [wasRegistered, setWasRegistered] = useState('');
    const [selectedSiteId, setSelectedSiteId] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(true);

    const {onUserAdded, staffGroups, sites} = props;
    const loading = !sites;

    useEffect(() => {
        if(loading) {
            props.loadSites()
        }
    })

    const handleGroupSelect = useCallback((event: SelectChangeEvent) => {
        const group = event.target.value as string;
        setRegisterGroup(group);
        if(isOperator(group) && selectedSiteId) {
            setSelectedSiteId('');
        }
    }, [setRegisterGroup, setSelectedSiteId, selectedSiteId]);

    const handleRegisterEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegisterEmail(event.target.value as string);
    };

    const handleSiteSelect = (event: SelectChangeEvent) => {
        setSelectedSiteId(event.target.value as string);
    };

    const finishSignUp = useCallback((success = true) => {
        if(success) {
            const siteId = Array.isArray(sites)
                ? sites.filter(s => s.id === selectedSiteId).pop()!.displayId
                : selectedSiteId
            setWasRegistered(`${registerEmail}  ${registerGroup} ${siteId}`);
            setRegisterEmail('')
            setSelectedSiteId('')
            setRegisterGroup('')
        }
    }, [setWasRegistered, registerEmail, selectedSiteId, registerGroup, setSelectedSiteId, setRegisterGroup, sites])

    const onAdd = useCallback(async () => {
        let buttonStatus = buttonEnabled;
        if(buttonStatus) {
            buttonStatus = !buttonStatus;
            setButtonEnabled(buttonStatus)
        }
        if (registerError) setRegisterError('');
        if (wasRegistered) setWasRegistered('');
        let success = true;
        try {
            await createUser(registerEmail)
                .then(() => setUserPassword(registerEmail))
                .then(() => addUserToGroup(registerEmail, registerGroup))
                .then(() => isOperator(registerGroup) ? sendWarningEmail(registerEmail, registerGroup) : Promise.resolve())
                .then(() => sendWelcomeEmail({email : registerEmail, group : registerGroup, resetNeeded : true}))
                .then(() => getUser(registerEmail))
                .then((r) => onUserAdded(r, [registerGroup], selectedSiteId));
        } catch(e: any) {
            if('response' in e && 'data' in e.response && 'message' in e.response.data) {
                setRegisterError(e.response.data.message)
            } else {
                setRegisterError(String(e))
            }
            success = false;
        }
        if(!buttonStatus) {
            buttonStatus = !buttonStatus;
            setButtonEnabled(buttonStatus)
        }
        finishSignUp(success);
    }, [finishSignUp, registerEmail, registerGroup, selectedSiteId, registerError, onUserAdded, setButtonEnabled, buttonEnabled, wasRegistered])

    const renderSiteInputLabel = () => {
        let label = "Site"
        if((sites && sites.length === 0) && !isOperator(registerGroup)) {
            label = "Please Create a Site for Users"
        } else if(isOperator(registerGroup)){
            label = "No Site for Operators"
        }

        return (
          <InputLabel id="select-site-label">{label}</InputLabel>
        )
    }

    return (
        <Container disableGutters={true} maxWidth={false}>
            <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
                <Toolbar>
                    <Button
                        id={'backButton'}
                        variant={'outlined'}
                        color={'inherit'}
                        sx={{marginRight: 2}}
                        startIcon={<ArrowBack/>}
                        onClick={() => props.onNavClick(SCREENS.LIST_USERS)}>
                        Back</Button>
                    <Typography variant={'h6'} sx={{flexGrow: .85}}>
                        {SCREENS.ADD_USER}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container component={'form'} maxWidth={'xs'} sx={{marginTop: 2}} style={{maxHeight: props.maxHeight === -1 ? 'none' : props.maxHeight, overflow:'auto'}}>
                {registerError &&
                  <Typography variant="subtitle1" component="div">
                    Registration Error: {registerError}
                  </Typography>
                }
                {wasRegistered &&
                  <Typography variant="subtitle1" component="div">
                    Registered: {wasRegistered}
                  </Typography>
                }
                {loading ? (
                  [...Array(4)].map((_, idx) =>
                    <Stack key={idx} direction={'row'} sx={{marginTop: 2}}>
                        <Skeleton width={300} height={32} variant={'rectangular'} sx={{marginLeft: '16px', marginRight: '24px'}}/>
                    </Stack>
                  )
                ) : (
                      <Stack spacing={2} sx={{marginTop: 3, textAlign: 'left'}}>
                        <FormControl required={true}>
                            <InputLabel htmlFor="email-input">Email address</InputLabel>
                            <Input type="email" id="email-input" aria-describedby="email-helper-text" onChange={handleRegisterEmail} value={registerEmail} />
                            <FormHelperText id="email-helper-text">Authorized Email Only</FormHelperText>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="select-group-label">Group</InputLabel>
                            <Select
                              labelId="select-group-label"
                              id="select-group"
                              value={registerGroup}
                              label="Group"
                              onChange={handleGroupSelect}
                            >
                                {staffGroups.map(g => <MenuItem key={g} value={g}>{g}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl>
                            {renderSiteInputLabel()}
                            <Select
                              disabled={isOperator(registerGroup) || (sites.length === 0)}
                              labelId="select-site-label"
                              id="select-site"
                              value={selectedSiteId}
                              label="Site"
                              onChange={handleSiteSelect}
                            >
                                {sites.map(s => <MenuItem key={'Site'+s.displayId} value={s.id}>{s.displayId}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <Button
                          disabled={!registerGroup || (!selectedSiteId && !isOperator(registerGroup)) || !registerEmail || !buttonEnabled}
                          variant={'contained'}
                          onClick={async () => onAdd()}>
                            Add Staff
                        </Button>
                    </Stack>
                    )
                }
            </Container>
        </Container>
    )
}

export default AddUser
