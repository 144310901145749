import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import awsExports from "./aws-exports";
import awsmobile from "./aws-exports";
import {Amplify, Analytics, API, AWSKinesisFirehoseProvider, Hub, Auth} from "aws-amplify";
import {HubCapsule} from '@aws-amplify/core';
import {styled, ThemeProvider} from "@mui/material";
import {dexcomTheme} from "./theme";
import {Authenticator} from "@aws-amplify/ui-react";
import {getVerbosity} from "./utils/Utils";
import {readAuthEvents} from "./analytics/ReactAnalytics";
import {GraphQLAPI, GraphQLResponse} from "./@dexbasal";
import DexbasalAPI from "./api/DexbasalApi";
import {handleLogout, handleOnActive} from "./utils/IdleUtils";

const DEBUG = process.env.REACT_APP_DEBUG === "true" ? true : (new URLSearchParams(window.location.search)).get('debug') === 'true'
const AMPLIFY_VERBOSITY = getVerbosity(new URLSearchParams(window.location.search));
export const LAST_ACTIVE_TIME_KEY: string = "lastActiveTimeInSeconds"

if(DEBUG) {
  console.log('Debug Enabled');
  if(AMPLIFY_VERBOSITY !== "NONE") {
    console.log('Amplify Log Level: '+AMPLIFY_VERBOSITY);
    Amplify.Logger.LOG_LEVEL = AMPLIFY_VERBOSITY
  }
}
Amplify.configure(
  awsExports
);

Analytics.addPluggable(new AWSKinesisFirehoseProvider());
Analytics.configure({
  AWSKinesisFirehose: {
    region: awsmobile.aws_firehose_region
  }
})

Hub.listen('auth', (data: HubCapsule) => {
    readAuthEvents(data);
    switch (data.payload.event) {
        case 'signIn':
            handleOnActive()
            break;
        case 'signOut':
            handleLogout()
            break;
        default:
            break;
    }
});

const api: GraphQLAPI = (query, variables, authToken, userAgentSuffix) => {
  return API.graphql({query, variables, authToken, userAgentSuffix}) as Promise<GraphQLResponse<any>>
};

const dexbasalApi = new DexbasalAPI({api});

const StyledAuthenticator = styled(Authenticator)(() => {
    if(DEBUG) {
        return {
            '[data-state=inactive][role=tab]': {
                display: 'block'
            }
        }
    }
    return {
        '[data-state=inactive][role=tab]': {
            display: 'none'
        }
    }
});

/**
 * We can to catch if people close their browser and then are "idle" for longer than 10 minutes.
 */
function checkUserSilentTimeout() {
    const currentUnixTimeInMillis = Date.now();
    const lastTime = localStorage.getItem(LAST_ACTIVE_TIME_KEY);
    if (lastTime !== null) {
        const lastActiveTime = parseInt(lastTime);
        if ((currentUnixTimeInMillis - lastActiveTime) > 600000) {
            Auth.signOut();
        }
    }
}

/** we use this to manage if a user is logged in or not */
function AuthListenWrapper() {
    return <StyledAuthenticator initialState={'signIn'}>
        {({user}) => {
            checkUserSilentTimeout();
            return (
                <App user={user}
                     debugApi={DEBUG}
                     api={dexbasalApi}
                />
            )
        }}
    </StyledAuthenticator>
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
    <ThemeProvider theme={dexcomTheme}>
      <React.StrictMode>
          <AuthListenWrapper />
      </React.StrictMode>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
