/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../../@dexbasal/api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUserSite = /* GraphQL */ `query GetUserSite($id: ID!) {
  getUserSite(id: $id) {
    id
    user
    site
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserSiteQueryVariables,
  APITypes.GetUserSiteQuery
>;
export const listUserSites = /* GraphQL */ `query ListUserSites(
  $filter: ModelUserSiteFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user
      site
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSitesQueryVariables,
  APITypes.ListUserSitesQuery
>;
export const userSiteByUser = /* GraphQL */ `query UserSiteByUser(
  $user: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserSiteFilterInput
  $limit: Int
  $nextToken: String
) {
  userSiteByUser(
    user: $user
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      site
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserSiteByUserQueryVariables,
  APITypes.UserSiteByUserQuery
>;
export const getRecommendation = /* GraphQL */ `query GetRecommendation($id: ID!) {
  getRecommendation(id: $id) {
    id
    timeCreated
    timeCreatedOffset
    reasons
    adjustment
    baseDose
    timeConfirmed
    timeConfirmedOffset
    subjectId
    resultSource
    datasetRef {
      identifier
      s3_bucket
      s3_key
      datatype
      md5
      s3_version_id
      meta
      file_format
      __typename
    }
    algorithmId
    algorithm {
      id
      timeCreated
      version
      runnerVersion
      runner
      createdAt
      updatedAt
      __typename
    }
    explanation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRecommendationQueryVariables,
  APITypes.GetRecommendationQuery
>;
export const listRecommendations = /* GraphQL */ `query ListRecommendations(
  $filter: ModelRecommendationFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecommendations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      timeCreated
      timeCreatedOffset
      reasons
      adjustment
      baseDose
      timeConfirmed
      timeConfirmedOffset
      subjectId
      resultSource
      datasetRef {
        identifier
        s3_bucket
        s3_key
        datatype
        md5
        s3_version_id
        meta
        file_format
        __typename
      }
      algorithmId
      algorithm {
        id
        timeCreated
        version
        runnerVersion
        runner
        createdAt
        updatedAt
        __typename
      }
      explanation
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecommendationsQueryVariables,
  APITypes.ListRecommendationsQuery
>;
export const recommendationsBySubjectAndTimeCreated = /* GraphQL */ `query RecommendationsBySubjectAndTimeCreated(
  $subjectId: ID!
  $timeCreated: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRecommendationFilterInput
  $limit: Int
  $nextToken: String
) {
  recommendationsBySubjectAndTimeCreated(
    subjectId: $subjectId
    timeCreated: $timeCreated
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      timeCreated
      timeCreatedOffset
      reasons
      adjustment
      baseDose
      timeConfirmed
      timeConfirmedOffset
      subjectId
      resultSource
      datasetRef {
        identifier
        s3_bucket
        s3_key
        datatype
        md5
        s3_version_id
        meta
        file_format
        __typename
      }
      algorithmId
      algorithm {
        id
        timeCreated
        version
        runnerVersion
        runner
        createdAt
        updatedAt
        __typename
      }
      explanation
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RecommendationsBySubjectAndTimeCreatedQueryVariables,
  APITypes.RecommendationsBySubjectAndTimeCreatedQuery
>;
export const getAlgorithm = /* GraphQL */ `query GetAlgorithm($id: ID!) {
  getAlgorithm(id: $id) {
    id
    timeCreated
    version
    runnerVersion
    runner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAlgorithmQueryVariables,
  APITypes.GetAlgorithmQuery
>;
export const listAlgorithms = /* GraphQL */ `query ListAlgorithms(
  $filter: ModelAlgorithmFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlgorithms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      timeCreated
      version
      runnerVersion
      runner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlgorithmsQueryVariables,
  APITypes.ListAlgorithmsQuery
>;
export const algorithmsByVersionAndTimeCreated = /* GraphQL */ `query AlgorithmsByVersionAndTimeCreated(
  $version: AlgorithmVersion!
  $timeCreated: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAlgorithmFilterInput
  $limit: Int
  $nextToken: String
) {
  algorithmsByVersionAndTimeCreated(
    version: $version
    timeCreated: $timeCreated
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      timeCreated
      version
      runnerVersion
      runner
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AlgorithmsByVersionAndTimeCreatedQueryVariables,
  APITypes.AlgorithmsByVersionAndTimeCreatedQuery
>;
export const getSubject = /* GraphQL */ `query GetSubject($id: ID!) {
  getSubject(id: $id) {
    id
    site
    titrationStatus
    weight
    displayId
    apiTryToken
    apiStatus
    enrollmentDate
    enrollmentDateOffset
    recommendations {
      items {
        id
        timeCreated
        timeCreatedOffset
        reasons
        adjustment
        baseDose
        timeConfirmed
        timeConfirmedOffset
        subjectId
        resultSource
        datasetRef {
          identifier
          s3_bucket
          s3_key
          datatype
          md5
          s3_version_id
          meta
          file_format
          __typename
        }
        algorithmId
        algorithm {
          id
          timeCreated
          version
          runnerVersion
          runner
          createdAt
          updatedAt
          __typename
        }
        explanation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    titratorVersion
    titratorAlgorithm {
      items {
        id
        timeCreated
        version
        runnerVersion
        runner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubjectQueryVariables,
  APITypes.GetSubjectQuery
>;
export const listSubjects = /* GraphQL */ `query ListSubjects(
  $filter: ModelSubjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      site
      titrationStatus
      weight
      displayId
      apiTryToken
      apiStatus
      enrollmentDate
      enrollmentDateOffset
      recommendations {
        items {
          id
          timeCreated
          timeCreatedOffset
          reasons
          adjustment
          baseDose
          timeConfirmed
          timeConfirmedOffset
          subjectId
          resultSource
          datasetRef {
            identifier
            s3_bucket
            s3_key
            datatype
            md5
            s3_version_id
            meta
            file_format
            __typename
          }
          algorithmId
          algorithm {
            id
            timeCreated
            version
            runnerVersion
            runner
            createdAt
            updatedAt
            __typename
          }
          explanation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      titratorVersion
      titratorAlgorithm {
        items {
          id
          timeCreated
          version
          runnerVersion
          runner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubjectsQueryVariables,
  APITypes.ListSubjectsQuery
>;
export const subjectsByDisplayId = /* GraphQL */ `query SubjectsByDisplayId(
  $displayId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSubjectFilterInput
  $limit: Int
  $nextToken: String
) {
  subjectsByDisplayId(
    displayId: $displayId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      site
      titrationStatus
      weight
      displayId
      apiTryToken
      apiStatus
      enrollmentDate
      enrollmentDateOffset
      recommendations {
        items {
          id
          timeCreated
          timeCreatedOffset
          reasons
          adjustment
          baseDose
          timeConfirmed
          timeConfirmedOffset
          subjectId
          resultSource
          datasetRef {
            identifier
            s3_bucket
            s3_key
            datatype
            md5
            s3_version_id
            meta
            file_format
            __typename
          }
          algorithmId
          algorithm {
            id
            timeCreated
            version
            runnerVersion
            runner
            createdAt
            updatedAt
            __typename
          }
          explanation
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      titratorVersion
      titratorAlgorithm {
        items {
          id
          timeCreated
          version
          runnerVersion
          runner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SubjectsByDisplayIdQueryVariables,
  APITypes.SubjectsByDisplayIdQuery
>;
export const getSite = /* GraphQL */ `query GetSite($id: ID!) {
  getSite(id: $id) {
    id
    displayId
    timezone
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSiteQueryVariables, APITypes.GetSiteQuery>;
export const listSites = /* GraphQL */ `query ListSites(
  $filter: ModelSiteFilterInput
  $limit: Int
  $nextToken: String
) {
  listSites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      displayId
      timezone
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListSitesQueryVariables, APITypes.ListSitesQuery>;
export const sitesByDisplayId = /* GraphQL */ `query SitesByDisplayId(
  $displayId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelSiteFilterInput
  $limit: Int
  $nextToken: String
) {
  sitesByDisplayId(
    displayId: $displayId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      displayId
      timezone
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SitesByDisplayIdQueryVariables,
  APITypes.SitesByDisplayIdQuery
>;
export const getApiSecret = /* GraphQL */ `query GetApiSecret($id: String!) {
  getApiSecret(id: $id) {
    id
    expiry
    token
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetApiSecretQueryVariables,
  APITypes.GetApiSecretQuery
>;
export const listApiSecrets = /* GraphQL */ `query ListApiSecrets(
  $id: String
  $filter: ModelApiSecretFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listApiSecrets(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      expiry
      token
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListApiSecretsQueryVariables,
  APITypes.ListApiSecretsQuery
>;
export const getPasswordExpiration = /* GraphQL */ `query GetPasswordExpiration($owner: String!) {
  getPasswordExpiration(owner: $owner) {
    owner
    expiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPasswordExpirationQueryVariables,
  APITypes.GetPasswordExpirationQuery
>;
export const listPasswordExpirations = /* GraphQL */ `query ListPasswordExpirations(
  $owner: String
  $filter: ModelPasswordExpirationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPasswordExpirations(
    owner: $owner
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      owner
      expiry
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPasswordExpirationsQueryVariables,
  APITypes.ListPasswordExpirationsQuery
>;
export const listEgvs = /* GraphQL */ `query ListEgvs(
  $id: String
  $startDate: String
  $endDate: String
  $debug: Boolean
) {
  listEgvs(id: $id, startDate: $startDate, endDate: $endDate, debug: $debug) {
    status
    response {
      recordType
      recordVersion
      userId
      records {
        recordId
        systemTime
        displayTime
        transmitterId
        transmitterTicks
        value
        status
        trend
        trendRate
        displayDevice
        transmitterGeneration
        unit
        rateUnit
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.ListEgvsQueryVariables, APITypes.ListEgvsQuery>;
export const listEvents = /* GraphQL */ `query ListEvents(
  $id: String
  $startDate: String
  $endDate: String
  $debug: Boolean
) {
  listEvents(id: $id, startDate: $startDate, endDate: $endDate, debug: $debug) {
    status
    response {
      recordType
      recordVersion
      userId
      records {
        systemTime
        displayTime
        recordId
        eventStatus
        eventType
        eventSubType
        value
        unit
        transmitterId
        transmitterGeneration
        displayDevice
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const listDataRange = /* GraphQL */ `query ListDataRange($id: String, $debug: Boolean) {
  listDataRange(id: $id, debug: $debug) {
    response {
      recordType
      recordVersion
      userId
      calibrations {
        start {
          systemTime
          displayTime
          __typename
        }
        end {
          systemTime
          displayTime
          __typename
        }
        __typename
      }
      egvs {
        start {
          systemTime
          displayTime
          __typename
        }
        end {
          systemTime
          displayTime
          __typename
        }
        __typename
      }
      events {
        start {
          systemTime
          displayTime
          __typename
        }
        end {
          systemTime
          displayTime
          __typename
        }
        __typename
      }
      __typename
    }
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDataRangeQueryVariables,
  APITypes.ListDataRangeQuery
>;
