import {Button, Chip, ListItem, ListItemAvatar, ListItemText, Tooltip} from "@mui/material";
import React from "react";
import {ApiStatus, Subject, TitrationStatus} from "../../@dexbasal";
import Export from "../Export/Export";
import {NetworkAlertHandler, SubjectHandler} from "../../utils/Interfaces";
import {getRecommendation, getReviewButtonActionLabel, recommendationNeedsReview} from "../../utils/Utils";
import DexcomRegistration from "../AddSubject/DexcomRegistration";
import DexbasalAPI from "../../api/DexbasalApi";
import AlgorithmIcon from "../AlgorithmIcon";

interface IProps {
    onSubjectClick: SubjectHandler,
    onNetworkAlert: NetworkAlertHandler
    onSubjectUpdate: SubjectHandler,
    subject: Subject,
    debugApi: boolean,
    api: DexbasalAPI
}

const SubjectItem: React.FunctionComponent<IProps> = (props) => {
    let action: string, titrationStatus: string, enabled: boolean, color: any, variant: any
    const {subject, debugApi, api} = props;
    const recommendation = getRecommendation(subject);
    const needsReview = recommendationNeedsReview(recommendation);
    const algorithm = (props.subject.titratorAlgorithm && props.subject.titratorAlgorithm.items && props.subject.titratorAlgorithm.items[0]) ? props.subject.titratorAlgorithm.items[0] : null

    action = 'N/A'
    titrationStatus = 'Unknown'
    variant = 'text'
    enabled = false
    color = 'warning'
    if(subject.titrationStatus) {
     switch (subject.titrationStatus) {
        case TitrationStatus.DISQUALIFIED:
            titrationStatus = subject.titrationStatus.toString()
            action = 'N/A'
            variant = 'text'
            enabled = false
            color = 'secondary'
            break
        case TitrationStatus.TITRATING:
            titrationStatus = subject.titrationStatus.toString()
            action = getReviewButtonActionLabel(subject);
            variant = needsReview ? 'contained' : 'outlined'
            enabled = true
            color = 'success'
            break
        case TitrationStatus.COMPLETE:
            titrationStatus = subject.titrationStatus.toString()
            action = getReviewButtonActionLabel(subject);
            variant = needsReview ? 'contained' : 'text'
            enabled = needsReview
            color = 'info'
            break
        }
    }

    function renderSubjectItemButton() {
        if(subject.apiStatus === ApiStatus.CONFIRMED) {
            return (
              <Button variant={variant}
                      disabled={!enabled}
                      onClick={() => props.onSubjectClick(subject)}
              >{action}</Button>
            )
        } else {
            return (
              <DexcomRegistration subject={subject} displayText={action} onNetworkAlert={props.onNetworkAlert} onSubjectUpdate={props.onSubjectUpdate} debugApi={debugApi} api={api}/>
            )
        }
    }

    return(
        <ListItem
            key={subject.id}
            secondaryAction={
                (enabled ? renderSubjectItemButton()
                    : (<Export subject={subject} debugApi={debugApi} api={api} />))
            }
        >
            <AlgorithmIcon titratorAlgorithm={algorithm}/>
            <ListItemAvatar sx={{marginLeft:2}}>
                <Tooltip title={titrationStatus}>
                    <Chip color={color}
                          aria-label={titrationStatus}
                          label={titrationStatus.charAt(0)}/>
                </Tooltip>
            </ListItemAvatar>
            <ListItemText primary={subject.displayId}/>
        </ListItem>
    )
}

export default SubjectItem
