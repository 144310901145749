export const updateSubjectWithSortedRecommendationsDescending = /* GraphQL */ `
    mutation UpdateSubject(
        $input: UpdateSubjectInput!
        $condition: ModelSubjectConditionInput
    ) {
        updateSubject(input: $input, condition: $condition) {
            id
            site
            titrationStatus
            weight
            displayId
            apiTryToken
            apiStatus
            enrollmentDate
            enrollmentDateOffset
            recommendations(sortDirection: DESC, limit: 1) {
                items {
                    id
                    timeCreated
                    timeCreatedOffset
                    reasons
                    adjustment
                    baseDose
                    timeConfirmed
                    timeConfirmedOffset
                    subjectId
                    resultSource
                    datasetRef {
                        identifier
                        s3_bucket
                        s3_key
                        datatype
                        md5
                        s3_version_id
                        meta
                        file_format
                        __typename
                    }
                    algorithmId
                    algorithm {
                        id
                        timeCreated
                        version
                        runnerVersion
                        runner
                        createdAt
                        updatedAt
                        __typename
                    }
                    explanation
                    createdAt
                    updatedAt
                    __typename
                }
                nextToken
                __typename
            }
            titratorVersion
            titratorAlgorithm {
                items {
                    id
                    timeCreated
                    version
                    runnerVersion
                    runner
                    createdAt
                    updatedAt
                    __typename
                }
                nextToken
                __typename
            }
            createdAt
            updatedAt
            __typename
        }
    }
`;
