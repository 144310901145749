import {Egv, Event} from "./api";
import {GetFn, GraphQLResponse} from "./graphql-api";

export type FlatRecord = {subjectId: string, recordType: string, recordVersion: string, userId: string};
export type FlatEgv =  FlatRecord & Egv
export type FlatEvent =  FlatRecord & Event
export type QueryAndProcessParams<V extends FlatRecord> =  {
  subjectId: string,
  mark: number,
  start: number,
  onData: (data: V[]) => void,
  debugApi: boolean
}

export type QueryAndProcessFn<R, F extends FlatRecord> = (params: QueryAndProcessParams<F>) => Promise<GraphQLResponse<R>>

export type QueryAndProcessRawFn<R, F extends FlatRecord> = (params: QueryAndProcessParams<F> & {getData: GetFn<R>}) => Promise<GraphQLResponse<R>>

export type QueryGeneratorParams<R, F extends FlatRecord> = {
  getData: GetFn<R>
  gather: QueryAndProcessRawFn<R, F>
}

export type GatherDataGenerator<R, F extends FlatRecord> = (params: QueryGeneratorParams<R, F>) => QueryAndProcessFn<R, F>

export enum DOWNLOAD_STATE {
  IDLE,
  LOADING,
  ERROR,
  COMPLETE
}
