import React, {useEffect} from "react";
import {
    Alert, AppBar, Button,
    Container,
    List,
    Skeleton, Stack, Toolbar,
    Typography
} from "@mui/material";
import SubjectItem from "./SubjectItem";
import {Add} from "@mui/icons-material";
import SCREENS from "../../utils/Screens";
import {
  AddSubjectClickHandler,
  NetworkAlertHandler,
  SubjectHandler
} from "../../utils/Interfaces";
import NetworkError from "../Alerts/NetworkError";
import {Subject} from "../../@dexbasal";
import {trackScreen} from "../../analytics/ReactAnalytics";
import DexbasalAPI from "../../api/DexbasalApi";

const SubjectCollection: React.FunctionComponent<{
  onSubjectClick: SubjectHandler,
  onAddSubjectClick: AddSubjectClickHandler,
  onNetworkError: NetworkAlertHandler,
  networkError: boolean,
  subjects: Subject[] | undefined
  loadSubjects: () => void
  onSubjectUpdate: SubjectHandler,
  debugApi: boolean,
  api: DexbasalAPI,
  maxHeight: number
}> = (props) => {
    const subjects = props.subjects;
    const loading = !subjects;
    const showErrorAlert = props.networkError;
    const handleNetworkAlert = props.onNetworkError;

    useEffect(() => {
      if(loading) {
        props.loadSubjects();
      }
    })

    useEffect(() => {
      trackScreen({screen: SCREENS.SUBJECT_OVERVIEW})
    }, [])

  return (
    <Container disableGutters={true} maxWidth={false}>
        <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
            <Toolbar>
                <Typography variant={'h6'} sx={{flexGrow: 1, position: "relative"}}>
                    {SCREENS.SUBJECT_OVERVIEW}
                </Typography>
                <Button variant={'outlined'}
                        sx={{position: "absolute", marginRight: 2}}
                        color={'inherit'}
                        onClick={props.onAddSubjectClick}
                        endIcon={<Add/>}>
                    Add Subject
                </Button>
            </Toolbar>
        </AppBar>

        <NetworkError show={showErrorAlert}/>

        <Container maxWidth={'md'} style={{maxHeight: props.maxHeight === -1 ? 'none' : props.maxHeight, overflow:'auto'}}>
            {loading ? (
              [...Array(20)].map((_, idx) =>
                <Stack key={idx} direction={'row'} sx={{marginTop: 2}}>
                    <Skeleton width={32} height={32} variant={'circular'} sx={{marginLeft: '16px', marginRight: '24px'}}/>
                    <Skeleton>
                        <Typography>fcfd4020-911a-4edb-a1b7-385d467a60fd</Typography>
                    </Skeleton>
                </Stack>
              )
            ):(
              subjects.length > 0 ? (
                <List id={'subjectList'}>
                    {subjects.map((subject) => {
                        return <SubjectItem
                          key={subject.id}
                          onSubjectClick={props.onSubjectClick}
                          onNetworkAlert={handleNetworkAlert}
                          onSubjectUpdate={props.onSubjectUpdate}
                          subject={subject}
                          debugApi={props.debugApi}
                          api={props.api}
                        />
                    })}
                </List>
              ):(
                <Alert color={'warning'} sx={{marginTop: 5}}>Add Subjects to get started.</Alert>
              )
            )}
        </Container>
    </Container>
  );
}

export default SubjectCollection
