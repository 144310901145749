/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../../@dexbasal/api";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserSite = /* GraphQL */ `mutation CreateUserSite(
  $input: CreateUserSiteInput!
  $condition: ModelUserSiteConditionInput
) {
  createUserSite(input: $input, condition: $condition) {
    id
    user
    site
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserSiteMutationVariables,
  APITypes.CreateUserSiteMutation
>;
export const updateUserSite = /* GraphQL */ `mutation UpdateUserSite(
  $input: UpdateUserSiteInput!
  $condition: ModelUserSiteConditionInput
) {
  updateUserSite(input: $input, condition: $condition) {
    id
    user
    site
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserSiteMutationVariables,
  APITypes.UpdateUserSiteMutation
>;
export const deleteUserSite = /* GraphQL */ `mutation DeleteUserSite(
  $input: DeleteUserSiteInput!
  $condition: ModelUserSiteConditionInput
) {
  deleteUserSite(input: $input, condition: $condition) {
    id
    user
    site
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserSiteMutationVariables,
  APITypes.DeleteUserSiteMutation
>;
export const createRecommendation = /* GraphQL */ `mutation CreateRecommendation(
  $input: CreateRecommendationInput!
  $condition: ModelRecommendationConditionInput
) {
  createRecommendation(input: $input, condition: $condition) {
    id
    timeCreated
    timeCreatedOffset
    reasons
    adjustment
    baseDose
    timeConfirmed
    timeConfirmedOffset
    subjectId
    resultSource
    datasetRef {
      identifier
      s3_bucket
      s3_key
      datatype
      md5
      s3_version_id
      meta
      file_format
      __typename
    }
    algorithmId
    algorithm {
      id
      timeCreated
      version
      runnerVersion
      runner
      createdAt
      updatedAt
      __typename
    }
    explanation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRecommendationMutationVariables,
  APITypes.CreateRecommendationMutation
>;
export const updateRecommendation = /* GraphQL */ `mutation UpdateRecommendation(
  $input: UpdateRecommendationInput!
  $condition: ModelRecommendationConditionInput
) {
  updateRecommendation(input: $input, condition: $condition) {
    id
    timeCreated
    timeCreatedOffset
    reasons
    adjustment
    baseDose
    timeConfirmed
    timeConfirmedOffset
    subjectId
    resultSource
    datasetRef {
      identifier
      s3_bucket
      s3_key
      datatype
      md5
      s3_version_id
      meta
      file_format
      __typename
    }
    algorithmId
    algorithm {
      id
      timeCreated
      version
      runnerVersion
      runner
      createdAt
      updatedAt
      __typename
    }
    explanation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRecommendationMutationVariables,
  APITypes.UpdateRecommendationMutation
>;
export const deleteRecommendation = /* GraphQL */ `mutation DeleteRecommendation(
  $input: DeleteRecommendationInput!
  $condition: ModelRecommendationConditionInput
) {
  deleteRecommendation(input: $input, condition: $condition) {
    id
    timeCreated
    timeCreatedOffset
    reasons
    adjustment
    baseDose
    timeConfirmed
    timeConfirmedOffset
    subjectId
    resultSource
    datasetRef {
      identifier
      s3_bucket
      s3_key
      datatype
      md5
      s3_version_id
      meta
      file_format
      __typename
    }
    algorithmId
    algorithm {
      id
      timeCreated
      version
      runnerVersion
      runner
      createdAt
      updatedAt
      __typename
    }
    explanation
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRecommendationMutationVariables,
  APITypes.DeleteRecommendationMutation
>;
export const createAlgorithm = /* GraphQL */ `mutation CreateAlgorithm(
  $input: CreateAlgorithmInput!
  $condition: ModelAlgorithmConditionInput
) {
  createAlgorithm(input: $input, condition: $condition) {
    id
    timeCreated
    version
    runnerVersion
    runner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAlgorithmMutationVariables,
  APITypes.CreateAlgorithmMutation
>;
export const updateAlgorithm = /* GraphQL */ `mutation UpdateAlgorithm(
  $input: UpdateAlgorithmInput!
  $condition: ModelAlgorithmConditionInput
) {
  updateAlgorithm(input: $input, condition: $condition) {
    id
    timeCreated
    version
    runnerVersion
    runner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAlgorithmMutationVariables,
  APITypes.UpdateAlgorithmMutation
>;
export const deleteAlgorithm = /* GraphQL */ `mutation DeleteAlgorithm(
  $input: DeleteAlgorithmInput!
  $condition: ModelAlgorithmConditionInput
) {
  deleteAlgorithm(input: $input, condition: $condition) {
    id
    timeCreated
    version
    runnerVersion
    runner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAlgorithmMutationVariables,
  APITypes.DeleteAlgorithmMutation
>;
export const createSubject = /* GraphQL */ `mutation CreateSubject(
  $input: CreateSubjectInput!
  $condition: ModelSubjectConditionInput
) {
  createSubject(input: $input, condition: $condition) {
    id
    site
    titrationStatus
    weight
    displayId
    apiTryToken
    apiStatus
    enrollmentDate
    enrollmentDateOffset
    recommendations {
      items {
        id
        timeCreated
        timeCreatedOffset
        reasons
        adjustment
        baseDose
        timeConfirmed
        timeConfirmedOffset
        subjectId
        resultSource
        datasetRef {
          identifier
          s3_bucket
          s3_key
          datatype
          md5
          s3_version_id
          meta
          file_format
          __typename
        }
        algorithmId
        algorithm {
          id
          timeCreated
          version
          runnerVersion
          runner
          createdAt
          updatedAt
          __typename
        }
        explanation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    titratorVersion
    titratorAlgorithm {
      items {
        id
        timeCreated
        version
        runnerVersion
        runner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubjectMutationVariables,
  APITypes.CreateSubjectMutation
>;
export const updateSubject = /* GraphQL */ `mutation UpdateSubject(
  $input: UpdateSubjectInput!
  $condition: ModelSubjectConditionInput
) {
  updateSubject(input: $input, condition: $condition) {
    id
    site
    titrationStatus
    weight
    displayId
    apiTryToken
    apiStatus
    enrollmentDate
    enrollmentDateOffset
    recommendations {
      items {
        id
        timeCreated
        timeCreatedOffset
        reasons
        adjustment
        baseDose
        timeConfirmed
        timeConfirmedOffset
        subjectId
        resultSource
        datasetRef {
          identifier
          s3_bucket
          s3_key
          datatype
          md5
          s3_version_id
          meta
          file_format
          __typename
        }
        algorithmId
        algorithm {
          id
          timeCreated
          version
          runnerVersion
          runner
          createdAt
          updatedAt
          __typename
        }
        explanation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    titratorVersion
    titratorAlgorithm {
      items {
        id
        timeCreated
        version
        runnerVersion
        runner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubjectMutationVariables,
  APITypes.UpdateSubjectMutation
>;
export const deleteSubject = /* GraphQL */ `mutation DeleteSubject(
  $input: DeleteSubjectInput!
  $condition: ModelSubjectConditionInput
) {
  deleteSubject(input: $input, condition: $condition) {
    id
    site
    titrationStatus
    weight
    displayId
    apiTryToken
    apiStatus
    enrollmentDate
    enrollmentDateOffset
    recommendations {
      items {
        id
        timeCreated
        timeCreatedOffset
        reasons
        adjustment
        baseDose
        timeConfirmed
        timeConfirmedOffset
        subjectId
        resultSource
        datasetRef {
          identifier
          s3_bucket
          s3_key
          datatype
          md5
          s3_version_id
          meta
          file_format
          __typename
        }
        algorithmId
        algorithm {
          id
          timeCreated
          version
          runnerVersion
          runner
          createdAt
          updatedAt
          __typename
        }
        explanation
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    titratorVersion
    titratorAlgorithm {
      items {
        id
        timeCreated
        version
        runnerVersion
        runner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubjectMutationVariables,
  APITypes.DeleteSubjectMutation
>;
export const createSite = /* GraphQL */ `mutation CreateSite(
  $input: CreateSiteInput!
  $condition: ModelSiteConditionInput
) {
  createSite(input: $input, condition: $condition) {
    id
    displayId
    timezone
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSiteMutationVariables,
  APITypes.CreateSiteMutation
>;
export const updateSite = /* GraphQL */ `mutation UpdateSite(
  $input: UpdateSiteInput!
  $condition: ModelSiteConditionInput
) {
  updateSite(input: $input, condition: $condition) {
    id
    displayId
    timezone
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSiteMutationVariables,
  APITypes.UpdateSiteMutation
>;
export const deleteSite = /* GraphQL */ `mutation DeleteSite(
  $input: DeleteSiteInput!
  $condition: ModelSiteConditionInput
) {
  deleteSite(input: $input, condition: $condition) {
    id
    displayId
    timezone
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSiteMutationVariables,
  APITypes.DeleteSiteMutation
>;
export const createApiSecret = /* GraphQL */ `mutation CreateApiSecret(
  $input: CreateApiSecretInput!
  $condition: ModelApiSecretConditionInput
) {
  createApiSecret(input: $input, condition: $condition) {
    id
    expiry
    token
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateApiSecretMutationVariables,
  APITypes.CreateApiSecretMutation
>;
export const updateApiSecret = /* GraphQL */ `mutation UpdateApiSecret(
  $input: UpdateApiSecretInput!
  $condition: ModelApiSecretConditionInput
) {
  updateApiSecret(input: $input, condition: $condition) {
    id
    expiry
    token
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateApiSecretMutationVariables,
  APITypes.UpdateApiSecretMutation
>;
export const deleteApiSecret = /* GraphQL */ `mutation DeleteApiSecret(
  $input: DeleteApiSecretInput!
  $condition: ModelApiSecretConditionInput
) {
  deleteApiSecret(input: $input, condition: $condition) {
    id
    expiry
    token
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteApiSecretMutationVariables,
  APITypes.DeleteApiSecretMutation
>;
export const createPasswordExpiration = /* GraphQL */ `mutation CreatePasswordExpiration(
  $input: CreatePasswordExpirationInput!
  $condition: ModelPasswordExpirationConditionInput
) {
  createPasswordExpiration(input: $input, condition: $condition) {
    owner
    expiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePasswordExpirationMutationVariables,
  APITypes.CreatePasswordExpirationMutation
>;
export const updatePasswordExpiration = /* GraphQL */ `mutation UpdatePasswordExpiration(
  $input: UpdatePasswordExpirationInput!
  $condition: ModelPasswordExpirationConditionInput
) {
  updatePasswordExpiration(input: $input, condition: $condition) {
    owner
    expiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePasswordExpirationMutationVariables,
  APITypes.UpdatePasswordExpirationMutation
>;
export const deletePasswordExpiration = /* GraphQL */ `mutation DeletePasswordExpiration(
  $input: DeletePasswordExpirationInput!
  $condition: ModelPasswordExpirationConditionInput
) {
  deletePasswordExpiration(input: $input, condition: $condition) {
    owner
    expiry
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePasswordExpirationMutationVariables,
  APITypes.DeletePasswordExpirationMutation
>;
export const getApiRedirect = /* GraphQL */ `mutation GetApiRedirect($id: String, $returnUrl: String, $debug: Boolean) {
  getApiRedirect(id: $id, returnUrl: $returnUrl, debug: $debug)
}
` as GeneratedMutation<
  APITypes.GetApiRedirectMutationVariables,
  APITypes.GetApiRedirectMutation
>;
export const postBasalRecommendation = /* GraphQL */ `mutation PostBasalRecommendation(
  $id: String
  $totalDose: Float
  $timeConfirmed: String
  $debug: Boolean
) {
  postBasalRecommendation(
    id: $id
    totalDose: $totalDose
    timeConfirmed: $timeConfirmed
    debug: $debug
  ) {
    status
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PostBasalRecommendationMutationVariables,
  APITypes.PostBasalRecommendationMutation
>;
export const titrate = /* GraphQL */ `mutation Titrate($args: HandlerArgs) {
  titrate(args: $args) {
    statusCode
    headers
    body
    errorMessage
    errorType
    stackTrace
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TitrateMutationVariables,
  APITypes.TitrateMutation
>;
