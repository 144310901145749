import React, { useMemo} from 'react'
import { Egv, GlucoseUnit } from '../../@dexbasal'
import { TIME_RANGE, Chart } from './Chart'
import {Grid} from "@mui/material";

interface GraphProps {
  egvs: Egv[]
  highThresh: number
  lowThresh: number
  glucoseUnits: GlucoseUnit
}

const Graph: React.FunctionComponent<GraphProps> = ({
  egvs,
  highThresh,
  lowThresh,
  glucoseUnits
}) => {
  const data = useMemo(() => {
    const now = new Date().getTime()

    return egvs
      .filter((egv) => (new Date(egv.systemTime || "").getTime()) >= now - TIME_RANGE.RANGE_27_HOURS)
      .map((egv) => ({
        value: egv.value || 0,
        systemTime: (new Date(egv.systemTime || "").getTime()),
        unit: glucoseUnits
      }))
  }, [egvs, glucoseUnits])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Chart
          data={data}
          timeRange={TIME_RANGE.RANGE_27_HOURS}
          highThresh={highThresh}
          lowThresh={lowThresh}
          glucoseUnits={glucoseUnits}
        />
      </Grid>
    </Grid>
  )
}

export default Graph
