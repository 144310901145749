import {API, Auth} from "aws-amplify";
import {ListGroupsResponse, ListUsersInGroupResponse, User} from "../App";

export async function getUser(username: string): Promise<User> {
    let apiName = 'AdminQueries';
    let path = '/getUser';
    let myInit = {
        queryStringParameters: {
            username
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.get(apiName, path, myInit);
}

export async function createUser(username: string): Promise<User> {
    let apiName = 'AdminQueries';
    let path = '/createUser';
    let myInit = {
        body: {
            username
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export async function setUserPassword(username: string) {
    let apiName = 'AdminQueries';
    let path = '/setUserPassword';
    let myInit = {
        body: {
            username
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export async function addUserToGroup(username: string, groupname: string) {
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let myInit = {
        body: {
            username,
            groupname
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export async function removeUserFromGroup(username: string, groupname: string) {
    let apiName = 'AdminQueries';
    let path = '/removeUserFromGroup';
    let myInit = {
        body: {
            username,
            groupname
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

interface WelcomeEmail {
    email: string;
    group: string;
    resetNeeded: boolean;
    site?: string;
}

export async function sendWelcomeEmail({email, group, resetNeeded, site}: WelcomeEmail) {
    let apiName = 'AdminQueries';
    let path = '/sendWelcomeEmail';
    let myInit = {
        body: {
            email,
            hostname: window.location.hostname,
            protocol: window.location.protocol,
            port: window.location.port ? window.location.port : undefined,
            group,
            site,
            resetNeeded
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export async function sendWarningEmail(email: string, group: string) {
    let apiName = 'AdminQueries';
    let path = '/sendWarningEmail';
    let myInit = {
        body: {
            email,
            group
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export async function disableUser(username: string) {
    let apiName = 'AdminQueries';
    let path = '/disableUser';
    let myInit = {
        body: {
            username
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export async function deleteUser(username: string) {
    let apiName = 'AdminQueries';
    let path = '/deleteUser';
    let myInit = {
        body: {
            username
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export async function listGroups(): Promise<ListGroupsResponse> {
    let apiName = 'AdminQueries';
    let path = '/listGroups';
    let myInit = {
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.get(apiName, path, myInit);
}

export async function listUsersInGroup(groupname: string): Promise<ListUsersInGroupResponse> {
    let apiName = 'AdminQueries';
    let path = '/listUsersInGroup';
    let myInit = {
        queryStringParameters: {
            groupname
        },
        headers: {
            'Content-Type' : 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.get(apiName, path, myInit);
}
