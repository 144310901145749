/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://aur990fm9e.execute-api.us-west-2.amazonaws.com/int",
            "region": "us-west-2"
        },
        {
            "name": "callback",
            "endpoint": "https://31vldm3phj.execute-api.us-west-2.amazonaws.com/int",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://vrqfaosdjbc77pjg5tjxtckw4q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-west-2:c6a07b5a-8581-4f0f-bfe1-7844d4fa78e7",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_5IcA4qz6w",
    "aws_user_pools_web_client_id": "37hi5958ie5svh8shns75n4uo0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_firehose_name": "amplify-dexbasal-start-int-175652-customtitrator-NX-Hk9ZdHFpScHD",
    "aws_firehose_region": "us-west-2"
};


export default awsmobile;
