import {AutoFixHigh, DeveloperBoard, Start} from "@mui/icons-material";
import {AlgorithmVersion} from "../@dexbasal";

export interface AlgorithmOptions {
  algorithm: AlgorithmVersion
  description: string
  badgeVersion: string
  showBadge: boolean
  color: string
  icon: any
}

/**
 * Creates an array of AlgorithmOptions from the values in the enum AlgorithmVersion.
 * Creates a description based on the first word in the enum, and adds an
 * icon (default is the "DeveloperBoard" icon)
 */
export const getAlgorithmOptions = (): AlgorithmOptions[] => {
  return Object.values(AlgorithmVersion)
    .map(a => {
      let name = a.split("_")[0].toLowerCase()
      name = name.charAt(0).toUpperCase() + name.slice(1)

      let icon;
      let badgeVersion;
      switch (a) {
        case AlgorithmVersion.START_V_1:
          icon = <Start/>
          badgeVersion = "v1"
          break;
        case AlgorithmVersion.START_V_2:
          icon = <Start/>
          badgeVersion = "v2"
          break;
        case AlgorithmVersion.OPTIMIZE_V_1:
          icon = <AutoFixHigh/>
          badgeVersion = "v1"
          break;
        default:
          icon = <DeveloperBoard/>
          badgeVersion = "---"
          break;
      }

      return {
        algorithm: a,
        description: name,
        icon,
        badgeVersion,
        showBadge: (a !== AlgorithmVersion.OPTIMIZE_V_1),
        color: 'lightblue'
      }
    })
}