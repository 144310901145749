import {grey} from '@mui/material/colors'
import {createTheme} from "@mui/material";

export const dexcomTheme = createTheme({
  palette: {
    background: {
      default: grey[50],
      paper: '#fff', //white
    },
    primary: {
      main: '#58A618', // Dexcom Green
    },
    secondary: {
      main: '#212121',  // Dark Gray
    },
    info: {
      main: '#0075C2', // Dexcom Blue
    },
    warning: {
      main: '#E98300', // Dexcom Orange
    },
    success: {
      main: '#58A618', // Dexcom Green
    },
  },
})