import React from "react";
import {Chip, Tooltip, Zoom} from "@mui/material";
import '../../index.css';
import {ResultReason, TitratorResultsSource} from "../../@dexbasal";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DoneOutline from '@mui/icons-material/DoneOutline';
import {ReadableResultReason} from "../../utils/ReadableResultReason";

interface IProps {
  resultSource: TitratorResultsSource,
  resultReasons: Array<ResultReason>
}

function statusToString(reason: ResultReason | null): string {
  switch (reason) {
    case ResultReason.LOW_BG_GUARDRAIL_ACTIVATED:
      return ReadableResultReason.LOW_BG_GUARDRAIL_ACTIVATED
    case ResultReason.CURRENT_DOSE_OVERBASALIZATION_WARNING:
      return ReadableResultReason.CURRENT_DOSE_OVERBASALIZATION_WARNING
    case ResultReason.RECENT_NON_SEVERE_HYPO_PREVENTED_DOSE_INCREASE:
      return ReadableResultReason.RECENT_NON_SEVERE_HYPO_PREVENTED_DOSE_INCREASE
    case ResultReason.RECENT_SEVERE_HYPO_PREVENTED_DOSE_INCREASE:
      return ReadableResultReason.RECENT_SEVERE_HYPO_PREVENTED_DOSE_INCREASE
    case ResultReason.SEVERE_HYPO_FORCED_DOSE_DECREASE:
      return ReadableResultReason.SEVERE_HYPO_FORCED_DOSE_DECREASE
    case ResultReason.TARGET_DOSE_OVERBASALIZATION_WARNING:
      return ReadableResultReason.TARGET_DOSE_OVERBASALIZATION_WARNING
    case ResultReason.TERMINATED:
      return ReadableResultReason.TERMINATED
    default:
      return ReadableResultReason.UNKNOWN
  }
}

function AttentionTooltip (props: {resultReasons: ResultReason[], color: 'info' | 'warning', toolTip: String}) {
  return (
    <Tooltip title={
      props.resultReasons.length <= 1 ? "" :
        <span style={{ whiteSpace: 'pre-line', textAlign: 'center'}}>
          {props.toolTip}
        </span>
    } arrow TransitionComponent={Zoom}>
      <Chip
        icon={
          props.color === 'warning' ?
            <PriorityHighIcon style={{paddingLeft:'3px'}} fontSize={'small'}/> :
            <DoneOutline style={{paddingLeft:'3px'}} fontSize={'small'}/>
        }
        style={{color:'white', margin:'10px'}}
        color={props.color}
        label={props.resultReasons.length <= 1 ? props.toolTip : props.resultReasons.length + " Result Alerts"}
      />
    </Tooltip>
  )
}

const RecommendationIndicator: React.FunctionComponent<IProps> = (props) => {
  let warningReasonTooltip: String = "";
  let infoReasonToolTip: String = "";
  props.resultReasons
    .filter(r => r !== ResultReason.TERMINATED)
    .map((reason) => warningReasonTooltip +=
      (props.resultReasons.length > 1 ? "- " : "") + statusToString(reason) + "\n"
    );
  props.resultReasons
    .filter(r => r === ResultReason.TERMINATED)
    .map((reason) => infoReasonToolTip += statusToString(reason) + "\n")

  const source = props.resultSource;
  if (source === "SYSTEM_ERROR") {
    warningReasonTooltip = ReadableResultReason.SYSTEM_ERROR
  }

  const warningToolTip = <AttentionTooltip
    resultReasons={props.resultReasons.filter(r => r !== ResultReason.TERMINATED)}
    color={'warning'}
    toolTip={warningReasonTooltip}
  />;

  const infoToolTip = <AttentionTooltip
    resultReasons={props.resultReasons.filter(r => r === ResultReason.TERMINATED)}
    color={'info'}
    toolTip={infoReasonToolTip}
  />

  if (warningReasonTooltip.length > 0 && infoReasonToolTip.length > 0) {
    return (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        {warningToolTip}
        {infoToolTip}
      </div>
    )
  } else if (warningReasonTooltip.length > 0) {
    return warningToolTip
  } else {
    return infoToolTip
  }
}

export default RecommendationIndicator
