import React from "react";
import {Grid, Stack, Tooltip, Typography} from "@mui/material";
import {IHistoricalRow} from "../../utils/Interfaces";
import {Event} from "../../@dexbasal"
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {formatDateString} from "../../utils/Locale";

const InjectionRow: React.FunctionComponent<IHistoricalRow> = (props) => {
  const event = props.record as Event

  function renderInsulin() {
    if(event.eventSubType === null) {
      return (
        <Grid style={{display: 'flex', flexDirection: 'row'}}>
          <Grid item>
            <Tooltip title={'Unknown Insulin Type'}>
              <WarningAmberIcon color={'warning'} fontSize={'small'}/>
            </Tooltip>
          </Grid>
          <Grid item alignSelf={"center"}>
            <Tooltip title="Insulin Type">
              <Typography variant={'body2'} noWrap={true}>Unknown Insulin Type</Typography>
            </Tooltip>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Tooltip title="Insulin Type">
          <Typography variant={'body2'} noWrap={true}>
            {event.eventSubType === "longActing" ? "Long Acting" : "Fast Acting"}
          </Typography>
        </Tooltip>
      )
    }
  }

  const unitValue = Math.floor(parseFloat(event.value));
  return (
    <Stack>
        <Tooltip title="Injected Dose">
            <Typography
                variant={'h6'}
                component={'p'}>
                <strong>{unitValue} {unitValue === 1 ? "Unit" : "Units"}</strong>
            </Typography>
        </Tooltip>
        <Tooltip title="Recorded">
            <Typography color={'text.secondary'}>
                {formatDateString(event.systemTime)}
            </Typography>
        </Tooltip>
        {renderInsulin()}
    </Stack>
  );
}

export default InjectionRow
