/* tslint:disable */
/* eslint-disable */
// THIS IS NOT AUTOGENERATED, IT IS A COPY OF WHAT IS AUTOGENERATED
// WE NEED TO SPECIFY THE SORT DIRECTION OF SUBJECT RECOMMENDATIONS, HENCE THE SLIGHT MODIFICATION!!!!
export const listSubjectsWithSortedRecommendationsDescending = /* GraphQL */ `
  query ListSubjects(
    $filter: ModelSubjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        site
        titrationStatus
        weight
        displayId
        apiTryToken
        apiStatus
        enrollmentDate
        enrollmentDateOffset
        recommendations(sortDirection: DESC, limit: 1) {
          items {
            id
            timeCreated
            timeCreatedOffset
            reasons
            adjustment
            baseDose
            timeConfirmed
            timeConfirmedOffset
            subjectId
            resultSource
            datasetRef {
              identifier
              s3_bucket
              s3_key
              datatype
              md5
              s3_version_id
              meta
              file_format
            }
            createdAt
            updatedAt
            algorithmId
            algorithm {
                id
                runner
                runnerVersion
                timeCreated
                version
                updatedAt
                createdAt
            }
            explanation
          }
          nextToken
        }
        titratorVersion
        titratorAlgorithm(sortDirection: DESC, limit: 1) {
            items {
                id
                timeCreated
                version
                runnerVersion
                runner
                createdAt
                updatedAt
                __typename
            }
            nextToken
            __typename
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSubjectsWithSortedAlgorithmsDescending = /* GraphQL */ `
    query ListSubjects(
        $filter: ModelSubjectFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listSubjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                site
                titrationStatus
                weight
                displayId
                apiTryToken
                apiStatus
                enrollmentDate
                enrollmentDateOffset
                createdAt
                updatedAt
                titratorVersion
                titratorAlgorithm(sortDirection: DESC, limit: 1) {
                    items {
                        id
                        timeCreated
                        version
                        runnerVersion
                        runner
                        createdAt
                        updatedAt
                        __typename
                    }
                    nextToken
                    __typename
                }
            }
            nextToken
        }
    }
`;
export const getApiSecret = /* GraphQL */ `
  query GetApiSecret($id: String!) {
    getApiSecret(id: $id) {
      id
      expiry
      token
      createdAt
      updatedAt
    }
  }
`;
export const listApiSecrets = /* GraphQL */ `
  query ListApiSecrets(
    $id: String
    $filter: ModelApiSecretFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApiSecrets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        expiry
        token
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEgvs = /* GraphQL */ `
  query ListEgvs($id: String, $startDate: String, $endDate: String) {
    listEgvs(id: $id, startDate: $startDate, endDate: $endDate) {
      status
      response {
        recordType
        recordVersion
        userId
        records {
          recordId
          systemTime
          displayTime
          transmitterId
          transmitterTicks
          value
          status
          trend
          trendRate
          displayDevice
          transmitterGeneration
          unit
          rateUnit
        }
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents($id: String, $startDate: String, $endDate: String) {
    listEvents(id: $id, startDate: $startDate, endDate: $endDate) {
      status
      response {
        recordType
        recordVersion
        userId
        records {
          systemTime
          displayTime
          recordId
          eventStatus
          eventType
          eventSubType
          value
          unit
          transmitterId
          transmitterGeneration
          displayDevice
        }
      }
    }
  }
`;
export const listDataRange = /* GraphQL */ `
  query ListDataRange($id: String) {
    listDataRange(id: $id) {
      response {
        recordType
        recordVersion
        userId
        calibrations {
          start {
            systemTime
            displayTime
          }
          end {
            systemTime
            displayTime
          }
        }
        egvs {
          start {
            systemTime
            displayTime
          }
          end {
            systemTime
            displayTime
          }
        }
        events {
          start {
            systemTime
            displayTime
          }
          end {
            systemTime
            displayTime
          }
        }
      }
      status
    }
  }
`;
