import {Analytics as ReactAnalytics} from "analytics"
import {HubCapsule} from '@aws-amplify/core';
import {kinesisFirehosePlugin} from "./FirehosePlugin";
import {EventMap, ScreenMap} from "./Interfaces";

const DEBUG = process.env.REACT_APP_DEBUG === "true" ? true : (new URLSearchParams(window.location.search)).get('debug') === 'true'
const VERBOSE = (new URLSearchParams(window.location.search)).get('analytics') === 'true'

const reactAnalytics = ReactAnalytics({
  app: 'dexbasal-study',
  plugins: [
    kinesisFirehosePlugin({
      debug: DEBUG,
      analyticsDebug: VERBOSE
    })
  ]
})

export function readAuthEvents(data: HubCapsule) {
  switch (data.payload.event) {
    case 'signIn':
      const user = data.payload.data
      const userId = user ? user.getUsername() : "unknown_user"
      const userEmail = user && user.attributes ? user.attributes.email : "unknown_email"

      reactAnalytics.identify(userId, {email: userEmail}, () => trackEvent("login"))
      break;
    default:
      break;
  }
}

export function trackScreen<K extends keyof ScreenMap>(props: ScreenMap[K]) {
  reactAnalytics.page({}, props)
}

export function trackEvent<K extends keyof EventMap>(eventName: string, props?: EventMap[K]) {
  if (props && 'callback' in props) {
    reactAnalytics.track(eventName, {}, {type: "event"}, () => props.callback())
  } else {
    reactAnalytics.track(eventName, props, {type: "event"})
  }
}

export function trackError(eventName: string, props?: any) {
  reactAnalytics.track(eventName, props, {type: "error"})
}

export default reactAnalytics
