/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserSiteInput = {
  id?: string | null,
  user: string,
  site: string,
};

export type ModelUserSiteConditionInput = {
  user?: ModelIDInput | null,
  site?: ModelIDInput | null,
  and?: Array< ModelUserSiteConditionInput | null > | null,
  or?: Array< ModelUserSiteConditionInput | null > | null,
  not?: ModelUserSiteConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type UserSite = {
  __typename: "UserSite",
  id: string,
  user: string,
  site: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserSiteInput = {
  id: string,
  user?: string | null,
  site?: string | null,
};

export type DeleteUserSiteInput = {
  id: string,
};

export type CreateRecommendationInput = {
  id?: string | null,
  timeCreated: number,
  timeCreatedOffset: number,
  reasons?: Array< ResultReason > | null,
  adjustment: number,
  baseDose?: number | null,
  timeConfirmed?: number | null,
  timeConfirmedOffset?: number | null,
  subjectId: string,
  resultSource: TitratorResultsSource,
  datasetRef?: DatasetRefInput | null,
  algorithmId: string,
  explanation?: string | null,
};

export enum ResultReason {
  TERMINATED = "TERMINATED",
  RECENT_NON_SEVERE_HYPO_PREVENTED_DOSE_INCREASE = "RECENT_NON_SEVERE_HYPO_PREVENTED_DOSE_INCREASE",
  LOW_BG_GUARDRAIL_ACTIVATED = "LOW_BG_GUARDRAIL_ACTIVATED",
  SEVERE_HYPO_FORCED_DOSE_DECREASE = "SEVERE_HYPO_FORCED_DOSE_DECREASE",
  TARGET_DOSE_OVERBASALIZATION_WARNING = "TARGET_DOSE_OVERBASALIZATION_WARNING",
  CURRENT_DOSE_OVERBASALIZATION_WARNING = "CURRENT_DOSE_OVERBASALIZATION_WARNING",
  RECENT_SEVERE_HYPO_PREVENTED_DOSE_INCREASE = "RECENT_SEVERE_HYPO_PREVENTED_DOSE_INCREASE",
}


export enum TitratorResultsSource {
  TITRATOR = "TITRATOR",
  SYSTEM_ERROR = "SYSTEM_ERROR",
}


export type DatasetRefInput = {
  identifier: string,
  s3_bucket: string,
  s3_key: string,
  datatype: string,
  md5?: string | null,
  s3_version_id?: string | null,
  meta?: string | null,
  file_format?: string | null,
};

export type ModelRecommendationConditionInput = {
  timeCreated?: ModelIntInput | null,
  timeCreatedOffset?: ModelIntInput | null,
  reasons?: ModelResultReasonListInput | null,
  adjustment?: ModelFloatInput | null,
  baseDose?: ModelFloatInput | null,
  timeConfirmed?: ModelIntInput | null,
  timeConfirmedOffset?: ModelIntInput | null,
  subjectId?: ModelIDInput | null,
  resultSource?: ModelTitratorResultsSourceInput | null,
  algorithmId?: ModelIDInput | null,
  explanation?: ModelStringInput | null,
  and?: Array< ModelRecommendationConditionInput | null > | null,
  or?: Array< ModelRecommendationConditionInput | null > | null,
  not?: ModelRecommendationConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelResultReasonListInput = {
  eq?: Array< ResultReason | null > | null,
  ne?: Array< ResultReason | null > | null,
  contains?: ResultReason | null,
  notContains?: ResultReason | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelTitratorResultsSourceInput = {
  eq?: TitratorResultsSource | null,
  ne?: TitratorResultsSource | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Recommendation = {
  __typename: "Recommendation",
  id: string,
  timeCreated: number,
  timeCreatedOffset: number,
  reasons?: Array< ResultReason > | null,
  adjustment: number,
  baseDose?: number | null,
  timeConfirmed?: number | null,
  timeConfirmedOffset?: number | null,
  subjectId: string,
  resultSource: TitratorResultsSource,
  datasetRef?: DatasetRef | null,
  algorithmId: string,
  algorithm: Algorithm,
  explanation?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type DatasetRef = {
  __typename: "DatasetRef",
  identifier: string,
  s3_bucket: string,
  s3_key: string,
  datatype: string,
  md5?: string | null,
  s3_version_id?: string | null,
  meta?: string | null,
  file_format?: string | null,
};

export type Algorithm = {
  __typename: "Algorithm",
  id: string,
  timeCreated: number,
  version: AlgorithmVersion,
  runnerVersion: string,
  runner: string,
  createdAt: string,
  updatedAt: string,
};

export enum AlgorithmVersion {
  START_V_1 = "START_V_1",
  START_V_2 = "START_V_2",
  OPTIMIZE_V_1 = "OPTIMIZE_V_1",
}


export type UpdateRecommendationInput = {
  id: string,
  timeCreated?: number | null,
  timeCreatedOffset?: number | null,
  reasons?: Array< ResultReason > | null,
  adjustment?: number | null,
  baseDose?: number | null,
  timeConfirmed?: number | null,
  timeConfirmedOffset?: number | null,
  subjectId?: string | null,
  resultSource?: TitratorResultsSource | null,
  datasetRef?: DatasetRefInput | null,
  algorithmId?: string | null,
  explanation?: string | null,
};

export type DeleteRecommendationInput = {
  id: string,
};

export type CreateAlgorithmInput = {
  id?: string | null,
  timeCreated: number,
  version: AlgorithmVersion,
  runnerVersion: string,
  runner: string,
};

export type ModelAlgorithmConditionInput = {
  timeCreated?: ModelIntInput | null,
  version?: ModelAlgorithmVersionInput | null,
  runnerVersion?: ModelStringInput | null,
  runner?: ModelStringInput | null,
  and?: Array< ModelAlgorithmConditionInput | null > | null,
  or?: Array< ModelAlgorithmConditionInput | null > | null,
  not?: ModelAlgorithmConditionInput | null,
};

export type ModelAlgorithmVersionInput = {
  eq?: AlgorithmVersion | null,
  ne?: AlgorithmVersion | null,
};

export type UpdateAlgorithmInput = {
  id: string,
  timeCreated?: number | null,
  version?: AlgorithmVersion | null,
  runnerVersion?: string | null,
  runner?: string | null,
};

export type DeleteAlgorithmInput = {
  id: string,
};

export type CreateSubjectInput = {
  id?: string | null,
  site: string,
  titrationStatus: TitrationStatus,
  weight: number,
  displayId: string,
  apiTryToken?: string | null,
  apiStatus: ApiStatus,
  enrollmentDate: number,
  enrollmentDateOffset: number,
  titratorVersion: AlgorithmVersion,
};

export enum TitrationStatus {
  TITRATING = "TITRATING",
  COMPLETE = "COMPLETE",
  DISQUALIFIED = "DISQUALIFIED",
}


export enum ApiStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
}


export type ModelSubjectConditionInput = {
  site?: ModelStringInput | null,
  titrationStatus?: ModelTitrationStatusInput | null,
  weight?: ModelIntInput | null,
  displayId?: ModelStringInput | null,
  apiTryToken?: ModelStringInput | null,
  apiStatus?: ModelApiStatusInput | null,
  enrollmentDate?: ModelIntInput | null,
  enrollmentDateOffset?: ModelIntInput | null,
  titratorVersion?: ModelAlgorithmVersionInput | null,
  and?: Array< ModelSubjectConditionInput | null > | null,
  or?: Array< ModelSubjectConditionInput | null > | null,
  not?: ModelSubjectConditionInput | null,
};

export type ModelTitrationStatusInput = {
  eq?: TitrationStatus | null,
  ne?: TitrationStatus | null,
};

export type ModelApiStatusInput = {
  eq?: ApiStatus | null,
  ne?: ApiStatus | null,
};

export type Subject = {
  __typename: "Subject",
  id: string,
  site: string,
  titrationStatus: TitrationStatus,
  weight: number,
  displayId: string,
  apiTryToken?: string | null,
  apiStatus: ApiStatus,
  enrollmentDate: number,
  enrollmentDateOffset: number,
  recommendations?: ModelRecommendationConnection | null,
  titratorVersion: AlgorithmVersion,
  titratorAlgorithm?: ModelAlgorithmConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelRecommendationConnection = {
  __typename: "ModelRecommendationConnection",
  items:  Array<Recommendation | null >,
  nextToken?: string | null,
};

export type ModelAlgorithmConnection = {
  __typename: "ModelAlgorithmConnection",
  items:  Array<Algorithm | null >,
  nextToken?: string | null,
};

export type UpdateSubjectInput = {
  id: string,
  site?: string | null,
  titrationStatus?: TitrationStatus | null,
  weight?: number | null,
  displayId?: string | null,
  apiTryToken?: string | null,
  apiStatus?: ApiStatus | null,
  enrollmentDate?: number | null,
  enrollmentDateOffset?: number | null,
  titratorVersion?: AlgorithmVersion | null,
};

export type DeleteSubjectInput = {
  id: string,
};

export type CreateSiteInput = {
  id?: string | null,
  displayId: string,
  timezone: string,
};

export type ModelSiteConditionInput = {
  displayId?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  and?: Array< ModelSiteConditionInput | null > | null,
  or?: Array< ModelSiteConditionInput | null > | null,
  not?: ModelSiteConditionInput | null,
};

export type Site = {
  __typename: "Site",
  id: string,
  displayId: string,
  timezone: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSiteInput = {
  id: string,
  displayId?: string | null,
  timezone?: string | null,
};

export type DeleteSiteInput = {
  id: string,
};

export type CreateApiSecretInput = {
  id?: string | null,
  expiry: number,
  token: string,
};

export type ModelApiSecretConditionInput = {
  expiry?: ModelIntInput | null,
  token?: ModelStringInput | null,
  and?: Array< ModelApiSecretConditionInput | null > | null,
  or?: Array< ModelApiSecretConditionInput | null > | null,
  not?: ModelApiSecretConditionInput | null,
};

export type ApiSecret = {
  __typename: "ApiSecret",
  id: string,
  expiry: number,
  token: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateApiSecretInput = {
  id: string,
  expiry?: number | null,
  token?: string | null,
};

export type DeleteApiSecretInput = {
  id: string,
};

export type CreatePasswordExpirationInput = {
  owner: string,
  expiry: number,
};

export type ModelPasswordExpirationConditionInput = {
  expiry?: ModelIntInput | null,
  and?: Array< ModelPasswordExpirationConditionInput | null > | null,
  or?: Array< ModelPasswordExpirationConditionInput | null > | null,
  not?: ModelPasswordExpirationConditionInput | null,
};

export type PasswordExpiration = {
  __typename: "PasswordExpiration",
  owner: string,
  expiry: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePasswordExpirationInput = {
  owner: string,
  expiry?: number | null,
};

export type DeletePasswordExpirationInput = {
  owner: string,
};

export type PostBasalRecommendationResponse = {
  __typename: "PostBasalRecommendationResponse",
  status: number,
};

export type HandlerArgs = {
  task_input?: TaskInput | null,
  error?: boolean | null,
  force?: boolean | null,
};

export type TaskInput = {
  subject_id: string,
};

export type TitrateResponse = {
  __typename: "TitrateResponse",
  statusCode?: number | null,
  headers?: string | null,
  body?: string | null,
  errorMessage?: string | null,
  errorType?: string | null,
  stackTrace?: Array< string > | null,
};

export type ModelUserSiteFilterInput = {
  id?: ModelIDInput | null,
  user?: ModelIDInput | null,
  site?: ModelIDInput | null,
  and?: Array< ModelUserSiteFilterInput | null > | null,
  or?: Array< ModelUserSiteFilterInput | null > | null,
  not?: ModelUserSiteFilterInput | null,
};

export type ModelUserSiteConnection = {
  __typename: "ModelUserSiteConnection",
  items:  Array<UserSite | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelRecommendationFilterInput = {
  id?: ModelIDInput | null,
  timeCreated?: ModelIntInput | null,
  timeCreatedOffset?: ModelIntInput | null,
  reasons?: ModelResultReasonListInput | null,
  adjustment?: ModelFloatInput | null,
  baseDose?: ModelFloatInput | null,
  timeConfirmed?: ModelIntInput | null,
  timeConfirmedOffset?: ModelIntInput | null,
  subjectId?: ModelIDInput | null,
  resultSource?: ModelTitratorResultsSourceInput | null,
  algorithmId?: ModelIDInput | null,
  explanation?: ModelStringInput | null,
  and?: Array< ModelRecommendationFilterInput | null > | null,
  or?: Array< ModelRecommendationFilterInput | null > | null,
  not?: ModelRecommendationFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelAlgorithmFilterInput = {
  id?: ModelIDInput | null,
  timeCreated?: ModelIntInput | null,
  version?: ModelAlgorithmVersionInput | null,
  runnerVersion?: ModelStringInput | null,
  runner?: ModelStringInput | null,
  and?: Array< ModelAlgorithmFilterInput | null > | null,
  or?: Array< ModelAlgorithmFilterInput | null > | null,
  not?: ModelAlgorithmFilterInput | null,
};

export type ModelSubjectFilterInput = {
  id?: ModelIDInput | null,
  site?: ModelStringInput | null,
  titrationStatus?: ModelTitrationStatusInput | null,
  weight?: ModelIntInput | null,
  displayId?: ModelStringInput | null,
  apiTryToken?: ModelStringInput | null,
  apiStatus?: ModelApiStatusInput | null,
  enrollmentDate?: ModelIntInput | null,
  enrollmentDateOffset?: ModelIntInput | null,
  titratorVersion?: ModelAlgorithmVersionInput | null,
  and?: Array< ModelSubjectFilterInput | null > | null,
  or?: Array< ModelSubjectFilterInput | null > | null,
  not?: ModelSubjectFilterInput | null,
};

export type ModelSubjectConnection = {
  __typename: "ModelSubjectConnection",
  items:  Array<Subject | null >,
  nextToken?: string | null,
};

export type ModelSiteFilterInput = {
  id?: ModelIDInput | null,
  displayId?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  and?: Array< ModelSiteFilterInput | null > | null,
  or?: Array< ModelSiteFilterInput | null > | null,
  not?: ModelSiteFilterInput | null,
};

export type ModelSiteConnection = {
  __typename: "ModelSiteConnection",
  items:  Array<Site | null >,
  nextToken?: string | null,
};

export type ModelApiSecretFilterInput = {
  id?: ModelStringInput | null,
  expiry?: ModelIntInput | null,
  token?: ModelStringInput | null,
  and?: Array< ModelApiSecretFilterInput | null > | null,
  or?: Array< ModelApiSecretFilterInput | null > | null,
  not?: ModelApiSecretFilterInput | null,
};

export type ModelApiSecretConnection = {
  __typename: "ModelApiSecretConnection",
  items:  Array<ApiSecret | null >,
  nextToken?: string | null,
};

export type ModelPasswordExpirationFilterInput = {
  owner?: ModelStringInput | null,
  expiry?: ModelIntInput | null,
  and?: Array< ModelPasswordExpirationFilterInput | null > | null,
  or?: Array< ModelPasswordExpirationFilterInput | null > | null,
  not?: ModelPasswordExpirationFilterInput | null,
};

export type ModelPasswordExpirationConnection = {
  __typename: "ModelPasswordExpirationConnection",
  items:  Array<PasswordExpiration | null >,
  nextToken?: string | null,
};

export type ListEgvsResponse = {
  __typename: "ListEgvsResponse",
  status: number,
  response?: ListEgvsData | null,
};

export type ListEgvsData = {
  __typename: "ListEgvsData",
  recordType?: string | null,
  recordVersion?: string | null,
  userId?: string | null,
  records:  Array<Egv | null >,
};

export type Egv = {
  __typename: "Egv",
  recordId: string,
  systemTime: string,
  displayTime: string,
  transmitterId?: string | null,
  transmitterTicks?: number | null,
  value?: number | null,
  status?: string | null,
  trend?: string | null,
  trendRate?: number | null,
  displayDevice: string,
  transmitterGeneration: string,
  unit?: string | null,
  rateUnit?: string | null,
};

export type ListEventsResponse = {
  __typename: "ListEventsResponse",
  status: number,
  response?: ListEventsData | null,
};

export type ListEventsData = {
  __typename: "ListEventsData",
  recordType?: string | null,
  recordVersion?: string | null,
  userId?: string | null,
  records:  Array<Event | null >,
};

export type Event = {
  __typename: "Event",
  systemTime: string,
  displayTime: string,
  recordId?: string | null,
  eventStatus: string,
  eventType: string,
  eventSubType?: string | null,
  value: string,
  unit?: string | null,
  transmitterId: string,
  transmitterGeneration: string,
  displayDevice: string,
};

export type ListDataRangeResponse = {
  __typename: "ListDataRangeResponse",
  response?: ListDataRangeData | null,
  status: number,
};

export type ListDataRangeData = {
  __typename: "ListDataRangeData",
  recordType?: string | null,
  recordVersion?: string | null,
  userId?: string | null,
  calibrations?: DataRange | null,
  egvs?: DataRange | null,
  events?: DataRange | null,
};

export type DataRange = {
  __typename: "DataRange",
  start: TimeValue,
  end: TimeValue,
};

export type TimeValue = {
  __typename: "TimeValue",
  systemTime?: string | null,
  displayTime?: string | null,
};

export type ModelSubscriptionUserSiteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  user?: ModelSubscriptionIDInput | null,
  site?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionUserSiteFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserSiteFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionRecommendationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  timeCreated?: ModelSubscriptionIntInput | null,
  timeCreatedOffset?: ModelSubscriptionIntInput | null,
  reasons?: ModelSubscriptionStringInput | null,
  adjustment?: ModelSubscriptionFloatInput | null,
  baseDose?: ModelSubscriptionFloatInput | null,
  timeConfirmed?: ModelSubscriptionIntInput | null,
  timeConfirmedOffset?: ModelSubscriptionIntInput | null,
  subjectId?: ModelSubscriptionIDInput | null,
  resultSource?: ModelSubscriptionStringInput | null,
  algorithmId?: ModelSubscriptionIDInput | null,
  explanation?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRecommendationFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecommendationFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAlgorithmFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  timeCreated?: ModelSubscriptionIntInput | null,
  version?: ModelSubscriptionStringInput | null,
  runnerVersion?: ModelSubscriptionStringInput | null,
  runner?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAlgorithmFilterInput | null > | null,
  or?: Array< ModelSubscriptionAlgorithmFilterInput | null > | null,
};

export type ModelSubscriptionSubjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  titrationStatus?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionIntInput | null,
  displayId?: ModelSubscriptionStringInput | null,
  apiTryToken?: ModelSubscriptionStringInput | null,
  apiStatus?: ModelSubscriptionStringInput | null,
  enrollmentDate?: ModelSubscriptionIntInput | null,
  enrollmentDateOffset?: ModelSubscriptionIntInput | null,
  titratorVersion?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSubjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionSubjectFilterInput | null > | null,
};

export type ModelSubscriptionSiteFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  displayId?: ModelSubscriptionStringInput | null,
  timezone?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSiteFilterInput | null > | null,
  or?: Array< ModelSubscriptionSiteFilterInput | null > | null,
};

export type ModelSubscriptionApiSecretFilterInput = {
  id?: ModelSubscriptionStringInput | null,
  expiry?: ModelSubscriptionIntInput | null,
  token?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionApiSecretFilterInput | null > | null,
  or?: Array< ModelSubscriptionApiSecretFilterInput | null > | null,
};

export type ModelSubscriptionPasswordExpirationFilterInput = {
  owner?: ModelSubscriptionStringInput | null,
  expiry?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionPasswordExpirationFilterInput | null > | null,
  or?: Array< ModelSubscriptionPasswordExpirationFilterInput | null > | null,
};

export type CreateUserSiteMutationVariables = {
  input: CreateUserSiteInput,
  condition?: ModelUserSiteConditionInput | null,
};

export type CreateUserSiteMutation = {
  createUserSite?:  {
    __typename: "UserSite",
    id: string,
    user: string,
    site: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserSiteMutationVariables = {
  input: UpdateUserSiteInput,
  condition?: ModelUserSiteConditionInput | null,
};

export type UpdateUserSiteMutation = {
  updateUserSite?:  {
    __typename: "UserSite",
    id: string,
    user: string,
    site: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserSiteMutationVariables = {
  input: DeleteUserSiteInput,
  condition?: ModelUserSiteConditionInput | null,
};

export type DeleteUserSiteMutation = {
  deleteUserSite?:  {
    __typename: "UserSite",
    id: string,
    user: string,
    site: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRecommendationMutationVariables = {
  input: CreateRecommendationInput,
  condition?: ModelRecommendationConditionInput | null,
};

export type CreateRecommendationMutation = {
  createRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    timeCreated: number,
    timeCreatedOffset: number,
    reasons?: Array< ResultReason > | null,
    adjustment: number,
    baseDose?: number | null,
    timeConfirmed?: number | null,
    timeConfirmedOffset?: number | null,
    subjectId: string,
    resultSource: TitratorResultsSource,
    datasetRef?:  {
      __typename: "DatasetRef",
      identifier: string,
      s3_bucket: string,
      s3_key: string,
      datatype: string,
      md5?: string | null,
      s3_version_id?: string | null,
      meta?: string | null,
      file_format?: string | null,
    } | null,
    algorithmId: string,
    algorithm:  {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    },
    explanation?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRecommendationMutationVariables = {
  input: UpdateRecommendationInput,
  condition?: ModelRecommendationConditionInput | null,
};

export type UpdateRecommendationMutation = {
  updateRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    timeCreated: number,
    timeCreatedOffset: number,
    reasons?: Array< ResultReason > | null,
    adjustment: number,
    baseDose?: number | null,
    timeConfirmed?: number | null,
    timeConfirmedOffset?: number | null,
    subjectId: string,
    resultSource: TitratorResultsSource,
    datasetRef?:  {
      __typename: "DatasetRef",
      identifier: string,
      s3_bucket: string,
      s3_key: string,
      datatype: string,
      md5?: string | null,
      s3_version_id?: string | null,
      meta?: string | null,
      file_format?: string | null,
    } | null,
    algorithmId: string,
    algorithm:  {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    },
    explanation?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRecommendationMutationVariables = {
  input: DeleteRecommendationInput,
  condition?: ModelRecommendationConditionInput | null,
};

export type DeleteRecommendationMutation = {
  deleteRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    timeCreated: number,
    timeCreatedOffset: number,
    reasons?: Array< ResultReason > | null,
    adjustment: number,
    baseDose?: number | null,
    timeConfirmed?: number | null,
    timeConfirmedOffset?: number | null,
    subjectId: string,
    resultSource: TitratorResultsSource,
    datasetRef?:  {
      __typename: "DatasetRef",
      identifier: string,
      s3_bucket: string,
      s3_key: string,
      datatype: string,
      md5?: string | null,
      s3_version_id?: string | null,
      meta?: string | null,
      file_format?: string | null,
    } | null,
    algorithmId: string,
    algorithm:  {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    },
    explanation?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAlgorithmMutationVariables = {
  input: CreateAlgorithmInput,
  condition?: ModelAlgorithmConditionInput | null,
};

export type CreateAlgorithmMutation = {
  createAlgorithm?:  {
    __typename: "Algorithm",
    id: string,
    timeCreated: number,
    version: AlgorithmVersion,
    runnerVersion: string,
    runner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAlgorithmMutationVariables = {
  input: UpdateAlgorithmInput,
  condition?: ModelAlgorithmConditionInput | null,
};

export type UpdateAlgorithmMutation = {
  updateAlgorithm?:  {
    __typename: "Algorithm",
    id: string,
    timeCreated: number,
    version: AlgorithmVersion,
    runnerVersion: string,
    runner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAlgorithmMutationVariables = {
  input: DeleteAlgorithmInput,
  condition?: ModelAlgorithmConditionInput | null,
};

export type DeleteAlgorithmMutation = {
  deleteAlgorithm?:  {
    __typename: "Algorithm",
    id: string,
    timeCreated: number,
    version: AlgorithmVersion,
    runnerVersion: string,
    runner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSubjectMutationVariables = {
  input: CreateSubjectInput,
  condition?: ModelSubjectConditionInput | null,
};

export type CreateSubjectMutation = {
  createSubject?:  {
    __typename: "Subject",
    id: string,
    site: string,
    titrationStatus: TitrationStatus,
    weight: number,
    displayId: string,
    apiTryToken?: string | null,
    apiStatus: ApiStatus,
    enrollmentDate: number,
    enrollmentDateOffset: number,
    recommendations?:  {
      __typename: "ModelRecommendationConnection",
      items:  Array< {
        __typename: "Recommendation",
        id: string,
        timeCreated: number,
        timeCreatedOffset: number,
        reasons?: Array< ResultReason > | null,
        adjustment: number,
        baseDose?: number | null,
        timeConfirmed?: number | null,
        timeConfirmedOffset?: number | null,
        subjectId: string,
        resultSource: TitratorResultsSource,
        datasetRef?:  {
          __typename: "DatasetRef",
          identifier: string,
          s3_bucket: string,
          s3_key: string,
          datatype: string,
          md5?: string | null,
          s3_version_id?: string | null,
          meta?: string | null,
          file_format?: string | null,
        } | null,
        algorithmId: string,
        algorithm:  {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        },
        explanation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    titratorVersion: AlgorithmVersion,
    titratorAlgorithm?:  {
      __typename: "ModelAlgorithmConnection",
      items:  Array< {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSubjectMutationVariables = {
  input: UpdateSubjectInput,
  condition?: ModelSubjectConditionInput | null,
};

export type UpdateSubjectMutation = {
  updateSubject?:  {
    __typename: "Subject",
    id: string,
    site: string,
    titrationStatus: TitrationStatus,
    weight: number,
    displayId: string,
    apiTryToken?: string | null,
    apiStatus: ApiStatus,
    enrollmentDate: number,
    enrollmentDateOffset: number,
    recommendations?:  {
      __typename: "ModelRecommendationConnection",
      items:  Array< {
        __typename: "Recommendation",
        id: string,
        timeCreated: number,
        timeCreatedOffset: number,
        reasons?: Array< ResultReason > | null,
        adjustment: number,
        baseDose?: number | null,
        timeConfirmed?: number | null,
        timeConfirmedOffset?: number | null,
        subjectId: string,
        resultSource: TitratorResultsSource,
        datasetRef?:  {
          __typename: "DatasetRef",
          identifier: string,
          s3_bucket: string,
          s3_key: string,
          datatype: string,
          md5?: string | null,
          s3_version_id?: string | null,
          meta?: string | null,
          file_format?: string | null,
        } | null,
        algorithmId: string,
        algorithm:  {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        },
        explanation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    titratorVersion: AlgorithmVersion,
    titratorAlgorithm?:  {
      __typename: "ModelAlgorithmConnection",
      items:  Array< {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSubjectMutationVariables = {
  input: DeleteSubjectInput,
  condition?: ModelSubjectConditionInput | null,
};

export type DeleteSubjectMutation = {
  deleteSubject?:  {
    __typename: "Subject",
    id: string,
    site: string,
    titrationStatus: TitrationStatus,
    weight: number,
    displayId: string,
    apiTryToken?: string | null,
    apiStatus: ApiStatus,
    enrollmentDate: number,
    enrollmentDateOffset: number,
    recommendations?:  {
      __typename: "ModelRecommendationConnection",
      items:  Array< {
        __typename: "Recommendation",
        id: string,
        timeCreated: number,
        timeCreatedOffset: number,
        reasons?: Array< ResultReason > | null,
        adjustment: number,
        baseDose?: number | null,
        timeConfirmed?: number | null,
        timeConfirmedOffset?: number | null,
        subjectId: string,
        resultSource: TitratorResultsSource,
        datasetRef?:  {
          __typename: "DatasetRef",
          identifier: string,
          s3_bucket: string,
          s3_key: string,
          datatype: string,
          md5?: string | null,
          s3_version_id?: string | null,
          meta?: string | null,
          file_format?: string | null,
        } | null,
        algorithmId: string,
        algorithm:  {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        },
        explanation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    titratorVersion: AlgorithmVersion,
    titratorAlgorithm?:  {
      __typename: "ModelAlgorithmConnection",
      items:  Array< {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSiteMutationVariables = {
  input: CreateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type CreateSiteMutation = {
  createSite?:  {
    __typename: "Site",
    id: string,
    displayId: string,
    timezone: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSiteMutationVariables = {
  input: UpdateSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type UpdateSiteMutation = {
  updateSite?:  {
    __typename: "Site",
    id: string,
    displayId: string,
    timezone: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSiteMutationVariables = {
  input: DeleteSiteInput,
  condition?: ModelSiteConditionInput | null,
};

export type DeleteSiteMutation = {
  deleteSite?:  {
    __typename: "Site",
    id: string,
    displayId: string,
    timezone: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateApiSecretMutationVariables = {
  input: CreateApiSecretInput,
  condition?: ModelApiSecretConditionInput | null,
};

export type CreateApiSecretMutation = {
  createApiSecret?:  {
    __typename: "ApiSecret",
    id: string,
    expiry: number,
    token: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateApiSecretMutationVariables = {
  input: UpdateApiSecretInput,
  condition?: ModelApiSecretConditionInput | null,
};

export type UpdateApiSecretMutation = {
  updateApiSecret?:  {
    __typename: "ApiSecret",
    id: string,
    expiry: number,
    token: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteApiSecretMutationVariables = {
  input: DeleteApiSecretInput,
  condition?: ModelApiSecretConditionInput | null,
};

export type DeleteApiSecretMutation = {
  deleteApiSecret?:  {
    __typename: "ApiSecret",
    id: string,
    expiry: number,
    token: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePasswordExpirationMutationVariables = {
  input: CreatePasswordExpirationInput,
  condition?: ModelPasswordExpirationConditionInput | null,
};

export type CreatePasswordExpirationMutation = {
  createPasswordExpiration?:  {
    __typename: "PasswordExpiration",
    owner: string,
    expiry: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePasswordExpirationMutationVariables = {
  input: UpdatePasswordExpirationInput,
  condition?: ModelPasswordExpirationConditionInput | null,
};

export type UpdatePasswordExpirationMutation = {
  updatePasswordExpiration?:  {
    __typename: "PasswordExpiration",
    owner: string,
    expiry: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePasswordExpirationMutationVariables = {
  input: DeletePasswordExpirationInput,
  condition?: ModelPasswordExpirationConditionInput | null,
};

export type DeletePasswordExpirationMutation = {
  deletePasswordExpiration?:  {
    __typename: "PasswordExpiration",
    owner: string,
    expiry: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetApiRedirectMutationVariables = {
  id?: string | null,
  returnUrl?: string | null,
  debug?: boolean | null,
};

export type GetApiRedirectMutation = {
  getApiRedirect?: string | null,
};

export type PostBasalRecommendationMutationVariables = {
  id?: string | null,
  totalDose?: number | null,
  timeConfirmed?: string | null,
  debug?: boolean | null,
};

export type PostBasalRecommendationMutation = {
  postBasalRecommendation?:  {
    __typename: "PostBasalRecommendationResponse",
    status: number,
  } | null,
};

export type TitrateMutationVariables = {
  args?: HandlerArgs | null,
};

export type TitrateMutation = {
  titrate?:  {
    __typename: "TitrateResponse",
    statusCode?: number | null,
    headers?: string | null,
    body?: string | null,
    errorMessage?: string | null,
    errorType?: string | null,
    stackTrace?: Array< string > | null,
  } | null,
};

export type GetUserSiteQueryVariables = {
  id: string,
};

export type GetUserSiteQuery = {
  getUserSite?:  {
    __typename: "UserSite",
    id: string,
    user: string,
    site: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserSitesQueryVariables = {
  filter?: ModelUserSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSitesQuery = {
  listUserSites?:  {
    __typename: "ModelUserSiteConnection",
    items:  Array< {
      __typename: "UserSite",
      id: string,
      user: string,
      site: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserSiteByUserQueryVariables = {
  user: string,
  id?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserSiteByUserQuery = {
  userSiteByUser?:  {
    __typename: "ModelUserSiteConnection",
    items:  Array< {
      __typename: "UserSite",
      id: string,
      user: string,
      site: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecommendationQueryVariables = {
  id: string,
};

export type GetRecommendationQuery = {
  getRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    timeCreated: number,
    timeCreatedOffset: number,
    reasons?: Array< ResultReason > | null,
    adjustment: number,
    baseDose?: number | null,
    timeConfirmed?: number | null,
    timeConfirmedOffset?: number | null,
    subjectId: string,
    resultSource: TitratorResultsSource,
    datasetRef?:  {
      __typename: "DatasetRef",
      identifier: string,
      s3_bucket: string,
      s3_key: string,
      datatype: string,
      md5?: string | null,
      s3_version_id?: string | null,
      meta?: string | null,
      file_format?: string | null,
    } | null,
    algorithmId: string,
    algorithm:  {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    },
    explanation?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRecommendationsQueryVariables = {
  filter?: ModelRecommendationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecommendationsQuery = {
  listRecommendations?:  {
    __typename: "ModelRecommendationConnection",
    items:  Array< {
      __typename: "Recommendation",
      id: string,
      timeCreated: number,
      timeCreatedOffset: number,
      reasons?: Array< ResultReason > | null,
      adjustment: number,
      baseDose?: number | null,
      timeConfirmed?: number | null,
      timeConfirmedOffset?: number | null,
      subjectId: string,
      resultSource: TitratorResultsSource,
      datasetRef?:  {
        __typename: "DatasetRef",
        identifier: string,
        s3_bucket: string,
        s3_key: string,
        datatype: string,
        md5?: string | null,
        s3_version_id?: string | null,
        meta?: string | null,
        file_format?: string | null,
      } | null,
      algorithmId: string,
      algorithm:  {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      },
      explanation?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RecommendationsBySubjectAndTimeCreatedQueryVariables = {
  subjectId: string,
  timeCreated?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRecommendationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RecommendationsBySubjectAndTimeCreatedQuery = {
  recommendationsBySubjectAndTimeCreated?:  {
    __typename: "ModelRecommendationConnection",
    items:  Array< {
      __typename: "Recommendation",
      id: string,
      timeCreated: number,
      timeCreatedOffset: number,
      reasons?: Array< ResultReason > | null,
      adjustment: number,
      baseDose?: number | null,
      timeConfirmed?: number | null,
      timeConfirmedOffset?: number | null,
      subjectId: string,
      resultSource: TitratorResultsSource,
      datasetRef?:  {
        __typename: "DatasetRef",
        identifier: string,
        s3_bucket: string,
        s3_key: string,
        datatype: string,
        md5?: string | null,
        s3_version_id?: string | null,
        meta?: string | null,
        file_format?: string | null,
      } | null,
      algorithmId: string,
      algorithm:  {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      },
      explanation?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAlgorithmQueryVariables = {
  id: string,
};

export type GetAlgorithmQuery = {
  getAlgorithm?:  {
    __typename: "Algorithm",
    id: string,
    timeCreated: number,
    version: AlgorithmVersion,
    runnerVersion: string,
    runner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAlgorithmsQueryVariables = {
  filter?: ModelAlgorithmFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAlgorithmsQuery = {
  listAlgorithms?:  {
    __typename: "ModelAlgorithmConnection",
    items:  Array< {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AlgorithmsByVersionAndTimeCreatedQueryVariables = {
  version: AlgorithmVersion,
  timeCreated?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAlgorithmFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AlgorithmsByVersionAndTimeCreatedQuery = {
  algorithmsByVersionAndTimeCreated?:  {
    __typename: "ModelAlgorithmConnection",
    items:  Array< {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubjectQueryVariables = {
  id: string,
};

export type GetSubjectQuery = {
  getSubject?:  {
    __typename: "Subject",
    id: string,
    site: string,
    titrationStatus: TitrationStatus,
    weight: number,
    displayId: string,
    apiTryToken?: string | null,
    apiStatus: ApiStatus,
    enrollmentDate: number,
    enrollmentDateOffset: number,
    recommendations?:  {
      __typename: "ModelRecommendationConnection",
      items:  Array< {
        __typename: "Recommendation",
        id: string,
        timeCreated: number,
        timeCreatedOffset: number,
        reasons?: Array< ResultReason > | null,
        adjustment: number,
        baseDose?: number | null,
        timeConfirmed?: number | null,
        timeConfirmedOffset?: number | null,
        subjectId: string,
        resultSource: TitratorResultsSource,
        datasetRef?:  {
          __typename: "DatasetRef",
          identifier: string,
          s3_bucket: string,
          s3_key: string,
          datatype: string,
          md5?: string | null,
          s3_version_id?: string | null,
          meta?: string | null,
          file_format?: string | null,
        } | null,
        algorithmId: string,
        algorithm:  {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        },
        explanation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    titratorVersion: AlgorithmVersion,
    titratorAlgorithm?:  {
      __typename: "ModelAlgorithmConnection",
      items:  Array< {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSubjectsQueryVariables = {
  filter?: ModelSubjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubjectsQuery = {
  listSubjects?:  {
    __typename: "ModelSubjectConnection",
    items:  Array< {
      __typename: "Subject",
      id: string,
      site: string,
      titrationStatus: TitrationStatus,
      weight: number,
      displayId: string,
      apiTryToken?: string | null,
      apiStatus: ApiStatus,
      enrollmentDate: number,
      enrollmentDateOffset: number,
      recommendations?:  {
        __typename: "ModelRecommendationConnection",
        items:  Array< {
          __typename: "Recommendation",
          id: string,
          timeCreated: number,
          timeCreatedOffset: number,
          reasons?: Array< ResultReason > | null,
          adjustment: number,
          baseDose?: number | null,
          timeConfirmed?: number | null,
          timeConfirmedOffset?: number | null,
          subjectId: string,
          resultSource: TitratorResultsSource,
          datasetRef?:  {
            __typename: "DatasetRef",
            identifier: string,
            s3_bucket: string,
            s3_key: string,
            datatype: string,
            md5?: string | null,
            s3_version_id?: string | null,
            meta?: string | null,
            file_format?: string | null,
          } | null,
          algorithmId: string,
          algorithm:  {
            __typename: "Algorithm",
            id: string,
            timeCreated: number,
            version: AlgorithmVersion,
            runnerVersion: string,
            runner: string,
            createdAt: string,
            updatedAt: string,
          },
          explanation?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      titratorVersion: AlgorithmVersion,
      titratorAlgorithm?:  {
        __typename: "ModelAlgorithmConnection",
        items:  Array< {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SubjectsByDisplayIdQueryVariables = {
  displayId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubjectsByDisplayIdQuery = {
  subjectsByDisplayId?:  {
    __typename: "ModelSubjectConnection",
    items:  Array< {
      __typename: "Subject",
      id: string,
      site: string,
      titrationStatus: TitrationStatus,
      weight: number,
      displayId: string,
      apiTryToken?: string | null,
      apiStatus: ApiStatus,
      enrollmentDate: number,
      enrollmentDateOffset: number,
      recommendations?:  {
        __typename: "ModelRecommendationConnection",
        items:  Array< {
          __typename: "Recommendation",
          id: string,
          timeCreated: number,
          timeCreatedOffset: number,
          reasons?: Array< ResultReason > | null,
          adjustment: number,
          baseDose?: number | null,
          timeConfirmed?: number | null,
          timeConfirmedOffset?: number | null,
          subjectId: string,
          resultSource: TitratorResultsSource,
          datasetRef?:  {
            __typename: "DatasetRef",
            identifier: string,
            s3_bucket: string,
            s3_key: string,
            datatype: string,
            md5?: string | null,
            s3_version_id?: string | null,
            meta?: string | null,
            file_format?: string | null,
          } | null,
          algorithmId: string,
          algorithm:  {
            __typename: "Algorithm",
            id: string,
            timeCreated: number,
            version: AlgorithmVersion,
            runnerVersion: string,
            runner: string,
            createdAt: string,
            updatedAt: string,
          },
          explanation?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      titratorVersion: AlgorithmVersion,
      titratorAlgorithm?:  {
        __typename: "ModelAlgorithmConnection",
        items:  Array< {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSiteQueryVariables = {
  id: string,
};

export type GetSiteQuery = {
  getSite?:  {
    __typename: "Site",
    id: string,
    displayId: string,
    timezone: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSitesQueryVariables = {
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSitesQuery = {
  listSites?:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      displayId: string,
      timezone: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SitesByDisplayIdQueryVariables = {
  displayId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSiteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SitesByDisplayIdQuery = {
  sitesByDisplayId?:  {
    __typename: "ModelSiteConnection",
    items:  Array< {
      __typename: "Site",
      id: string,
      displayId: string,
      timezone: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetApiSecretQueryVariables = {
  id: string,
};

export type GetApiSecretQuery = {
  getApiSecret?:  {
    __typename: "ApiSecret",
    id: string,
    expiry: number,
    token: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListApiSecretsQueryVariables = {
  id?: string | null,
  filter?: ModelApiSecretFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListApiSecretsQuery = {
  listApiSecrets?:  {
    __typename: "ModelApiSecretConnection",
    items:  Array< {
      __typename: "ApiSecret",
      id: string,
      expiry: number,
      token: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPasswordExpirationQueryVariables = {
  owner: string,
};

export type GetPasswordExpirationQuery = {
  getPasswordExpiration?:  {
    __typename: "PasswordExpiration",
    owner: string,
    expiry: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPasswordExpirationsQueryVariables = {
  owner?: string | null,
  filter?: ModelPasswordExpirationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListPasswordExpirationsQuery = {
  listPasswordExpirations?:  {
    __typename: "ModelPasswordExpirationConnection",
    items:  Array< {
      __typename: "PasswordExpiration",
      owner: string,
      expiry: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEgvsQueryVariables = {
  id?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  debug?: boolean | null,
};

export type ListEgvsQuery = {
  listEgvs?:  {
    __typename: "ListEgvsResponse",
    status: number,
    response?:  {
      __typename: "ListEgvsData",
      recordType?: string | null,
      recordVersion?: string | null,
      userId?: string | null,
      records:  Array< {
        __typename: "Egv",
        recordId: string,
        systemTime: string,
        displayTime: string,
        transmitterId?: string | null,
        transmitterTicks?: number | null,
        value?: number | null,
        status?: string | null,
        trend?: string | null,
        trendRate?: number | null,
        displayDevice: string,
        transmitterGeneration: string,
        unit?: string | null,
        rateUnit?: string | null,
      } | null >,
    } | null,
  } | null,
};

export type ListEventsQueryVariables = {
  id?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  debug?: boolean | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ListEventsResponse",
    status: number,
    response?:  {
      __typename: "ListEventsData",
      recordType?: string | null,
      recordVersion?: string | null,
      userId?: string | null,
      records:  Array< {
        __typename: "Event",
        systemTime: string,
        displayTime: string,
        recordId?: string | null,
        eventStatus: string,
        eventType: string,
        eventSubType?: string | null,
        value: string,
        unit?: string | null,
        transmitterId: string,
        transmitterGeneration: string,
        displayDevice: string,
      } | null >,
    } | null,
  } | null,
};

export type ListDataRangeQueryVariables = {
  id?: string | null,
  debug?: boolean | null,
};

export type ListDataRangeQuery = {
  listDataRange?:  {
    __typename: "ListDataRangeResponse",
    response?:  {
      __typename: "ListDataRangeData",
      recordType?: string | null,
      recordVersion?: string | null,
      userId?: string | null,
      calibrations?:  {
        __typename: "DataRange",
        start:  {
          __typename: "TimeValue",
          systemTime?: string | null,
          displayTime?: string | null,
        },
        end:  {
          __typename: "TimeValue",
          systemTime?: string | null,
          displayTime?: string | null,
        },
      } | null,
      egvs?:  {
        __typename: "DataRange",
        start:  {
          __typename: "TimeValue",
          systemTime?: string | null,
          displayTime?: string | null,
        },
        end:  {
          __typename: "TimeValue",
          systemTime?: string | null,
          displayTime?: string | null,
        },
      } | null,
      events?:  {
        __typename: "DataRange",
        start:  {
          __typename: "TimeValue",
          systemTime?: string | null,
          displayTime?: string | null,
        },
        end:  {
          __typename: "TimeValue",
          systemTime?: string | null,
          displayTime?: string | null,
        },
      } | null,
    } | null,
    status: number,
  } | null,
};

export type OnCreateUserSiteSubscriptionVariables = {
  filter?: ModelSubscriptionUserSiteFilterInput | null,
};

export type OnCreateUserSiteSubscription = {
  onCreateUserSite?:  {
    __typename: "UserSite",
    id: string,
    user: string,
    site: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSiteSubscriptionVariables = {
  filter?: ModelSubscriptionUserSiteFilterInput | null,
};

export type OnUpdateUserSiteSubscription = {
  onUpdateUserSite?:  {
    __typename: "UserSite",
    id: string,
    user: string,
    site: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSiteSubscriptionVariables = {
  filter?: ModelSubscriptionUserSiteFilterInput | null,
};

export type OnDeleteUserSiteSubscription = {
  onDeleteUserSite?:  {
    __typename: "UserSite",
    id: string,
    user: string,
    site: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRecommendationSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationFilterInput | null,
};

export type OnCreateRecommendationSubscription = {
  onCreateRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    timeCreated: number,
    timeCreatedOffset: number,
    reasons?: Array< ResultReason > | null,
    adjustment: number,
    baseDose?: number | null,
    timeConfirmed?: number | null,
    timeConfirmedOffset?: number | null,
    subjectId: string,
    resultSource: TitratorResultsSource,
    datasetRef?:  {
      __typename: "DatasetRef",
      identifier: string,
      s3_bucket: string,
      s3_key: string,
      datatype: string,
      md5?: string | null,
      s3_version_id?: string | null,
      meta?: string | null,
      file_format?: string | null,
    } | null,
    algorithmId: string,
    algorithm:  {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    },
    explanation?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRecommendationSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationFilterInput | null,
};

export type OnUpdateRecommendationSubscription = {
  onUpdateRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    timeCreated: number,
    timeCreatedOffset: number,
    reasons?: Array< ResultReason > | null,
    adjustment: number,
    baseDose?: number | null,
    timeConfirmed?: number | null,
    timeConfirmedOffset?: number | null,
    subjectId: string,
    resultSource: TitratorResultsSource,
    datasetRef?:  {
      __typename: "DatasetRef",
      identifier: string,
      s3_bucket: string,
      s3_key: string,
      datatype: string,
      md5?: string | null,
      s3_version_id?: string | null,
      meta?: string | null,
      file_format?: string | null,
    } | null,
    algorithmId: string,
    algorithm:  {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    },
    explanation?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRecommendationSubscriptionVariables = {
  filter?: ModelSubscriptionRecommendationFilterInput | null,
};

export type OnDeleteRecommendationSubscription = {
  onDeleteRecommendation?:  {
    __typename: "Recommendation",
    id: string,
    timeCreated: number,
    timeCreatedOffset: number,
    reasons?: Array< ResultReason > | null,
    adjustment: number,
    baseDose?: number | null,
    timeConfirmed?: number | null,
    timeConfirmedOffset?: number | null,
    subjectId: string,
    resultSource: TitratorResultsSource,
    datasetRef?:  {
      __typename: "DatasetRef",
      identifier: string,
      s3_bucket: string,
      s3_key: string,
      datatype: string,
      md5?: string | null,
      s3_version_id?: string | null,
      meta?: string | null,
      file_format?: string | null,
    } | null,
    algorithmId: string,
    algorithm:  {
      __typename: "Algorithm",
      id: string,
      timeCreated: number,
      version: AlgorithmVersion,
      runnerVersion: string,
      runner: string,
      createdAt: string,
      updatedAt: string,
    },
    explanation?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAlgorithmSubscriptionVariables = {
  filter?: ModelSubscriptionAlgorithmFilterInput | null,
};

export type OnCreateAlgorithmSubscription = {
  onCreateAlgorithm?:  {
    __typename: "Algorithm",
    id: string,
    timeCreated: number,
    version: AlgorithmVersion,
    runnerVersion: string,
    runner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAlgorithmSubscriptionVariables = {
  filter?: ModelSubscriptionAlgorithmFilterInput | null,
};

export type OnUpdateAlgorithmSubscription = {
  onUpdateAlgorithm?:  {
    __typename: "Algorithm",
    id: string,
    timeCreated: number,
    version: AlgorithmVersion,
    runnerVersion: string,
    runner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAlgorithmSubscriptionVariables = {
  filter?: ModelSubscriptionAlgorithmFilterInput | null,
};

export type OnDeleteAlgorithmSubscription = {
  onDeleteAlgorithm?:  {
    __typename: "Algorithm",
    id: string,
    timeCreated: number,
    version: AlgorithmVersion,
    runnerVersion: string,
    runner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSubjectSubscriptionVariables = {
  filter?: ModelSubscriptionSubjectFilterInput | null,
};

export type OnCreateSubjectSubscription = {
  onCreateSubject?:  {
    __typename: "Subject",
    id: string,
    site: string,
    titrationStatus: TitrationStatus,
    weight: number,
    displayId: string,
    apiTryToken?: string | null,
    apiStatus: ApiStatus,
    enrollmentDate: number,
    enrollmentDateOffset: number,
    recommendations?:  {
      __typename: "ModelRecommendationConnection",
      items:  Array< {
        __typename: "Recommendation",
        id: string,
        timeCreated: number,
        timeCreatedOffset: number,
        reasons?: Array< ResultReason > | null,
        adjustment: number,
        baseDose?: number | null,
        timeConfirmed?: number | null,
        timeConfirmedOffset?: number | null,
        subjectId: string,
        resultSource: TitratorResultsSource,
        datasetRef?:  {
          __typename: "DatasetRef",
          identifier: string,
          s3_bucket: string,
          s3_key: string,
          datatype: string,
          md5?: string | null,
          s3_version_id?: string | null,
          meta?: string | null,
          file_format?: string | null,
        } | null,
        algorithmId: string,
        algorithm:  {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        },
        explanation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    titratorVersion: AlgorithmVersion,
    titratorAlgorithm?:  {
      __typename: "ModelAlgorithmConnection",
      items:  Array< {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSubjectSubscriptionVariables = {
  filter?: ModelSubscriptionSubjectFilterInput | null,
};

export type OnUpdateSubjectSubscription = {
  onUpdateSubject?:  {
    __typename: "Subject",
    id: string,
    site: string,
    titrationStatus: TitrationStatus,
    weight: number,
    displayId: string,
    apiTryToken?: string | null,
    apiStatus: ApiStatus,
    enrollmentDate: number,
    enrollmentDateOffset: number,
    recommendations?:  {
      __typename: "ModelRecommendationConnection",
      items:  Array< {
        __typename: "Recommendation",
        id: string,
        timeCreated: number,
        timeCreatedOffset: number,
        reasons?: Array< ResultReason > | null,
        adjustment: number,
        baseDose?: number | null,
        timeConfirmed?: number | null,
        timeConfirmedOffset?: number | null,
        subjectId: string,
        resultSource: TitratorResultsSource,
        datasetRef?:  {
          __typename: "DatasetRef",
          identifier: string,
          s3_bucket: string,
          s3_key: string,
          datatype: string,
          md5?: string | null,
          s3_version_id?: string | null,
          meta?: string | null,
          file_format?: string | null,
        } | null,
        algorithmId: string,
        algorithm:  {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        },
        explanation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    titratorVersion: AlgorithmVersion,
    titratorAlgorithm?:  {
      __typename: "ModelAlgorithmConnection",
      items:  Array< {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSubjectSubscriptionVariables = {
  filter?: ModelSubscriptionSubjectFilterInput | null,
};

export type OnDeleteSubjectSubscription = {
  onDeleteSubject?:  {
    __typename: "Subject",
    id: string,
    site: string,
    titrationStatus: TitrationStatus,
    weight: number,
    displayId: string,
    apiTryToken?: string | null,
    apiStatus: ApiStatus,
    enrollmentDate: number,
    enrollmentDateOffset: number,
    recommendations?:  {
      __typename: "ModelRecommendationConnection",
      items:  Array< {
        __typename: "Recommendation",
        id: string,
        timeCreated: number,
        timeCreatedOffset: number,
        reasons?: Array< ResultReason > | null,
        adjustment: number,
        baseDose?: number | null,
        timeConfirmed?: number | null,
        timeConfirmedOffset?: number | null,
        subjectId: string,
        resultSource: TitratorResultsSource,
        datasetRef?:  {
          __typename: "DatasetRef",
          identifier: string,
          s3_bucket: string,
          s3_key: string,
          datatype: string,
          md5?: string | null,
          s3_version_id?: string | null,
          meta?: string | null,
          file_format?: string | null,
        } | null,
        algorithmId: string,
        algorithm:  {
          __typename: "Algorithm",
          id: string,
          timeCreated: number,
          version: AlgorithmVersion,
          runnerVersion: string,
          runner: string,
          createdAt: string,
          updatedAt: string,
        },
        explanation?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    titratorVersion: AlgorithmVersion,
    titratorAlgorithm?:  {
      __typename: "ModelAlgorithmConnection",
      items:  Array< {
        __typename: "Algorithm",
        id: string,
        timeCreated: number,
        version: AlgorithmVersion,
        runnerVersion: string,
        runner: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSiteSubscriptionVariables = {
  filter?: ModelSubscriptionSiteFilterInput | null,
};

export type OnCreateSiteSubscription = {
  onCreateSite?:  {
    __typename: "Site",
    id: string,
    displayId: string,
    timezone: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSiteSubscriptionVariables = {
  filter?: ModelSubscriptionSiteFilterInput | null,
};

export type OnUpdateSiteSubscription = {
  onUpdateSite?:  {
    __typename: "Site",
    id: string,
    displayId: string,
    timezone: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSiteSubscriptionVariables = {
  filter?: ModelSubscriptionSiteFilterInput | null,
};

export type OnDeleteSiteSubscription = {
  onDeleteSite?:  {
    __typename: "Site",
    id: string,
    displayId: string,
    timezone: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateApiSecretSubscriptionVariables = {
  filter?: ModelSubscriptionApiSecretFilterInput | null,
};

export type OnCreateApiSecretSubscription = {
  onCreateApiSecret?:  {
    __typename: "ApiSecret",
    id: string,
    expiry: number,
    token: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateApiSecretSubscriptionVariables = {
  filter?: ModelSubscriptionApiSecretFilterInput | null,
};

export type OnUpdateApiSecretSubscription = {
  onUpdateApiSecret?:  {
    __typename: "ApiSecret",
    id: string,
    expiry: number,
    token: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteApiSecretSubscriptionVariables = {
  filter?: ModelSubscriptionApiSecretFilterInput | null,
};

export type OnDeleteApiSecretSubscription = {
  onDeleteApiSecret?:  {
    __typename: "ApiSecret",
    id: string,
    expiry: number,
    token: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePasswordExpirationSubscriptionVariables = {
  filter?: ModelSubscriptionPasswordExpirationFilterInput | null,
};

export type OnCreatePasswordExpirationSubscription = {
  onCreatePasswordExpiration?:  {
    __typename: "PasswordExpiration",
    owner: string,
    expiry: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePasswordExpirationSubscriptionVariables = {
  filter?: ModelSubscriptionPasswordExpirationFilterInput | null,
};

export type OnUpdatePasswordExpirationSubscription = {
  onUpdatePasswordExpiration?:  {
    __typename: "PasswordExpiration",
    owner: string,
    expiry: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePasswordExpirationSubscriptionVariables = {
  filter?: ModelSubscriptionPasswordExpirationFilterInput | null,
};

export type OnDeletePasswordExpirationSubscription = {
  onDeletePasswordExpiration?:  {
    __typename: "PasswordExpiration",
    owner: string,
    expiry: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};
