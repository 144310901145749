import React from "react";
import {Stack, Tooltip, Typography} from "@mui/material";
import {IHistoricalRow} from "../../utils/Interfaces";
import {Recommendation} from "../../@dexbasal";
import {timestampToDate} from "../../utils/Locale";

const RecommendationRow: React.FunctionComponent<IHistoricalRow> = (props) => {
  const recommendation = props.record as Recommendation
  const baseDoseValue = Math.floor(recommendation.baseDose as number);
  const baseDoseStr = Math.floor(recommendation.baseDose as number).toString();
  const baseDoseUnit = baseDoseValue === 1 ? "Unit" : "Units";
  const adjustedDoseValue = Math.floor(recommendation.adjustment as number);
  const adjustedDoseStr = (recommendation.adjustment as number) < 0 ? `(${Math.floor(recommendation.adjustment as number).toString()})` : Math.floor(recommendation.adjustment as number).toString();
  const adjustedDoseUnit = Math.abs(adjustedDoseValue) === 1 ? "Unit" : "Units";
  const totalDoseValue = (Math.floor(recommendation.baseDose as number) + Math.floor(recommendation.adjustment as number));
  const totalDoseStr = (Math.floor(recommendation.baseDose as number) + Math.floor(recommendation.adjustment as number)).toString();
  const totalDoseUnit = totalDoseValue === 1 ? "Unit" : "Units";
  const pad = Math.max(...[baseDoseStr, adjustedDoseStr, totalDoseStr].map(s => s.length)) + 1
  const uPad = Math.max(...[baseDoseUnit, adjustedDoseUnit, totalDoseUnit].map(s => s.length))
  const tip1 = ` ${baseDoseStr.padStart(pad)} ${baseDoseUnit.padEnd(uPad)} (Algorithm)`;
  const tip2 = `+${adjustedDoseStr.padStart(pad)} ${adjustedDoseUnit.padEnd(uPad)} (Adjustment)`;
  const tip3 = `=${totalDoseStr.padStart(pad)} ${totalDoseUnit.padEnd(uPad)} (Confirmed)`;
  return (
    <Stack>
        <Tooltip title={<pre style={{font: "code"}}>{tip1}<br/>{tip2}<br/>{tip3}</pre>}>
            <Typography
                variant={'h6'}
                component={'p'}>
                <strong>{totalDoseValue} {totalDoseValue === 1 ? "Unit" : "Units"}</strong>
            </Typography>
        </Tooltip>
        <Tooltip title="Confirmed Time">
            <Typography color={'text.secondary'}>
                {timestampToDate(recommendation.timeConfirmed)}
            </Typography>
        </Tooltip>
    </Stack>
  );
}

export default RecommendationRow
