const DEFAULT_TIMEZONES = [
    "America/Puerto_Rico",
    "America/New_York",
    "America/Chicago",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "Pacific/Honolulu",
    "Pacific/Guam",
    "Pacific/Saipan",
]

const getBrowserTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

const isValidTimezone = (tz: string): boolean => {
    try {
        Intl.DateTimeFormat(undefined, {timeZone: tz})
        return true
    }
    catch (ex) {
        return false
    }
}

export {DEFAULT_TIMEZONES, getBrowserTimezone, isValidTimezone}