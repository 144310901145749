import { Analytics } from "aws-amplify";
import reactAnalytics from "./ReactAnalytics";
import awsmobile from "../aws-exports";
import {AnalyticsOutput, StateData} from "./Interfaces";

function telemetry(
  name: string,
  data: any,
  debug:boolean):void
{
  const context = reactAnalytics.getState('context')

  const stateData: StateData = {
    locale: context.locale,
    offline: context.offline,
    os: context.os.name,
    referrer: context.referrer,
    timezone: context.timezone,
    userAgent: context.userAgent,
    sessionId: context.sessionId,
    app: context.app,
    version: process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : 'none'
  }

  const telemetryOutput:AnalyticsOutput = {
    type: name,
    time: new Date().getTime(),
    userId: reactAnalytics.user("userId"),
    userEmail: reactAnalytics.user("traits.email"),
    state: stateData,
    attributes: {
      ...data
    }
  }

  Analytics.record({
    data: JSON.stringify(telemetryOutput)+"\n",
    partitionKey: '0',
    streamName: awsmobile.aws_firehose_name
  }, 'AWSKinesisFirehose');

  if (debug){
    console.log(telemetryOutput)
  }
}

interface Payload {
  event: string,
  meta: any,
  options: any,
  properties: any
  type: any,
  userId?: string
}

interface Data {
  payload: Payload
  abort: any
  config: any
}

export function kinesisFirehosePlugin(userConfig = {}) {
  return {
    name: 'firehose',
    config: Object.assign({}, userConfig),
    initialize: (data: any) => {
    },
    page: (data:any) => {
      if(data.config.analyticsDebug) {
        console.log(data)
      }

      telemetry(
        "page",
        data.payload.options,
        data.config.debug
      )
    },
    identify: (data:Data) => {
      if(data.config.analyticsDebug) {
        console.log(data)
      }
    },
    track: (data: Data) => {
      if(data.config.analyticsDebug) {
        console.log(data)
      }

      telemetry(
        data.payload.options.type,
        {
          eventName: data.payload.event,
          ...data.payload.properties
        },
        data.config.debug
      )
    },
    reset: (data: Data) => {
      if(data.config.analyticsDebug) {
        console.log(data)
      }
    }
  }
}