import React, {useState} from "react";
import {Button, Card, CardContent, CardHeader, Skeleton, Stack, Typography} from "@mui/material";
import Dots from "./Dots";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {IHistoricalView} from "../../utils/Interfaces";

const HistoricalView: React.FunctionComponent<IHistoricalView> = (props) => {
  const [index, setIndex] = useState(0)

  const updateIndex = (increment: boolean) => {
    const totalLength = props.records ? props.records.length - 1 : 0

    if(increment) {
      if(index + 1 <= totalLength) {
        setIndex(index + 1)
      } else {
      }
    } else {
      if(index - 1 >= 0) {
        setIndex(index - 1)
      } else {
      }
    }
  }

  return (
      <Card sx={{minHeight: 200}}>
        <CardHeader title={'Recent ' + props.title} titleTypographyProps={{}}/>
        {props.records ? (
            props.records.length >= 1 ? (
              <CardContent>
                  <Stack direction='row' spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <Button variant={'text'}
                            disabled={index === props.records.length - 1}
                            color={'secondary'}
                            aria-label={'Previous ' + props.title}
                            onClick={() => updateIndex(true)}>
                      <ArrowBackIosIcon/>
                    </Button>
                    {props.rowTile({record: props.records[index]})}
                    <Button variant={'text'}
                            disabled={index === 0}
                            color={'secondary'}
                            aria-label={'Next ' + props.title}
                            onClick={() => updateIndex(false)}>
                      <ArrowForwardIosIcon/>
                    </Button>
                  </Stack>
                  <Dots index={index} length={props.records.length}/>
                </CardContent>
              ):(
                <CardContent>
                  <Typography variant={'subtitle2'}><em>{props.retrieveError ? "Error retrieving history." : "No history available."}</em></Typography>
                </CardContent>
              )
            ):(
              <Skeleton variant={'rectangular'} height={200} width={350}/>
            )
        }
      </Card>
  );
}

export default HistoricalView
