import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import SCREENS from "../../utils/Screens";
import {
    AppBar,
    Box,
    Button,
    Container,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {createSubject} from "../../graphql/base/mutations";
import {
    AlgorithmVersion,
    ApiStatus,
    CreateSubjectMutation,
    CreateSubjectMutationVariables,
    Subject,
    TitrationStatus
} from "../../@dexbasal";
import {timezoneOffsetInSeconds, utcSecondsSinceEpoch} from "../../utils/Utils";
import {ArrowBack} from "@mui/icons-material";
import DexcomRegistration from "./DexcomRegistration";
import {NavClickHandler, NetworkAlertHandler, SubjectHandler} from "../../utils/Interfaces";
import NetworkError from "../Alerts/NetworkError";
import {checkUniqueDisplayId, UNIQUE_ID} from "../../utils/UniqueId";
import {trackError, trackScreen} from "../../analytics/ReactAnalytics";
import DexbasalAPI from "../../api/DexbasalApi";
import {AlgorithmOptions, getAlgorithmOptions} from "../AlgorithmOptions";

interface IProps {
    onNavClick: NavClickHandler,
    onNetworkError: NetworkAlertHandler,
    networkError: boolean,
    onSubjectAdded: SubjectHandler,
    onSubjectUpdate: SubjectHandler,
    site: string,
    debugApi: boolean,
    api: DexbasalAPI,
    maxHeight: number
}

const AddSubject: React.FunctionComponent<IProps> = (props) => {
    const {api, debugApi, onNetworkError: handleNetworkError, site} = props;
    const [subject, setSubject] = useState<Subject|undefined>()
    const [formWeight, setFormWeight] = useState<string>("0")
    const [validWeight, setValidWeight] = useState<boolean>(true)
    const showErrorAlert = props.networkError;
    const [activeStep, setActiveStep] = useState<number>(0)
    const [displayId, setDisplayId] = useState<string>("");
    const [validDisplayId, setValidDisplayId] = useState<boolean>(false);
    const [uniqueDisplayId, setUniqueDisplayId] = useState<UNIQUE_ID>(UNIQUE_ID.UNKNOWN);
    const [displayIdHelperText, setDisplayIdHelperText] = useState<string>(
      'Minimum of 4 characters that do not personally identify the subject.'
    )
    const [selectedAlgorithm, setSelectedAlgorithm] = useState<AlgorithmVersion | null>(null)
    const algorithmOptions: AlgorithmOptions[] = getAlgorithmOptions().filter(algo => algo.algorithm !== AlgorithmVersion.START_V_1)

    useEffect(() => {
        trackScreen({screen: SCREENS.ADD_SUBJECT})
    }, [])

    const handleAlgorithmSelect = useCallback((event: SelectChangeEvent) => {
        const algorithmVersionEvent = event.target.value as AlgorithmVersion
        setSelectedAlgorithm(algorithmVersionEvent)
    }, [setSelectedAlgorithm])

    const addSubject = (formEvent: HTMLButtonElement) => {
        // do nothing with invalid data
        if (!validWeight || !validDisplayId || !(uniqueDisplayId === UNIQUE_ID.UNIQUE) || selectedAlgorithm === null) {
            return false
        }

        const enrollmentDate = new Date();
        let variables: CreateSubjectMutationVariables = {
            input: {
                site,
                weight: parseInt(formWeight.toString()),
                titrationStatus: TitrationStatus.TITRATING,
                displayId: displayId,
                apiStatus: ApiStatus.PENDING,
                enrollmentDate: utcSecondsSinceEpoch(enrollmentDate),
                enrollmentDateOffset: timezoneOffsetInSeconds(enrollmentDate),
                titratorVersion: selectedAlgorithm
            }
        }

        const query = api.invoke<CreateSubjectMutation, CreateSubjectMutationVariables>(createSubject, variables)
        query.then((response) => {
            handleNetworkError(false)
            const newSubject = response.data.createSubject!;
            props.onSubjectAdded(newSubject)
            setSubject(newSubject)
            setActiveStep(activeStep + 1)
        }).catch((e) => {
            formEvent.disabled = false;

            if(debugApi) {
                console.error('Error during addSubject from createSubject.', e);
            }
            handleNetworkError(true)
            trackError("createSubject", {function: "addSubject"});
        })
    }

    const steps = [
        {
            label: 'Select Mode',
            content: <Box>
                <Box sx={{ mb: 2}}>
                    <FormControl sx={{textAlign: 'left', width: 200}}>
                        <InputLabel id="select-algo-label">Mode</InputLabel>
                        <Select
                          autoFocus
                          labelId="select-algo-label"
                          id="select-algo"
                          value={selectedAlgorithm ? selectedAlgorithm : ''}
                          label="Mode"
                          onChange={handleAlgorithmSelect}
                        >
                            {algorithmOptions.map(g =>
                              <MenuItem key={g.algorithm} value={g.algorithm}>
                                  <Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
                                      {g.icon}
                                      <Typography sx={{paddingLeft:1}}>{g.description}</Typography>
                                  </Box>
                              </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Button
                    disabled={selectedAlgorithm === null}
                    variant={'contained'}
                    onClick={(event) => {
                        event.currentTarget.disabled = true
                        setActiveStep(activeStep + 1)
                    }}>
                    Next
                </Button>
            </Box>
        },{
            label: 'Enter Subject\'s Display ID',
            content: <Box>
                <TextField
                    fullWidth
                    autoFocus
                    id={'displayId'}
                    label={'Subject display ID'}
                    type={'text'}
                    margin={'dense'}
                    error={!validDisplayId}
                    helperText={displayIdHelperText}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                        setDisplayId(e.target.value)
                        setUniqueDisplayId(UNIQUE_ID.UNKNOWN)
                    }}
                    value={displayId}
                />
                <Button
                    disabled={!validDisplayId || uniqueDisplayId === UNIQUE_ID.CHECKING}
                    variant={'contained'}
                    onClick={async () => {
                        if(uniqueDisplayId === UNIQUE_ID.CHECKING) {
                            return;
                        }
                        setUniqueDisplayId(UNIQUE_ID.CHECKING);
                        const isUnique = await checkUniqueDisplayId(displayId, handleNetworkError, debugApi);
                        if(isUnique && validDisplayId) {
                            // Set unique flag and move to next step
                            setUniqueDisplayId(UNIQUE_ID.UNIQUE)
                            setActiveStep(activeStep + 1)
                        } else {
                            // Update the form
                            setUniqueDisplayId(UNIQUE_ID.DUPLICATE);
                            setDisplayIdHelperText('This display ID already exists!  Please modify the text.')
                        }
                    }}
                    >
                    Next
                </Button>
                <Button
                    variant={'outlined'}
                    sx={{
                        marginLeft: 2,
                    }}
                    onClick={() => {
                        setActiveStep(activeStep - 1)
                    }}
                >Back</Button>
            </Box>
        },{
            label: 'Add Weight',
            content: <Box>
                <TextField
                    autoFocus
                    id={'weight'}
                    label={'Weight'}
                    type={'number'}
                    margin={'dense'}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                    error={!validWeight}
                    helperText={'Weight must be between 40 and 200'}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                            setFormWeight(e.target.value);
                    }}
                    value={formWeight}
                />
                <Button
                    disabled={!validWeight || formWeight === "0"}
                    variant={'contained'}
                    onClick={(event) => {
                        event.currentTarget.disabled = true
                        addSubject(event.currentTarget)
                    }}>
                    Add Subject
                </Button>
                <Button
                    variant={'outlined'}
                    sx={{
                        marginLeft: 2,
                    }}
                    onClick={() => {
                        setActiveStep(activeStep - 1)
                    }}
                >Back</Button>
            </Box>
        },{
            label: 'Register With Dexcom',
            content: <DexcomRegistration subject={subject} onNetworkAlert={handleNetworkError} onSubjectUpdate={props.onSubjectUpdate} debugApi={debugApi} api={api}/>
        },
    ]

    useLayoutEffect(() => {
        const intFormWeight = parseInt(formWeight);

        setValidWeight((intFormWeight >= 40 && intFormWeight <= 200) || intFormWeight === 0)
        setValidDisplayId((displayId.length >= 4) && (uniqueDisplayId !== UNIQUE_ID.DUPLICATE))
        if(uniqueDisplayId !== UNIQUE_ID.DUPLICATE) {
            setDisplayIdHelperText('Minimum of 4 characters that do not personally identify the subject.')
        }

    }, [formWeight, setValidWeight, displayId, setValidDisplayId, uniqueDisplayId])

    return (
        <Container disableGutters={true} maxWidth={false}>
            <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
                <Toolbar>
                    <Button
                        id={'backButton'}
                        variant={'outlined'}
                        color={'inherit'}
                        sx={{marginRight: 2}}
                        startIcon={<ArrowBack/>}
                        onClick={() => props.onNavClick(SCREENS.SUBJECT_OVERVIEW)}>
                        Back</Button>
                    <Typography variant={'h6'} sx={{flexGrow: .85}}>
                        {SCREENS.ADD_SUBJECT} {subject && subject.displayId}
                    </Typography>
                </Toolbar>
            </AppBar>

            <Container component={'form'} maxWidth={'xs'} sx={{marginTop: 2}} style={{maxHeight: props.maxHeight === -1 ? 'none' : props.maxHeight, overflow:'auto'}}>
                <Stepper activeStep={activeStep} orientation={'vertical'}>
                    {steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>
                                {step.content}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>

                <NetworkError show={showErrorAlert}/>
            </Container>
        </Container>
    )

}

export default AddSubject
