import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {
    ApiStatus,
    Egv,
    Event,
    GlucoseUnit,
    ModelSortDirection,
    Recommendation,
    RecommendationsBySubjectAndTimeCreatedQuery,
    RecommendationsBySubjectAndTimeCreatedQueryVariables,
    Subject,
    TitrationStatus,
    UpdateSubjectMutation,
    UpdateSubjectMutationVariables,
} from "../../@dexbasal";
import SCREENS from "../../utils/Screens";
import {
    Alert,
    AlertTitle,
    AppBar,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    InputAdornment,
    Skeleton,
    Snackbar,
    Stack,
    TextField,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import HistoricalView from "../HistoricalView/HistoricalView";
import {
    recommendationsBySubjectAndTimeCreated
} from "../../graphql/base/queries";
import RecommendationRow from "../HistoricalView/RecommendationRow";
import InjectionRow from "../HistoricalView/InjectionRow";
import RecommendationReview from "../RecommendationReview/RecommendationReview";
import Graph from "../Graph/Graph";
import {
    convertEgvSafe,
    dateTimeFromUtcSecondsSinceEpoch,
    dateTimeStringToSecondsSinceEpoch,
    threeDayWindowOrGreater,
    transformMgdlEgvRecordToMmol,
    utcSecondsSinceEpoch
} from "../../utils/Utils";
import EditIcon from '@mui/icons-material/Edit';
import DexcomRegistration from "../AddSubject/DexcomRegistration";
import Export from "../Export/Export";
import {NavClickHandler, NetworkAlertHandler, SubjectHandler} from "../../utils/Interfaces";
import NetworkError from "../Alerts/NetworkError";
import {checkUniqueDisplayId, UNIQUE_ID} from "../../utils/UniqueId";
import {trackError, trackEvent, trackScreen} from "../../analytics/ReactAnalytics";
import DexbasalAPI from "../../api/DexbasalApi";
import {updateSubjectWithSortedRecommendationsDescending} from "../../graphql/custom/mutations";

interface IProps {
  subject: Subject,
  onNavClick: NavClickHandler,
  onNetworkError: NetworkAlertHandler
  networkError: boolean,
  onSubjectUpdate: SubjectHandler,
  isSupport: boolean,
  debugApi: boolean,
  glucoseUnit: GlucoseUnit,
  api: DexbasalAPI,
  maxHeight: number,
  loadSubjects: () => void
}

const TitrationReview: React.FunctionComponent<IProps> = (props) => {
    const {api, onNetworkError: handleNetworkAlert, glucoseUnit, loadSubjects} = props;
    const [egvs, setEgvs] = useState<Array<Egv>>()
    const [egvError, setEgvError] = useState<boolean>(false)
    const [events, setEvents] = useState<Array<Event>>()
    const [eventError, setEventError] = useState<boolean>(false)
    const [recommendations, setRecommendations] = useState<Array<Recommendation>>()
    const [editWeight, setEditWeight] = useState<boolean>(false)
    const [editDisplayId, setEditDisplayId] = useState<boolean>(false)
    const [displayId, setDisplayId] = useState<string>(props.subject.displayId)
    const [validDisplayId, setValidDisplayId] = useState<boolean>(true)
    const [titrationWeight, setTitrationWeight] = useState<number>(props.subject.weight ? props.subject.weight: 0)
    const [displayWeight, setDisplayWeight] = useState<string>(props.subject.weight ? props.subject.weight.toString(): "0")
    const [validWeight, setValidWeight] = useState<boolean>(true)
    const showErrorAlert = props.networkError;
    const [subjectAlert, setSubjectAlert] = useState(false)
    const [displayStatus, setDisplayStatus] = useState<TitrationStatus | 'unknown'>(props.subject.titrationStatus || 'unknown')
    const [statusColor, setStatusColor] = useState<any>('warning')
    const [statusTitle, setStatusTitle] = useState<string>('The subject status is unknown.')
    const [disqualify, setDisqualify] = useState<boolean>(false)
    const [disqualifyEnabled, setDisqualifyEnabled] = useState<boolean>(true)
    const [apiRegistered, ] = useState<boolean>(props.subject.apiStatus === ApiStatus.CONFIRMED)
    const [uniqueDisplayId, setUniqueDisplayId] = useState<UNIQUE_ID>(UNIQUE_ID.UNKNOWN);
    const [displayIdHelperText, setDisplayIdHelperText] = useState<string>(
      'Minimum of 4 characters that do not personally identify the subject.'
    )
    const isSupport = props.isSupport;

    useEffect(() => {
        trackScreen({
            screen: SCREENS.REVIEW_TITRATION,
            subjectId: props.subject.id,
            subjectDisplayId: props.subject.displayId
        })
    // We want no empty deps here so that it doesn't duplicate telemetry if the props change
    // eslint-disable-next-line
    }, [])

    const fetchEgvs = useCallback(() => {
        if(apiRegistered) {
            api.getEgvs({
                id: props.subject.id,
                startDate: dateTimeFromUtcSecondsSinceEpoch(utcSecondsSinceEpoch() - (36 * 60 * 60)),
                endDate: dateTimeFromUtcSecondsSinceEpoch(utcSecondsSinceEpoch()),
                debugApi: props.debugApi
            }).then((response) => {
                const egvs = [] as Array<Egv>
                if (response.data?.listEgvs?.response?.records && response.data?.listEgvs?.response?.records.length > 0) {
                    response.data.listEgvs.response.records.forEach((egv) => {
                        if (egv !== null && egv.status === null) {
                            egvs.unshift(glucoseUnit === GlucoseUnit.MGDL ? egv : transformMgdlEgvRecordToMmol(egv))
                        }
                    })
                    egvs.sort((a, b) => dateTimeStringToSecondsSinceEpoch(a.systemTime) - dateTimeStringToSecondsSinceEpoch(b.systemTime)).slice(0, 5);
                }
                if(egvError) {
                    setEgvError(false)
                }
                setEgvs(egvs || [])
            }).catch((e) => {
                if(props.debugApi) {
                    console.error('Error during fetchEgvs from listEgvs.', e);
                }
                if(!egvError) {
                    setEgvError(true)
                }
                setEgvs([])
                trackError("fetchEgvs");
            })
        } else {
            if(egvError) {
                setEgvError(false)
            }
            setEgvs([])
        }
    }, [props.subject.id, apiRegistered, egvError, props.debugApi, glucoseUnit, api])

    const fetchEvents = useCallback(() => {
        if(apiRegistered) {
            api.getEvents({
                id: props.subject.id,
                startDate: dateTimeFromUtcSecondsSinceEpoch(threeDayWindowOrGreater(props.subject.enrollmentDate)),
                endDate: dateTimeFromUtcSecondsSinceEpoch(utcSecondsSinceEpoch()),
                debugApi: props.debugApi
            }).then((response) => {
                if(response.data?.listEvents?.response?.records && response.data?.listEvents?.response?.records.length > 0) {
                    const events = [] as Array<Event>;
                    response.data.listEvents.response.records.forEach((event) => {
                        if (event !== null && event.eventStatus !== "deleted" && event.eventType === "insulin") {
                            events.push(event);
                        }
                    })
                    events.sort((a, b) => dateTimeStringToSecondsSinceEpoch(a.systemTime) - dateTimeStringToSecondsSinceEpoch(b.systemTime)).slice(0, 5);
                    if(eventError) {
                        setEventError(false)
                    }
                    setEvents(events.slice(0, 5).reverse() || []);
                } else {
                    if(eventError) {
                        setEventError(false)
                    }
                    setEvents([])
                }
            }).catch((e) => {
                if(props.debugApi) {
                    console.error('Error during fetchEvents from getEvents.', e);
                }
                if(!eventError) {
                    setEventError(true)
                }
                setEvents([])
                trackError("fetchEgvs");
            })
        } else {
            if(eventError) {
                setEventError(false)
            }
            setEvents([])
        }
    }, [props.subject.enrollmentDate, props.subject.id, apiRegistered, eventError, props.debugApi, api])

    const updateSubjectDisplayId = async () => {
        let variables = {input: {id: props.subject.id, displayId}}

        setEditDisplayId(false)
        const query = api.invoke<UpdateSubjectMutation, UpdateSubjectMutationVariables>(updateSubjectWithSortedRecommendationsDescending, variables)
        return query.then((result) => {
          handleNetworkAlert(false)
          setSubjectAlert(true)
          if (result.data?.updateSubject) {
            props.onSubjectUpdate(result.data.updateSubject);
          }
          trackEvent('edit_display_id', {
              subjectId: props.subject.id,
              subjectDisplayId: props.subject.displayId,
              newDisplayId: displayId
          })
        }).catch((e) => {
          if (props.debugApi) {
            console.error('Error during handleEditDisplayId from updateSubject.', e);
          }
          handleNetworkAlert(true)
          trackError("updateSubjectDisplayId");
        })
    }

    const fetchRecommendations = useCallback(() => {
        if(apiRegistered) {
            const variables: RecommendationsBySubjectAndTimeCreatedQueryVariables = {
                subjectId: props.subject.id,
                timeCreated: { le: Math.floor(new Date().getTime() / 1000)},
                sortDirection: ModelSortDirection.DESC,
                limit: 6
            }

            const call = api.invoke<RecommendationsBySubjectAndTimeCreatedQuery, RecommendationsBySubjectAndTimeCreatedQueryVariables>(recommendationsBySubjectAndTimeCreated, variables)
            call.then((response) => {
                let items = response.data?.recommendationsBySubjectAndTimeCreated?.items !== null && response.data?.recommendationsBySubjectAndTimeCreated?.items !== undefined ? response.data?.recommendationsBySubjectAndTimeCreated.items as [Recommendation] : [];
                setRecommendations(items || [])
            }).catch((e) => {
                if(props.debugApi) {
                    console.error('Error during fetchRecommendations from recommendationsBySubjectAndTimeCreated.', e);
                }
                setRecommendations([])
                trackError("recommendationsBySubjectAndTimeCreated");
            })
        } else {
            setRecommendations([])
        }
    }, [props.subject.id, apiRegistered, props.debugApi, api])

    const handleEditDisplayIdOpen = () => {
        setDisplayId(props.subject.displayId)
        setUniqueDisplayId(UNIQUE_ID.UNKNOWN)
        setEditDisplayId(true)
    }

    const handleEditDisplayIdClose = () => {
        setEditDisplayId(false)
    }

    const handleEditDisplayIdAction = async () => {
      if (validDisplayId && uniqueDisplayId !== UNIQUE_ID.CHECKING) {
        setUniqueDisplayId(UNIQUE_ID.CHECKING)
        const isUnique = await checkUniqueDisplayId(displayId, handleNetworkAlert, props.debugApi);
        if (isUnique) {
          await updateSubjectDisplayId()
        } else {
          setDisplayIdHelperText('This display ID already exists!  Please modify the text.')
        }
        setUniqueDisplayId(isUnique ? UNIQUE_ID.UNIQUE : UNIQUE_ID.DUPLICATE);
      }
    }

    const handleEditWeightOpen = () => {
        setDisplayWeight(props.subject.weight.toString())
        setEditWeight(true)
    }

    const handleEditWeightClose = () => {
        setDisplayWeight(displayWeight)
        setEditWeight(false)
    }

    const handleEditWeightAction = (event:HTMLButtonElement) => {
      event.disabled = true
        // do nothing with invalid data
        if (!validWeight) {
            return false
        }
        let variables: UpdateSubjectMutationVariables = {input: { id: props.subject.id, weight: parseInt(displayWeight) }}
        setEditWeight(false)
        const query = api.invoke<UpdateSubjectMutation, UpdateSubjectMutationVariables>(updateSubjectWithSortedRecommendationsDescending, variables)
        query.then((result) => {
            setTitrationWeight(parseInt(displayWeight))
            handleNetworkAlert(false)
            setSubjectAlert(true)
            if(result.data?.updateSubject) {
                props.onSubjectUpdate(result.data?.updateSubject);
            }
            trackEvent("edit_weight", {
                subjectId: props.subject.id,
                subjectDisplayId: props.subject.displayId,
                oldWeight: props.subject.weight,
                newWeight: parseInt(displayWeight)
            })
        }).catch((e) => {
            if(props.debugApi) {
                console.error('error during handleEditWeight from updateSubject.', e);
            }
            handleNetworkAlert(true)
            trackError("updateSubject", {function: "handleEditWeightAction"});
        })
    }

    const handleDisqualifyOpen = () => {
        setDisqualify(true)
    }

    const handleDisqualifyClose = () => {
        setDisqualify(false)
    }

    const handleDisqualifyAction = () => {
        let variables = {input: {
                id: props.subject.id,
                titrationStatus: TitrationStatus.DISQUALIFIED
            }}
        const query = api.invoke<UpdateSubjectMutation, UpdateSubjectMutationVariables>(updateSubjectWithSortedRecommendationsDescending, variables)
        query.then((result) => {
            setDisqualify(false)
            setDisplayStatus(TitrationStatus.DISQUALIFIED)
            setSubjectAlert(true)
            handleNetworkAlert(false)
            if(result.data?.updateSubject) {
                props.onSubjectUpdate(result.data?.updateSubject);
            }
            trackEvent("disqualify", {
                subjectId: props.subject.id,
                subjectDisplayId: props.subject.displayId
            })
        }).catch((e) => {
            if(props.debugApi) {
                console.error('Error during updateSubject for disqualification.', e);
            }
            handleNetworkAlert(true)
            trackError("updateSubject", {function: "handleDisqualifyAction"});
        })
    }

    useLayoutEffect(() => {
        const intDisplayWeight = parseInt(displayWeight)
        setValidDisplayId((displayId.length >= 4))
        setValidWeight((intDisplayWeight >= 40 && intDisplayWeight <= 200) || intDisplayWeight === 0)
    }, [displayId, displayWeight])

    useLayoutEffect(() => {
        switch (displayStatus) {
            case TitrationStatus.TITRATING:
                setStatusTitle('The subject is titrating. Click to disqualify.')
                setStatusColor('success')
                setDisqualifyEnabled(true)
                break
            case TitrationStatus.COMPLETE:
                setStatusTitle('The subject has completed titration.')
                setStatusColor('info')
                setDisqualifyEnabled(false)
                break
            case TitrationStatus.DISQUALIFIED:
                setStatusTitle('The subject has been removed from the study.')
                setStatusColor('error')
                setDisqualifyEnabled(false)
                break
            default:
                setStatusTitle('The subject\'s status is unknown to the system.')
                setStatusColor('warning')
                setDisqualifyEnabled(false)
                break
        }
    }, [displayStatus])

    useEffect(() => {
        fetchEgvs();
        fetchEvents();
        fetchRecommendations();
    }, [fetchEgvs, fetchEvents, fetchRecommendations])
  //Get reviews and historical values

  return (
    <Container disableGutters={true} maxWidth={false}>
        {!apiRegistered &&
            <Alert severity={'error'} sx={{justifyContent: 'center'}}>
                    <AlertTitle>Dexcom Registration Required</AlertTitle>
                    <DexcomRegistration subject={props.subject} onNetworkAlert={handleNetworkAlert} onSubjectUpdate={props.onSubjectUpdate} debugApi={props.debugApi} api={api}/>
            </Alert>
        }
        <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
            <Toolbar>
                <Button
                    id={'backButton'}
                    variant={'outlined'}
                    color={'inherit'}
                    sx={{marginRight: 2}}
                    onClick={() => props.onNavClick(SCREENS.SUBJECT_OVERVIEW)}>
                    Back
                </Button>
                <Typography variant={'h6'} sx={{flexGrow: 1, position: "relative"}}>
                    {SCREENS.REVIEW_TITRATION} for
                    <Tooltip title={'Click to Edit'}>
                        <Button
                          id={'editDisplayId'}
                          onClick={handleEditDisplayIdOpen}
                          variant={'text'}>
                            {props.subject.displayId}
                        </Button>
                    </Tooltip>
                    <Chip color={'info'}
                          id={'editWeight'}
                          label={props.subject.weight + ' kg'}
                          onClick={handleEditWeightOpen}
                          onDelete={handleEditWeightOpen}
                          deleteIcon={<EditIcon/>}
                          sx={{position: "absolute"}}
                    />
                </Typography>
                <Dialog open={editDisplayId} onClose={handleEditDisplayIdClose}>
                    <DialogTitle>Subject Display Name</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Update the subjects's display name.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id={'editDisplayIdInput'}
                            label={'Display name'}
                            type={'text'}
                            margin={'dense'}
                            error={!validDisplayId || (uniqueDisplayId === UNIQUE_ID.DUPLICATE)}
                            helperText={displayIdHelperText}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                                setDisplayId(e.target.value)
                                setUniqueDisplayId(UNIQUE_ID.UNKNOWN)
                                setDisplayIdHelperText('Minimum of 4 characters that do not personally identify the subject.')
                            }}
                            value={displayId}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditDisplayIdClose}>Cancel</Button>
                        <Button id={'editDisplayIdUpdate'} variant={'contained'} disabled={!validDisplayId || (uniqueDisplayId === UNIQUE_ID.DUPLICATE || uniqueDisplayId === UNIQUE_ID.CHECKING)} onClick={handleEditDisplayIdAction}>Update</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={editWeight} onClose={handleEditWeightClose}>
                    <DialogTitle>Subject Weight</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Update the subject's weight.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id={'editWeightInput'}
                            label={'Weight'}
                            type={'number'}
                            margin={'dense'}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                            }}
                            error={!validWeight}
                            helperText={'Weight must be between 40 and 200'}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                                setDisplayWeight(e.target.value)
                            }}
                            value={displayWeight}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditWeightClose}>Cancel</Button>
                        <Button id={'editWeightUpdate'} variant={'contained'} disabled={!validWeight} onClick={(e) => handleEditWeightAction(e.currentTarget)}>Update</Button>
                    </DialogActions>
                </Dialog>
                <Tooltip title={statusTitle}>
                    <Chip
                        color={statusColor}
                        id={'disqualifySubject'}
                        sx={{marginRight: 2}}
                        label={displayStatus}
                        onClick={disqualifyEnabled ? handleDisqualifyOpen : undefined}
                        onDelete={disqualifyEnabled ? handleDisqualifyOpen : undefined}
                        deleteIcon={disqualifyEnabled ? <EditIcon/> : undefined} />
                </Tooltip>
                <Dialog fullWidth={true} maxWidth={'sm'} open={disqualify} onClose={handleDisqualifyClose}>
                   <DialogTitle>Disqualify Subject</DialogTitle>
                    <DialogContent>
                        <DialogContentText component={'div'}><Alert color={'warning'}>Warning: this action cannot be undone.</Alert></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button id={'disqualifyCancel'} onClick={handleDisqualifyClose}>Cancel</Button>
                        <Button id={'disqualifyConfirm'} variant={'contained'} color={'error'} onClick={handleDisqualifyAction}>Confirm</Button>
                    </DialogActions>
                </Dialog>
                <Export subject={props.subject} debugApi={props.debugApi} api={props.api}/>
            </Toolbar>
        </AppBar>

        <NetworkError show={showErrorAlert}/>

        <Container style={{maxHeight: props.maxHeight === -1 ? 'none' : props.maxHeight, overflow:'auto'}}>
            <Stack spacing={2} divider={<Divider/>}>
                <Stack
                    direction={'row'}
                    spacing={4}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}>
                    <Card sx={{height: '100%', width: '100%', minHeight: 392}}>
                        <CardContent>
                            {egvs && !egvError ? (
                                (egvs.length === 0) ?
                                    <Typography variant={'subtitle2'}><em>No CGM history available.</em></Typography>
                                :
                                    <Graph egvs={egvs} highThresh={convertEgvSafe(200, glucoseUnit)} lowThresh={convertEgvSafe(70, glucoseUnit)} glucoseUnits={glucoseUnit}/>
                            ): egvError ? <Typography variant={'subtitle2'}><em>Error retrieving CGM history.</em></Typography> : <Skeleton variant={'rectangular'} height={352} width={703}/>}
                        </CardContent>
                    </Card>
                    <RecommendationReview weight={titrationWeight} subject={props.subject} isSupport={isSupport} debugApi={props.debugApi} onSubjectUpdate={props.onSubjectUpdate} api={api} onRefreshRecommendation={fetchRecommendations} onRefreshSubjects={loadSubjects}/>
                </Stack>

                <Stack
                    direction={'row'}
                    spacing={4}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                    paddingBottom={1}>
                    <HistoricalView
                        title={'Confirmations'}
                        subject={props.subject}
                        // slice off the first because that is our "current" and then filter any that were not confirmed in the past
                        records={recommendations ? recommendations.slice(1).filter((r) => (r !== null && r.timeConfirmed)) : undefined}
                        rowTile={RecommendationRow}
                        retrieveError={false}
                    />

                    <HistoricalView
                        title={'Logged Injections'}
                        subject={props.subject}
                        records={events}
                        rowTile={InjectionRow}
                        retrieveError={eventError}
                    />
                </Stack>
            </Stack>
        </Container>
        <Snackbar
            open={subjectAlert}
            autoHideDuration={5000}
            onClose={() => setSubjectAlert(false)}
            message={"Subject " + displayId + " updated!"}
        />
    </Container>
  );
}

export default TitrationReview
