import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid,
  Grow,
  IconButton,
  Slide,
  Stack,
  Tooltip,
  Typography,
  Zoom
} from "@mui/material";
import {IRecommendationReview} from "../../utils/Interfaces";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RecommendationIndicator from "../RecommendationIndicator/RecommendationIndicator";
import {updateRecommendation} from "../../graphql/base/mutations";
import {
  AlgorithmVersion,
  Recommendation,
  ResultReason,
  TitrationStatus,
  TitratorResultsSource,
  UpdateRecommendationMutation,
  UpdateRecommendationMutationVariables
} from "../../@dexbasal";
import {timezoneOffsetInSeconds, utcSecondsSinceEpoch} from "../../utils/Utils";
import NetworkError from "../Alerts/NetworkError";
import {trackError, trackEvent} from "../../analytics/ReactAnalytics";
import {timestampToDate} from "../../utils/Locale";
import AlgorithmIcon from "../AlgorithmIcon";
import ExplanationIndicator from "../ExplanationIndicator/ExplanationIndicator";

const MAX_U_INCREASE = 50;
const MIN_U_TOTAL = 0;

enum RecommendationState {
  NONE,
  SHOW_REVIEW,
  HIDE_REVIEW,
  WARMUP,
  NEGATIVE_TERMINATED
}

const getRecommendationState = (recommendation: Recommendation | undefined): RecommendationState => {
  if(recommendation) {
    if(recommendation.algorithm.version === AlgorithmVersion.START_V_1) {
      return RecommendationState.SHOW_REVIEW
    } else {  //New Algorithms (START V2 and OPTIMIZE V1)
      const terminated = recommendation.reasons ? recommendation.reasons.filter(r => r === ResultReason.TERMINATED).length > 0 : false
      const nullDose = recommendation.baseDose === null
      if(!terminated && nullDose && recommendation.resultSource !== TitratorResultsSource.SYSTEM_ERROR) {
        return RecommendationState.WARMUP
      } else if(terminated && nullDose && recommendation.resultSource !== TitratorResultsSource.SYSTEM_ERROR) {
        return RecommendationState.NEGATIVE_TERMINATED
      } else if(!nullDose) {
        return RecommendationState.SHOW_REVIEW
      } else {
        return RecommendationState.HIDE_REVIEW
      }
    }
  } else {
    return RecommendationState.NONE
  }
}

const RecommendationReview: React.FunctionComponent<IRecommendationReview> = (props) => {
  const {api, debugApi, subject, onRefreshRecommendation, onRefreshSubjects} = props;
  const recommendation = (props.subject.recommendations && props.subject.recommendations.items.length > 0 && props.subject.recommendations.items[0]) ? props.subject.recommendations.items[0] : undefined
  const needsReview = recommendation && !recommendation.timeConfirmed;

  const base = (recommendation !== undefined) ? Math.floor(recommendation.baseDose as number) : 0
  const headingSize = 2
  const unitColumnSize = 2
  const blankColumnSize = 1
  const containerRef = useRef(null)

  let timer: NodeJS.Timeout

  const [adjustment, setAdjustment] = useState(recommendation ? Math.floor(recommendation.adjustment as number) : 0)
  const [total, setTotal] = useState(adjustment + base)
  const [minusDisabled, setMinusDisabled] = useState(false)
  const [plusDisabled, setPlusDisabled] = useState(false)
  const [adjusted, setAdjusted] = useState(false)
  const [reviewable, setReviewable] = useState(needsReview)
  const [rate, setRate] = useState(0.0)
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false)
  const [overBasalized, setOverBasalized] = useState<boolean>(rate > 0.5)
  const isSupport = props.isSupport;
  const [sent, setSent] = useState<boolean>(false)
  const [showSendError, setShowSendError] = useState<boolean>(false)
  const [titrating, setTitrating] = useState<boolean>(false)
  const [showTitratingError, setShowTitratingError] = useState<boolean>(false)

  const subjectAlgorithm = (props.subject.titratorAlgorithm && props.subject.titratorAlgorithm.items && props.subject.titratorAlgorithm.items[0]) ? props.subject.titratorAlgorithm.items[0] : null
  const state = getRecommendationState(recommendation)

  useEffect(
    () => {
      setTotal(adjustment + base)

      if(adjustment !== 0) {
        setAdjusted(true)
      } else {
        setAdjusted(false)
      }

      if(!reviewable) {
        setMinusDisabled(true)
        setPlusDisabled(true)
      } else {
        setMinusDisabled(false)
        setPlusDisabled(false)
      }

      if(reviewable && (adjustment + base) >= 0) {
        const max = base + MAX_U_INCREASE

        let newTotal = adjustment + base
        let minusDisabled = false;

        if(newTotal <= MIN_U_TOTAL) {
          newTotal = MIN_U_TOTAL
          minusDisabled = true;
        } else if (newTotal >= max) {
          newTotal = max
          setPlusDisabled(true)
        }

        setTotal(newTotal)
        setMinusDisabled(minusDisabled)
      }

      if(reviewable && (adjustment + base) === 0) {
        setMinusDisabled(true)
      }
    }, [adjustment, reviewable, base, total]
  )

  useEffect(() => {
    if(rate > 0.5) {
      if(!overBasalized) {
        setOverBasalized(true);
      }
    } else {
      if(overBasalized) {
        setOverBasalized(false);
      }
    }
  }, [rate, overBasalized])

  useEffect(() => {
    if(props.weight) {
      const roundedRate = total / props.weight
      setRate(Math.round((roundedRate + Number.EPSILON) * 100) / 100)
    }
  }, [total, props.weight])

  const renderUnitsGrid = (total: number) => {
    return (
      <Grid item xs={unitColumnSize} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
        <Typography variant={'h6'}>
          {Math.abs(total) === 1 ? "Unit" : "Units"}
        </Typography>
      </Grid>
    )
  }
  const blankGrid = () => {
    return(
      <Grid item xs={blankColumnSize}/>
    )
  }

  const longPress = () => {
    timer = setTimeout(() => {
      setReviewable(true)
      trackEvent("review_reset_confirm", {
        subjectId: props.subject.id,
        subjectDisplayId: props.subject.displayId
      })
    }, 2000)
  }

  const cancelLongPress = () => {
    clearTimeout(timer)
  }

  const updateRecords = () => {
    if (!recommendation) {
      return false
    }
    setReviewable(false)
    const timeConfirmed = new Date();
    api.invoke<UpdateRecommendationMutation, UpdateRecommendationMutationVariables>(
      updateRecommendation,
      {
        input: {
          id: recommendation.id,
          adjustment,
          timeConfirmed: utcSecondsSinceEpoch(timeConfirmed),
          timeConfirmedOffset: timezoneOffsetInSeconds(timeConfirmed)
        }
      }).then((result) => {
      if(result.data?.updateRecommendation) {
        props.subject.recommendations!.items = [result.data.updateRecommendation]
        props.onSubjectUpdate(props.subject)
      }
      setShowErrorAlert(false)
      trackEvent("review_confirmed", {
        subjectId: props.subject.id,
        subjectDisplayId: props.subject.displayId,
        base: base,
        adjustment: adjustment,
        total: total,
      })
    }).catch((e) => {
      if(props.debugApi) {
        console.error('Error during updateRecords from updateRecommendation.', e);
      }
      setShowErrorAlert(true)
      setReviewable(true)
      trackError("updateRecommendation", {function: "updateRecords"});
    })
  }

  const renderIndicator = (algorithm: AlgorithmVersion, recommendation: Recommendation) => {
    switch (algorithm) {
      case AlgorithmVersion.START_V_1:
        if(recommendation.resultSource && Array.isArray(recommendation.reasons) &&
          (recommendation?.reasons?.length > 0 || recommendation.resultSource === TitratorResultsSource.SYSTEM_ERROR.toString())
        ) {
          return (
            <RecommendationIndicator resultReasons={recommendation.reasons} resultSource={recommendation.resultSource}/>
          )
        } else {
          return null
        }
      case AlgorithmVersion.START_V_2:
      case AlgorithmVersion.OPTIMIZE_V_1:
        if(recommendation.resultSource === TitratorResultsSource.SYSTEM_ERROR.toString() || recommendation.explanation) {
          return (
            <ExplanationIndicator recommendation={recommendation}/>
          )
        } else {
          return null
        }
    }
  }

  const sendDose = useCallback(() => {
    setSent(true);
    api.postDose({
      id: props.subject.id,
      totalDose: Number.parseFloat(total.toFixed(1)),
      timeConfirmed: recommendation?.updatedAt || new Date().toISOString(),
      debugApi: props.debugApi
    }).then(() => {
      setShowSendError(false);
      setSent(false);
    }).catch((e) => {
      if (props.debugApi) {
        console.error('Error posting dose.', e);
      }
      setShowSendError(true);
      setSent(false);
      trackError("sendDose", {function: "sendTitrate"});
    })
  }, [api, props.debugApi, props.subject.id, recommendation?.updatedAt, total]);

  const renderRecommendation = () => {
    const titrateButton = () => props.debugApi ?
      <Tooltip title={'Don\'t care how. I want it now.'}>
        <Button disabled={titrating} variant={"contained"} onClick={sendTitrate}>
          Force Titrate
        </Button>
      </Tooltip>
      : undefined
    if(recommendation && state !== RecommendationState.NONE) {
      if(state === RecommendationState.WARMUP && recommendation.explanation) {
        return(
          <CardContent>
            <Typography sx={{marginBottom:3}} variant={'subtitle2'}><em>{recommendation.explanation}</em></Typography>
          </CardContent>
        )
      } else {
        return (
          <CardContent>
            <Typography padding={0.2} id={'generatedTime'}
                        variant={'body1'}>{recommendation.timeConfirmed ? 'Confirmed ' : 'Generated '}
              at {timestampToDate(recommendation.timeConfirmed ? recommendation.timeConfirmed : recommendation.timeCreated)}</Typography>

            {renderIndicator(recommendation.algorithm.version, recommendation)}
            {null}

            {state === RecommendationState.NEGATIVE_TERMINATED && recommendation.explanation &&
                <Typography sx={{marginBottom:3}} variant={'subtitle2'}><em>{recommendation.explanation}</em></Typography>
            }

            {state === RecommendationState.SHOW_REVIEW &&
              <>
              <Grid container columns={8} columnSpacing={0} justifyContent={'center'}>

                <Grid item xs={headingSize} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                  <Typography variant={'h6'}>Algorithm Dose</Typography>
                </Grid>
                {blankGrid()}
                <Grid item xs={1} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                  <Typography variant={'h5'}>
                    <strong>{base}</strong>
                  </Typography>
                </Grid>
                {blankGrid()}
                {renderUnitsGrid(base)}

                <Grid item xs={headingSize} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                  <Typography variant={'h6'}>Adjustment</Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton disabled={minusDisabled || isSupport} onClick={() => {
                    setAdjustment(adjustment - 1)
                    trackEvent("adjustment_minus", {
                      subjectId: props.subject.id,
                      subjectDisplayId: props.subject.displayId,
                    })
                  }}>
                    <RemoveCircleOutlineOutlinedIcon fontSize={'large'}/>
                  </IconButton>
                </Grid>
                <Grid item xs={1} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                  <Typography variant={'h5'}>
                    <strong>{adjustment}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton disabled={plusDisabled || isSupport} onClick={() => {
                    setAdjustment(adjustment + 1)
                    trackEvent("adjustment_plus", {
                      subjectId: props.subject.id,
                      subjectDisplayId: props.subject.displayId,
                    })
                  }}>
                    <AddCircleOutlineOutlinedIcon fontSize={'large'}/>
                  </IconButton>
                </Grid>
                {renderUnitsGrid(adjustment)}

                <Grid item xs={headingSize} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                </Grid>
                {blankGrid()}
                <Grid item xs={1}>
                  <Divider/>
                </Grid>
                {blankGrid()}
                <Grid item xs={unitColumnSize}/>

                <Grid item xs={headingSize} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                  <Typography variant={'h6'}>Final Dose</Typography>
                </Grid>
                <Grid item xs={1} style={{display: "flex", justifyContent: "center", alignSelf: "center"}}>
                  <Grow in={adjusted} timeout={{'enter': 500, 'exit': 200}}>
                    <Tooltip title={'This dose was adjusted by clinician!'}>
                      <WarningAmberIcon color={'info'} fontSize={'small'}/>
                    </Tooltip>
                  </Grow>
                </Grid>
                <Grid item xs={1} style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                  <Typography color={'primary'} variant={'h3'}>
                    <strong>{total}</strong>
                  </Typography>
                </Grid>
                {blankGrid()}
                {renderUnitsGrid(total)}

              </Grid>

              <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1} padding={0}>
                <Grow in={overBasalized} timeout={{'enter': 500, 'exit': 200}}>
                  <Tooltip id={'overBasalizedTip'} title={'Dose exceeds 0.5 U per kg body weight'}>
                    <WarningAmberIcon id={'overBasalizedWarning'} color={'warning'} fontSize={'small'}/>
                  </Tooltip>
                </Grow>
                <Typography id={'rate'} color={'primary'} variant={'caption'}><em>{rate} U per kg body
                  weight</em></Typography>
              </Stack>
              </>
            }

            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={1} padding={1.5}>
              {reviewable &&
                  <Zoom in={reviewable} timeout={500}>
                      <Button disabled={isSupport} variant="contained" onClick={updateRecords}>
                        {props.subject.titrationStatus === TitrationStatus.COMPLETE ? "Confirm Final Titrated Dose" : "Confirm Review"}
                      </Button>
                  </Zoom>
              }

              {!reviewable &&
                  <>
                      <Slide direction="right" in={!reviewable} container={containerRef.current} timeout={500}>
                          <CheckCircleOutlineIcon fontSize={'large'}/>
                      </Slide>
                      <Fade in={!reviewable} timeout={750}>
                          <Button
                              variant="contained"
                              disabled={isSupport}
                              onMouseLeave={() => cancelLongPress()}
                              onMouseUp={() => cancelLongPress()}
                              onMouseDown={() => longPress()}>
                              Reviewed
                          </Button>
                      </Fade>
                  </>
              }
            </Stack>

            {!reviewable &&
                <Fade in={!reviewable} timeout={1500}>
                    <Typography variant={'caption'}>Press and Hold to Edit</Typography>
                </Fade>
            }
            {debugApi &&
              <Stack spacing={1}>
                <Typography variant={'h5'}>
                  <strong>DEBUG OPTIONS</strong>
                </Typography>
                <Button disabled={reviewable || sent} variant={"contained"} onClick={sendDose}>
                  Send The Dose!
                </Button>

                {titrateButton()}
              </Stack>
            }
            <NetworkError show={showErrorAlert || showSendError || showTitratingError}/>
          </CardContent>
        )
      }
    } else {
      return(
        <CardContent>
          <Typography sx={{marginBottom:3}} variant={'subtitle2'}><em>Pending first attempt.</em></Typography>
          {debugApi &&
            <Stack spacing={1}>
              <Typography variant={'h5'}>
                <strong>DEBUG OPTIONS</strong>
              </Typography>
              {titrateButton()}
            </Stack>}
        </CardContent>
      )
    }
  }

  const sendTitrate = useCallback(() => {
    setShowTitratingError(false);
    setTitrating(true)
    api.titrate({
      args: {
        task_input: {
          subject_id: subject.id
        },
        force: true
      }
    }).then(() => {
      onRefreshSubjects()
      onRefreshRecommendation()
      setTitrating(false);
      setShowTitratingError(false);
    }).catch((e) => {
      if (debugApi) {
        console.error('Error toggling titrate.', e);
      }
      setShowTitratingError(true);
      setTitrating(false);
      trackError("titrate", {function: "sendTitrate"});
    })
  }, [api, debugApi, subject.id, onRefreshRecommendation]);

  return (
    <Card id={'TitrationReview'} ref={containerRef} sx={{minHeight: 392, minWidth: 300}}>
      <CardHeader
        title={
          <Stack direction={'row'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Box sx={{position:'absolute', transform:'translate(-125px)'}}>
              <AlgorithmIcon titratorAlgorithm={recommendation ? recommendation.algorithm : subjectAlgorithm}/>
            </Box>
            <Typography variant={'h5'}>Titration Review</Typography>
          </Stack>
        }
        titleTypographyProps={{}}/>
      {renderRecommendation()}
    </Card>
  );
}

export default RecommendationReview