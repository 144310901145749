import React from "react";
import {Alert} from "@mui/material";

const Browser: React.FunctionComponent = () => {
   return (
       <>
           {(navigator.userAgent.indexOf('Chrome') > -1) || (
               <Alert severity={'warning'}>
                   DexBasal-Study is unverified for this browser, please use the latest Google Chrome.
               </Alert>
           )}
       </>
   )
}

export default Browser
