export enum ReadableResultReason {
  TERMINATED = "Complete",
  RECENT_NON_SEVERE_HYPO_PREVENTED_DOSE_INCREASE = "Recent EGVs Below 70 mg/dL Prevented Dose Increase",
  LOW_BG_GUARDRAIL_ACTIVATED = "Low BG Guardrail Activated",
  SEVERE_HYPO_FORCED_DOSE_DECREASE = "EGVs Below 54 mg/dL Forced Dose Decrease",
  TARGET_DOSE_OVERBASALIZATION_WARNING = "Target Dose Overbasalization Warning",
  CURRENT_DOSE_OVERBASALIZATION_WARNING = "Algorithm Dose Overbasalization Warning",
  RECENT_SEVERE_HYPO_PREVENTED_DOSE_INCREASE = "Recent EGVs Below 54 mg/dL Prevented Dose Increase",
  SYSTEM_ERROR = "System Error: No Change",
  UNKNOWN = "Unknown Status"
}