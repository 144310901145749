import {
    Alert,
    AppBar,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    ListItemText,
    Skeleton,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import SCREENS from "../utils/Screens";
import {Add, Place} from "@mui/icons-material";
import {Users, User} from "../App";
import {
    addUserToGroup,
    deleteUser,
    disableUser,
    getUser,
    removeUserFromGroup,
    sendWarningEmail,
    sendWelcomeEmail
} from "../utils/Staff";
import {SiteLookup, UserSiteLookup} from "../utils/Interfaces";

interface IProps {
    currentUserId: string
    loadSites: (onError: any) => void
    loadUsers: (onError: any) => void
    loadUserSites: (onError: any) => void
    maxHeight: number
    onNavClick: (screen: SCREENS) => void
    onUserRemoved: (username: string) => void
    onUserUpdated: (user: User, groups: string[]) => void
    siteLookup: SiteLookup
    users: Users[] | undefined
    userSiteLookup: UserSiteLookup
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ListUsers: React.FunctionComponent<IProps> = (props) => {
    const {
        currentUserId,
        loadSites,
        loadUsers,
        loadUserSites,
        onNavClick,
        onUserRemoved,
        onUserUpdated,
        siteLookup,
        users,
        userSiteLookup,
    } = props;
    const [loadError, setLoadError] = useState<string>('');

    const handleError = useCallback((e: any) => {
        if('response' in e && 'data' in e.response && 'message' in e.response.data) {
            setLoadError(e.response.data.message)
        } else {
            setLoadError(String(e))
        }
    }, [setLoadError])

    const onConfirmDeleteUser = useCallback(async (username: string) => {
        try {
            await disableUser(username);
            await deleteUser(username);
            onUserRemoved(username);
        } catch (e) {
            handleError(e)
        }
    },[onUserRemoved, handleError])

    useEffect(() => {
        if(!users) {
            loadUsers(handleError)
        }
    }, [handleError, loadUsers, users])

    useEffect(() => {
        if (Object.keys(userSiteLookup).length === 0) {
            loadUserSites(handleError)
        }
    }, [handleError, loadUserSites, userSiteLookup])

    useEffect(() => {
        if (Object.keys(siteLookup).length === 0) {
            loadSites(handleError)
        }
    }, [handleError, loadSites, siteLookup])

    const [confirmDeleteEnabled, setConfirmDeleteEnabled] = useState<undefined|Users>();
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    const handleConfirmOpen = useCallback((target: Users) => {
        if(!confirmDeleteEnabled) setConfirmDeleteEnabled(target);
    }, [confirmDeleteEnabled, setConfirmDeleteEnabled])

    const handleConfirmClose = useCallback(() => {
        if(confirmDeleteEnabled) setConfirmDeleteEnabled(undefined)
    }, [confirmDeleteEnabled, setConfirmDeleteEnabled])

    const [confirmAuthorizeEnabled, setConfirmAuthorizeEnabled] = useState<undefined|Users>();
    const [confirmAddInProgress, setConfirmAddInProgress] = useState(false);

    const handleConfirmAuthorizeOpen = useCallback((target: Users) => {
        if(!confirmAuthorizeEnabled) setConfirmAuthorizeEnabled(target);
    }, [confirmAuthorizeEnabled, setConfirmAuthorizeEnabled])

    const handleConfirmAuthorizeClose = useCallback(() => {
        if(confirmAuthorizeEnabled) setConfirmAuthorizeEnabled(undefined)
    }, [confirmAuthorizeEnabled, setConfirmAuthorizeEnabled])

    const onConfirmAuthorizeUser = useCallback(async (staff: Users) => {
        const registerGroup = 'DexBasalStaff'
        try {
            await removeUserFromGroup(staff.username, 'Unauthorized');
            await addUserToGroup(staff.username, registerGroup);
            await sendWelcomeEmail({email : staff.email, group : registerGroup, resetNeeded : false});
            await sendWarningEmail(staff.email, registerGroup);
            const updatedUser = await getUser(staff.username);
            onUserUpdated(updatedUser, [registerGroup]);
        } catch (e) {
            handleError(e)
        }
    },[onUserUpdated, handleError])

    return (
        <Container disableGutters={true} maxWidth={false}>
            <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
                <Toolbar>
                    <Typography variant={'h6'} sx={{flexGrow: 1}}>
                        {SCREENS.LIST_USERS}
                    </Typography>
                    <Button variant={'outlined'}
                            sx={{position: "absolute", marginRight: 2}}
                            color={'inherit'}
                            onClick={() => onNavClick(SCREENS.ADD_USER)}
                            endIcon={<Add/>}>
                        Add Staff
                    </Button>
                    <Button variant={'contained'}
                            color={'info'}
                            onClick={() => onNavClick(SCREENS.MANAGE_SITES)}
                            endIcon={<Place/>}>
                        Manage Sites
                    </Button>
                </Toolbar>
            </AppBar>

            <Container maxWidth={'md'} style={{maxHeight: props.maxHeight === -1 ? 'none' : props.maxHeight, overflow:'auto'}}>
                {loadError &&
                  <Typography variant="subtitle1" component="div">
                    Error: {loadError}
                  </Typography>
                }
                {!users ? (
                    [...Array(20)].map((_, idx) =>
                        <Stack key={idx} direction={'row'} sx={{marginTop: 2}}>
                            <Skeleton width={32} height={32} variant={'circular'} sx={{marginLeft: '16px', marginRight: '24px'}}/>
                            <Skeleton>
                                <Typography>fcfd4020-911a-4edb-a1b7-385d467a60fd</Typography>
                            </Skeleton>
                        </Stack>
                    )
                ):(
                    users.length > 0 ? (
                            <TableContainer>
                                <Table sx={{maxWidth: 'md', maxHeight: props.maxHeight === -1 ? 'none' : props.maxHeight, overflow:'auto'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email Address</TableCell>
                                            <TableCell>Site</TableCell>
                                            <TableCell>Group Affiliations</TableCell>
                                            <TableCell>Delete User</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((member) => {
                                            return (
                                                <StyledTableRow key={member.username}>
                                                    <TableCell>
                                                        <ListItemText primary={member.email}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <ListItemText primary={siteLookup[userSiteLookup[member.username]]}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        {member.groups.map(g => (
                                                            <Grid item key={`${member.username}${g}`}>
                                                                <ListItemText primary={g}/>
                                                            </Grid>
                                                        ))}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(member.groups.length === 1 && member.groups[0] === "Unauthorized") && (
                                                            <Button variant={'contained'} key={member.username+'AddToStaff'} onClick={confirmAuthorizeEnabled ?  undefined : () => handleConfirmAuthorizeOpen(member)}>
                                                                Add to DexBasalStaff
                                                            </Button>
                                                        )}
                                                        <Button variant={'contained'} key={member.username+'Delete'} onClick={confirmDeleteEnabled ?  undefined : () => handleConfirmOpen(member)}>
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    ):(
                        <Alert color={'warning'} sx={{marginTop: 5}}>{loadError ? loadError : 'No Staff Found.'}</Alert>
                    )
                )}
                {confirmDeleteEnabled && <Dialog fullWidth={true} maxWidth={'sm'} open={!!confirmDeleteEnabled} onClose={handleConfirmClose}>
                  <DialogTitle>Delete {confirmDeleteEnabled.email}</DialogTitle>
                  <DialogContent>
                    <DialogContentText component={'div'}>
                      <Alert color={'warning'}>
                          {confirmDeleteEnabled.username === currentUserId ? 'Warning: you cannot delete your own account' : 'Warning: this action cannot be undone.'}
                      </Alert>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button id={'deleteCancel'} onClick={handleConfirmClose}>Cancel</Button>
                    <Button disabled={deleteInProgress || confirmDeleteEnabled.username === currentUserId} id={'deleteCancel'} variant={'contained'} color={'error'}
                            onClick={async () => {
                                setDeleteInProgress(true)
                                try {
                                    await onConfirmDeleteUser(confirmDeleteEnabled.username)
                                } catch (e) {
                                    handleError(e)
                                }
                                handleConfirmClose()
                                setDeleteInProgress(false)
                            }}>Confirm</Button>
                  </DialogActions>
                </Dialog>}
                {confirmAuthorizeEnabled && <Dialog fullWidth={true} maxWidth={'sm'} open={!!confirmAuthorizeEnabled} onClose={handleConfirmAuthorizeClose}>
                  <DialogTitle>Authorize {confirmAuthorizeEnabled.email}</DialogTitle>
                  <DialogContent>
                    <DialogContentText component={'div'}>
                      <Alert color={'warning'}>Warning: this will grant operator privileges.</Alert>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button id={'addConfirmCancel'} onClick={handleConfirmAuthorizeClose}>Cancel</Button>
                    <Button disabled={confirmAddInProgress || confirmAuthorizeEnabled.username === currentUserId} id={'addConfirmCancel'} variant={'contained'} color={'error'}
                            onClick={async () => {
                                setConfirmAddInProgress(true)
                                try {
                                    await onConfirmAuthorizeUser(confirmAuthorizeEnabled)
                                } catch (e) {
                                    handleError(e)
                                }
                                handleConfirmAuthorizeClose()
                                setConfirmAddInProgress(false)
                            }}>Confirm</Button>
                  </DialogActions>
                </Dialog>}
            </Container>
        </Container>
    )
}

export default ListUsers
