export const timestampToDate = (timestamp: number | null | undefined) => {
  if (timestamp === null || timestamp === undefined) {
    return "Unknown"
  }
  return LocaleFormatter.format(timestamp * 1000).toString()
}
export const formatDateString = (dateString: string | null | undefined) => {
  if (dateString === null || dateString === undefined) {
    return "Unknown"
  }

  return LocaleFormatter.format(new Date(dateString))
}
export const LocaleFormatter = new Intl.DateTimeFormat(navigator.language, {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short'
});
