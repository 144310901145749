import React from "react";
import {Chip, Stack, Tooltip, Zoom} from "@mui/material";
import '../../index.css';
import {Recommendation, ResultReason, TitratorResultsSource} from "../../@dexbasal";
import DoneOutline from '@mui/icons-material/DoneOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {dexcomTheme} from "../../theme";
import {ReadableResultReason} from "../../utils/ReadableResultReason";

interface IProps {
  recommendation: Recommendation
}

const renderChip = (text: string, label: string | JSX.Element, icon: JSX.Element, bgColor: string) => {
  return (
    <Tooltip title={
      text !== "" ?   // If we're negative terminated, the message will be on the screen not the tooltip
        <span style={{ whiteSpace: 'pre-line', textAlign: 'center'}}>
          {text}
        </span> : ""
    } arrow TransitionComponent={Zoom}>
      <Chip
        id={'recommendationIndicator'}
        icon={icon}
        style={{color:'white', margin:'10px', backgroundColor:(bgColor), height: '100%', padding: '2px', whiteSpace: 'nowrap', overflow: 'hidden'}}
        label={label}
      />
    </Tooltip>
  )
}

const ExplanationIndicator: React.FunctionComponent<IProps> = (props) => {
  const resultsReasons = props.recommendation.reasons
  const terminated = (resultsReasons ? resultsReasons.filter(r => r === ResultReason.TERMINATED).length > 0 : false)
  const negativeTerminated = terminated && props.recommendation.baseDose === null
  const systemError = props.recommendation.resultSource === TitratorResultsSource.SYSTEM_ERROR

  let toolTip: string = ""
  if(systemError || negativeTerminated) {
    toolTip = ""
  } else if(props.recommendation.explanation) {
    toolTip = props.recommendation.explanation
  }

  let chipLabel: JSX.Element | string = "Explanation"
  let chipBgColor = 'lightblue'
  let chipIcon = <ControlPointIcon style={{color: 'white', paddingLeft:'3px'}} fontSize={'small'}/>
  if(systemError) {
    chipLabel = "System Error: No Change"
    if(props.recommendation.baseDose === null) {
      chipLabel =
      <Stack>
        <div>System Error: No Recommendation</div>
        <div>Contact Dexcom</div>
      </Stack>
    }
    chipBgColor = dexcomTheme.palette.warning.main
    chipIcon = <PriorityHighIcon style={{color: 'white', paddingLeft:'3px'}} fontSize={'small'}/>
  } else if(terminated) {
    chipLabel = "Complete"
    chipBgColor = dexcomTheme.palette.info.main
    chipIcon = <DoneOutline style={{color: 'white', paddingLeft:'3px'}} fontSize={'small'}/>
  }

  return(
    <>{renderChip(toolTip, chipLabel, chipIcon, chipBgColor)}</>
  )
}

export default ExplanationIndicator
