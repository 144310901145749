enum SCREENS {
    SUBJECT_OVERVIEW = "Subject Overview",
    ADD_SUBJECT = "Add Subject",
    REVIEW_TITRATION = "Titration Review",
    ADD_USER = "Add Staff",
    LIST_USERS = "List Staff",
    MANAGE_SITES = "Manage Sites",
    ADD_SITE = "Add Site",
    UPDATE_SITE = "Edit Site",
}

export default SCREENS
