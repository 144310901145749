import {Alert, AlertColor, Box, Button, CircularProgress, Popover} from "@mui/material";
import React, {useState} from "react";
import Download from './Download'
import {FileDownload} from "@mui/icons-material";
import {ApiStatus, Subject} from "../../@dexbasal";
import DexbasalAPI from "../../api/DexbasalApi";

const Export: React.FunctionComponent<{subject: Subject, debugApi: boolean, api: DexbasalAPI}> = ({subject, debugApi, api}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [enabled, setEnabled] = useState<boolean>(subject.apiStatus === ApiStatus.CONFIRMED)
    const [openAlert, setOpenAlert] = useState<boolean>(false)
    const [alertColor, setAlertColor] = useState<AlertColor>('success')
    const [alertMessage, setAlertMessage] = useState<string>('Subject downloaded!')
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const onSuccess = () => {
        setLoading(false)
        setAlertColor('success')
        setAlertMessage('Subject data downloaded!')
        setOpenAlert(true)
    }

    const onFail = () => {
        setLoading(false)
        setEnabled(false)
        setAlertColor('error')
        setAlertMessage('No records exist!')
        setOpenAlert(true)
    }

    const onAlertClose = () => {
        setOpenAlert(false)
        setAnchorEl(null)
    }

    return (
        <Box>
            <Button
                color={'info'}
                variant={'outlined'}
                disabled={!enabled}
                endIcon={loading ? <CircularProgress/> : <FileDownload/>}
                onClick={(e) => {
                    if(!loading) {
                        setAnchorEl(e.currentTarget)
                        setLoading(true)
                    }
                }}>
                {loading ? 'Loading' : 'Export'}
                {loading && <Download subject={subject} onSuccess={onSuccess} onFail={onFail} debugApi={debugApi} api={api}/>}
            </Button>
            <Popover
                id={'exportAlert'}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                anchorEl={anchorEl}
                open={openAlert}
                onClose={onAlertClose} >
                <Alert severity={alertColor} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Popover>
        </Box>
    )
}

export default Export
