import React from "react";
import {Alert} from "@mui/material";
import useWindowDimensions from "../../utils/WindowDimensions";

const Resize: React.FunctionComponent = () => {
    const window = useWindowDimensions()

   return (
       <>
       {(window.width < 1024) && (
           <Alert severity={'error'}>
               DexBasal-Study is designed for a browser width of at least 1024 pixels.
           </Alert>
       )}
       {(window.height < 768) && (
         <Alert severity={'error'}>
             DexBasal-Study is designed for a browser height of at least 768 pixels.
         </Alert>
       )}
       </>
   )
}

export default Resize
