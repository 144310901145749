import React from 'react'
import {
  Dot,
  Rectangle,
  ReferenceArea,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  Label
} from 'recharts'
import TIME_RANGE from './TimeRange'
import {convertEgvSafe} from "../../../utils/Utils";
import {useTheme} from "@mui/material";
import {GlucoseUnit} from "../../../@dexbasal";
import {formatDateString} from "../../../utils/Locale";

const formatDateTime = (dateTicks: number): string => {
  let hours = new Date(dateTicks).getHours()
  return hours.toString()
}

const getScale = (timeRange: TIME_RANGE): number => {
  switch (timeRange) {
    case TIME_RANGE.RANGE_3_HOURS:
      return 1
    case TIME_RANGE.RANGE_6_HOURS:
      return 2
    case TIME_RANGE.RANGE_12_HOURS:
      return 4
    case TIME_RANGE.RANGE_24_HOURS:
      return 6
    case TIME_RANGE.RANGE_27_HOURS:
      return 8
    case TIME_RANGE.RANGE_72_HOURS:
      return 10
    default:
      return 0
  }
}

const getXTicks = (timeRange: TIME_RANGE, now: number) => {
  const hourMilli = 60 * 60 * 1000
  const nowJustHour = new Date(now).setMinutes(0, 0, 0)

  let ticks = [now]

  switch (timeRange) {
    case TIME_RANGE.RANGE_3_HOURS: {
      ticks = [nowJustHour - hourMilli * 2, nowJustHour - hourMilli, ...ticks]
      break
    }
    case TIME_RANGE.RANGE_6_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 4,
        nowJustHour - hourMilli * 2,
        ...ticks,
      ]
      break
    }
    case TIME_RANGE.RANGE_12_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 9,
        nowJustHour - hourMilli * 6,
        nowJustHour - hourMilli * 3,
        ...ticks,
      ]
      break
    }
    case TIME_RANGE.RANGE_24_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 18,
        nowJustHour - hourMilli * 12,
        nowJustHour - hourMilli * 6,
        ...ticks,
      ]
      break
    }
    case TIME_RANGE.RANGE_27_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 24,
        nowJustHour - hourMilli * 16,
        nowJustHour - hourMilli * 8,
        ...ticks,
      ]
      break
    }
    case TIME_RANGE.RANGE_72_HOURS: {
      ticks = [
        nowJustHour - hourMilli * 54,
        nowJustHour - hourMilli * 36,
        nowJustHour - hourMilli * 18,
        ...ticks,
      ]
    }
  }

  return ticks
}

// eslint-disable-next-line
const EgvTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: 'white',
          padding: 5,
          border: '1px solid grey',
        }}
      >
        <div>{payload[1].value} {payload[1].payload.unit === GlucoseUnit.MGDL ? "mg/dL" : "mmol/L"}</div>
        <div>{formatDateString(payload[0].value)}</div>
      </div>
    )
  }

  return <div/>
}

interface Data {
  value: number
  systemTime: number
  unit: GlucoseUnit
}

interface ChartProps {
  timeRange: TIME_RANGE
  data: Data[]
  highThresh: number
  lowThresh: number
  glucoseUnits: GlucoseUnit
}

const Chart: React.FunctionComponent<ChartProps> = ({
  data,
  timeRange,
  highThresh,
  lowThresh,
  glucoseUnits
}) => {
  const now = new Date().getTime()
  const theme = useTheme()

  const tickFormatter = (timeStr: string | undefined) => {
    if (!timeStr) {
      return 'Unknown'
    }

    const time = Number.parseInt(timeStr)
    if (Number.isNaN(time)) {
      return 'Unknown'
    }

    if (time === now) {
      return 'Now'
    }

    return formatDateTime(time)
  }

  return (
    <ResponsiveContainer width="100%" aspect={2}>
      <ScatterChart margin={{bottom: 15, right: 15 }}>
        <ReferenceArea
          y1={highThresh}
          fill="lightyellow"
          shape={<Rectangle radius={[10, 10, 0, 0]} />}
        />
        <ReferenceArea y1={lowThresh} y2={highThresh} />
        <ReferenceArea
          y1={0}
          y2={lowThresh}
          fill="lightsalmon"
          shape={<Rectangle radius={[0, 0, 10, 10]} />}
        />
        <XAxis
          label={<Label value="Time (24 hours)" position="bottom" offset={0} fontSize={14}/>}
          type="number"
          dataKey="systemTime"
          domain={[
            (dataMin: number) => dataMin - 100000 * getScale(timeRange),
            (dataMax: number) => dataMax + 100000 * getScale(timeRange),
          ]}
          ticks={getXTicks(timeRange, now)}
          tickFormatter={tickFormatter}
          axisLine={false}
          style={{ fill: theme.palette.text.primary }}
        />
        <YAxis
          label={<Label value={"CGM " + (glucoseUnits === GlucoseUnit.MGDL ? "(mg/dL)" : "(mmol/L)")} position={{x: 65, y: 100}} offset={10} angle={270} fontSize={14}/>}
          type="number"
          dataKey="value"
          domain={[0, 24]}
          ticks={[convertEgvSafe(39, glucoseUnits), convertEgvSafe(100, glucoseUnits), convertEgvSafe(200, glucoseUnits), convertEgvSafe(300, glucoseUnits), convertEgvSafe(400, glucoseUnits)]}
          orientation="right"
          axisLine={false}
          style={{ fill: theme.palette.text.primary }}
        />
        <Tooltip
          isAnimationActive={false}
          cursor={false}
          content={<EgvTooltip />}
        />
        <Scatter
          data={[...data.slice(0, data.length - 1)]}
          shape={<Dot r={2} />}
          isAnimationActive={false}
        />
        <Scatter
            data={[...data.slice(data.length - 1)]}
            shape={<Circle />}
            isAnimationActive={false}
        />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

const Circle: React.FC = (props) => {
  const excludes: Array<string> = ['systemTime', 'xAxis', 'yAxis', 'zAxis', 'tooltipPayload', 'tooltipPosition']
  const mutant: any = {...props}
  Object.keys(mutant)
      .filter(key => excludes.includes(key))
      .forEach(key => delete mutant[key])
  return (<circle {...mutant} r={4} stroke="black" strokeWidth={2} fill="white" />)
}

export default Chart
