import React, {useCallback, useState} from 'react';
import './App.css';
import SCREENS from './utils/Screens';
import SubjectCollection from './components/SubjectCollection/SubjectCollection';
import {CognitoUserAmplify} from '@aws-amplify/ui/dist/types/types/authenticator/user'
import "@aws-amplify/ui-react/styles.css";
import Resize from "./components/Alerts/Resize";
import Browser from "./components/Alerts/Browser";
import AddSubject from "./components/AddSubject/AddSubject";
import {CreateUserSiteMutation, CreateUserSiteMutationVariables, GlucoseUnit, Site, Subject} from "./@dexbasal";
import TitrationReview from "./components/TitrationReview/TitrationReview";
import {Alert, AppBar, Button, Container, Divider, Toolbar, Typography} from "@mui/material";
import {Auth} from 'aws-amplify';
import IdleTimer from 'react-idle-timer';
import {Logout} from "@mui/icons-material";
import DexbasalAPI from "./api/DexbasalApi";
import reactAnalytics, {trackError, trackEvent} from "./analytics/ReactAnalytics";
import useWindowDimensions from "./utils/WindowDimensions";
import AddUser from "./components/AddUser";
import ListUsers from "./components/ListUsers";
import {listUsersInGroup} from "./utils/Staff";
import ManageSites from "./components/ManageSites";
import AddSite from "./components/AddSite";
import UpdateSite from "./components/UpdateSite";
import {createUserSite} from "./graphql/base/mutations";
import {SiteLookup, UserSiteLookup} from "./utils/Interfaces";
import {handleOnActive} from "./utils/IdleUtils";

const MAX_WIDTH: number = 1024
const MAX_HEIGHT: number = 768

export interface IAppProps {
  user: CognitoUserAmplify | undefined
  debugApi: boolean,
  api: DexbasalAPI
}


export interface User {
  Attributes: [
    {
      "Name": string,
      "Value": string
    }
  ],
  Enabled: boolean,
  PreferredMfaSetting?: string
  MFAOptions?: [
    {
      "AttributeName": string,
      "DeliveryMedium": string
    }
  ],
  UserMFASettingList?: string[]
  UserCreateDate: number,
  UserLastModifiedDate: number,
  Username: string,
  UserStatus: string
}

export interface Group {
  CreationDate: number
  Description: string
  GroupName: string
  LastModifiedDate: number
  Precedence: number
  RoleArn: string
  UserPoolId: string
}

export interface ListGroupsResponse {
  NextToken?: string
  Groups: Group[],
}

export interface Users {
  username: string
  email: string
  groups: string[]
}

export interface ListUsersInGroupResponse {
  NextToken?: string,
  Users: User[]
}

const USER_GROUPS = ['DexBasalStaff', 'ClinicalManager', 'ClinicalSupport', 'Unauthorized']
const STAFF_GROUPS = ['DexBasalStaff', 'ClinicalManager', 'ClinicalSupport']

const App: React.FunctionComponent<IAppProps> = (props) => {

  const DISCLAIMER = "CAUTION - Investigational device. Limited by Federal (or United States) law to investigational use."
  const resize = useWindowDimensions();
  const {api, debugApi} = props;

  function logout() {
    trackEvent("logout", {callback: reactAnalytics.reset});
    Auth.signOut();
  }

  const glucoseUnit = GlucoseUnit.MGDL;
  const groups: string[] | undefined = props.user?.getSignInUserSession()?.getAccessToken().decodePayload()['cognito:groups'];
  const isSupport = groups?.includes('ClinicalSupport') || false;
  const siteName = groups?.find((group: string) => !USER_GROUPS.includes(group));
  const operator = groups?.includes('DexBasalStaff');

  const [currentScreen, setCurrentScreen] = useState<SCREENS>(operator ? SCREENS.LIST_USERS : SCREENS.SUBJECT_OVERVIEW);
  const [networkError, setNetworkError] = useState<boolean>(false)
  const [subjects, setSubjects] = useState<Array<Subject>>()
  const [currentSubject, setCurrentSubject] = useState<Subject>();
  const [sites, setSites] = useState<Array<Site>>()
  const [currentSite, setCurrentSite] = useState<Site>();
  const [userSiteLookup, setUserSiteLookup] = useState<UserSiteLookup>({})
  const [siteLookup, setSiteLookup] = useState<SiteLookup>({})

  function handleNetworkError(error: boolean) {
    setNetworkError(error);
  }

  const loadSubjects = useCallback(() => {
    setNetworkError(false);
    api.listSubjects({}).then(results => {
      setSubjects(results);
      if(currentSubject) {
        results.forEach(r => {
          if(currentSubject.id === r.id) {
            setCurrentSubject(r);
          }
        });
      }
    }).catch(e => {
      if(debugApi) {
        console.error('Error during loadSubjectsFromApi from listSubjectsWithSortedRecommendationsDescending', e);
      }
      trackError("listSubjects", {function: "loadSubjectsFromApi"});
      setNetworkError(true);
      setSubjects([]);
    });
  }, [api, debugApi, currentSubject, setCurrentSubject])

  const loadSites = useCallback(() => {
    setNetworkError(false)
    api.listSites({}).then(results => {
      setSites(results.sort((a, b) => a.displayId.localeCompare(b.displayId)))
      setSiteLookup((prev) => {
        results.forEach(({id, displayId}) => {
          prev[id] = displayId
        })
        return prev
      })
    }).catch(e => {
      if(debugApi) {
        console.error('Error during loadSitesFromApi from listSites', e);
      }
      trackError("listSites", {function: "loadSitesFromApi"});
      setNetworkError(true);
      setSites([]);
      setSiteLookup({})
    })
  }, [api, debugApi])

  const loadUserSites = useCallback(() => {
    setNetworkError(false)
    api.listUserSites({}).then(results => {
      setUserSiteLookup((prev) => {
        results.forEach(({user, site}) => {
          prev[user] = site
        })
        return prev
      })
    }).catch(e => {
      if (debugApi) {
        console.error('Error during listUserSites from loadSites', e)
      }
      trackError('listUserSites', {function: 'loadUserSitesFromApi'})
      setNetworkError(true)
      setUserSiteLookup({})
    })
  }, [api, debugApi])

  const handleNavClick = useCallback((navWindow:  SCREENS) => {
    if(currentScreen !== navWindow) {
      setCurrentScreen(navWindow)
    }
  }, [setCurrentScreen, currentScreen]);

  const handleAddSubjectClick = useCallback(() => {
    handleNavClick(SCREENS.ADD_SUBJECT)
  }, [handleNavClick])

  const handleSubjectClick = useCallback((subject: Subject) => {
    if(!currentSubject || currentSubject.id !== subject.id) {
      setCurrentSubject(subject);
    }
      handleNavClick(SCREENS.REVIEW_TITRATION)
  }, [handleNavClick, setCurrentSubject, currentSubject])

  const handleSubjectAdded = useCallback((subject: Subject) => {
    let oldSubjects: Subject[];
    if(subjects) {
      oldSubjects = subjects.slice();
    } else {
      oldSubjects = [];
    }
    setSubjects(oldSubjects.concat(subject))
  }, [subjects])

  const handleSubjectUpdate = useCallback((subject: Subject) => {
    let newSubjects: Subject[] = [];
    if(subjects) {
      for(const oldSubject of subjects) {
        if(oldSubject.id === subject.id) {
          newSubjects.push(subject);
        } else {
          newSubjects.push(oldSubject);
        }
      }
    } else {
      newSubjects.push(subject);
    }
    if(currentSubject && currentSubject.id === subject.id) {
      setCurrentSubject(subject);
    }
    setSubjects(newSubjects);
  }, [subjects, currentSubject])

  const handleSiteClick = useCallback((site: Site) => {
    if(!currentSite || currentSite.id !== site.id) {
      setCurrentSite(site);
    }
    handleNavClick(SCREENS.UPDATE_SITE)
  }, [handleNavClick, setCurrentSite, currentSite])

  const handleSiteAdded = useCallback((site: Site) => {
    let oldSites: Site[];
    if(sites) {
      oldSites = sites.slice();
    } else {
      oldSites = [];
    }
    setSites(oldSites.concat(site))
  }, [sites])

  const handleSiteUpdate = useCallback((site: Site) => {
    let newSites: Site[] = [];
    if(sites) {
      for(const oldSite of sites) {
        if(oldSite.id === site.id) {
          newSites.push(site);
        } else {
          newSites.push(oldSite);
        }
      }
    } else {
      newSites.push(site);
    }
    if(currentSite && currentSite.id === site.id) {
      setCurrentSite(site);
    }
    setSites(newSites);
  }, [sites, currentSite])

  const [users, setUsers] = useState<Users[]|undefined>();

  const handleLoadStaff = useCallback(async (onError: (e: any) => void) => {
    const emptyUsers = () => setUsers([]);
    try {
      const loadedMembers: Map<string, Users> = new Map();
      for(const groupName of USER_GROUPS) {
        const results = await(listUsersInGroup(groupName));
        if(results.Users.length > 0) {
          results.Users.forEach(u => {
            const username = u.Username;
            let member = loadedMembers.get(username);
            if(member) {
              if(!member.groups.includes(groupName)) {
                member.groups.push(groupName);
              }
            } else {
              loadedMembers.set(username, {
                username,
                groups: [groupName],
                email: u.Attributes.filter(o => o.Name === 'email')[0].Value
              });
            }
          });
        }
      }
      return setUsers(Array.from(loadedMembers.values()));
    } catch (e: any) {
      onError(e)
    }
    emptyUsers()
  }, [setUsers])

  const handleUserRemoved = useCallback((username: string) => {
    setUsers(s => s ? s.filter(s => s.username !== username) : [])
  }, [setUsers])

  const handleUserAdded = useCallback((user: User, groups: string[], siteId: string) => {
    const newMember: Users = {
      username: user.Username,
      email: user.Attributes.filter(o => o.Name === 'email')[0].Value,
      groups,
    }
    setUsers(s => s ? s.concat(newMember) : [newMember])
    if (siteId) {
      setUserSiteLookup((prev) => {
        prev[user.Username] = siteId
        return prev
      })

      let variables: CreateUserSiteMutationVariables = {
        input: {
          user: user.Username,
          site: siteId,
        }
      }

      const query = api.invoke<CreateUserSiteMutation, CreateUserSiteMutationVariables>(createUserSite, variables)
      query.then((_) => {
        handleNetworkError(false)
      }).catch((e) => {
        if (debugApi) {
          console.log('Error during addStaff from createUserSite', e)
        }
        handleNetworkError(true)
        trackError("createUserSite", {function: "handleUserAdded"})
      })
    }
  }, [api, debugApi, setUsers, setUserSiteLookup])

  const handleUserUpdated = useCallback((user: User, groups: string[]) => {
    const updatedMember: Users = {
      username: user.Username,
      email: user.Attributes.filter(o => o.Name === 'email')[0].Value,
      groups: groups
    }
    setUsers(s => s ? s.filter(m => m.username !== user.Username).concat(updatedMember) : [updatedMember])
  }, [setUsers])

  const renderView = useCallback(() => {
    // Max height is the 2 nav bars on top some margin and padding and the footer
    const maxHeight = resize.height < MAX_HEIGHT ? resize.height - (256) - (networkError ? 88 : 0) - (resize.height < MAX_HEIGHT ? 60: 0) - (resize.width < MAX_WIDTH ? 60:0) : -1;

    if(operator && props.user) {
      switch (currentScreen) {
        default:
        case SCREENS.LIST_USERS:
          return <ListUsers currentUserId={props.user.getUsername()}
                            loadSites={loadSites}
                            loadUsers={handleLoadStaff}
                            loadUserSites={loadUserSites}
                            maxHeight={maxHeight}
                            onNavClick={handleNavClick}
                            onUserRemoved={handleUserRemoved}
                            onUserUpdated={handleUserUpdated}
                            siteLookup={siteLookup}
                            users={users}
                            userSiteLookup={userSiteLookup}
          />
        case SCREENS.ADD_USER:
          return <AddUser loadSites={loadSites}
                           maxHeight={maxHeight}
                           onNavClick={handleNavClick}
                           onUserAdded={handleUserAdded}
                           staffGroups={STAFF_GROUPS}
                           sites={sites!}/>
        case SCREENS.MANAGE_SITES:
          return <ManageSites loadSites={loadSites} maxHeight={maxHeight} networkError={networkError} onNavClick={handleNavClick} onSiteClick={handleSiteClick} sites={sites}/>
        case SCREENS.ADD_SITE:
          return <AddSite api={api} debugApi={debugApi} onNetworkError={handleNetworkError} maxHeight={maxHeight} networkError={networkError} onNavClick={handleNavClick} onSiteAdded={handleSiteAdded} />
        case SCREENS.UPDATE_SITE:
          return <UpdateSite site={currentSite} onNavClick={handleNavClick} networkError={networkError} onNetworkError={handleNetworkError} onSiteUpdate={handleSiteUpdate} debugApi={debugApi} api={api} maxHeight={maxHeight}/>
      }
    } else if(siteName && !operator) {
      switch (currentScreen) {
        default:
        case SCREENS.SUBJECT_OVERVIEW:
          return <SubjectCollection onSubjectClick={handleSubjectClick} onAddSubjectClick={handleAddSubjectClick} loadSubjects={loadSubjects} networkError={networkError} onNetworkError={handleNetworkError} subjects={subjects} onSubjectUpdate={handleSubjectUpdate} debugApi={debugApi} api={api} maxHeight={maxHeight}/>
        case SCREENS.ADD_SUBJECT:
          return <AddSubject onNavClick={handleNavClick} networkError={networkError} onNetworkError={handleNetworkError} onSubjectAdded={handleSubjectAdded} onSubjectUpdate={handleSubjectUpdate} site={siteName} debugApi={debugApi} api={api} maxHeight={maxHeight}/>
        case SCREENS.REVIEW_TITRATION:
          if(!currentSubject) {
            setCurrentScreen(SCREENS.SUBJECT_OVERVIEW)
            return;
          }
          return <TitrationReview onNavClick={handleNavClick} subject={currentSubject} networkError={networkError} onNetworkError={handleNetworkError} onSubjectUpdate={handleSubjectUpdate} isSupport={isSupport} debugApi={debugApi} glucoseUnit={glucoseUnit} api={api} maxHeight={maxHeight} loadSubjects={loadSubjects}/>
      }
    } else {
      return (
          <Container disableGutters={true} maxWidth={false}>
            <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
              <Toolbar>
                <Typography variant={'h6'} sx={{flexGrow: 1}}>
                  No Permissions
                </Typography>
              </Toolbar>
            </AppBar>
            <Container maxWidth={'md'} style={{maxHeight: maxHeight === -1 ? 'none' : maxHeight, overflow:'auto'}}>
              <Alert color={'warning'} sx={{marginTop: 5}}>Ask a site owner to grant you permissions</Alert>
            </Container>
          </Container>
      )
    }
  }, [api, currentScreen, currentSubject, debugApi, glucoseUnit, handleAddSubjectClick, handleLoadStaff, handleNavClick, handleUserAdded, handleUserRemoved, handleUserUpdated, handleSubjectAdded, handleSubjectClick, handleSubjectUpdate, isSupport, loadSites, loadSubjects, loadUserSites, networkError, operator, props.user, resize.height, resize.width, siteName, siteLookup, users, subjects, sites, userSiteLookup, currentSite, handleSiteAdded, handleSiteClick, handleSiteUpdate])

  return (
    <Container disableGutters={true} maxWidth={false} sx={{minWidth: '800px'}} className='App'>
      <IdleTimer
        timeout={1000 * 60 * 10}
        onIdle={logout}
        onActive={handleOnActive}
        onAction={handleOnActive}
        debounce={250}
        events={['click', 'touchstart', 'keypress', 'mousemove']}
      />
      <Resize/>
      <Browser/>
      <AppBar color={'primary'} position={'static'}>
        <Toolbar>
          <Typography component={'div'} variant={'h6'} sx={{flexGrow: 1, textAlign: 'left'}}>
            <strong>DexBasal-Study</strong>
            <Typography component={'div'} variant={'subtitle2'}>
              {DISCLAIMER}
            </Typography>
          </Typography>
          <Button variant={'outlined'}
                  color={'inherit'}
                  endIcon={<Logout/>}
                  onClick={logout}>
            Sign out
          </Button>
        </Toolbar>
      </AppBar>

      <Container
        component={'main'}
        disableGutters={true}
        maxWidth={false}
      >
        {renderView()}
      </Container>

      <Container component={'footer'} sx={{minHeight:96, maxHeight:96}}>
        <Divider sx={{marginTop: 2, marginBottom: 2}}/>
        <Typography variant={'caption'}>
          DexBasal-Study v{process.env.REACT_APP_VERSION} Distributed by Dexcom Inc., 6340 Sequence Drive San Diego, CA 92121
        </Typography>
        <Typography paragraph={true} color={'red'} variant={'overline'} marginBottom={0}>
          {DISCLAIMER}
        </Typography>
      </Container>
    </Container>
  );
}

export default App;
