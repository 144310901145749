import {
  DataRange,
  DOWNLOAD_STATE,
  Egv,
  Event,
  FlatEgv,
  FlatEvent,
  FlatRecord, GatherDataGenerator,
  ListEgvsQuery,
  ListEventsQuery,
  QueryAndProcessFn, QueryAndProcessParams,
  QueryAndProcessRawFn,
  Subject
} from "../@dexbasal";
import {
  dateTimeFromUtcSecondsSinceEpoch,
  dateTimeStringToSecondsSinceEpoch,
  dateTimeStringToSecondsSinceEpochAws,
} from "./Utils";

export const REQUEST_WINDOW_SECONDS = 30 * 24 * 60 * 60 // 30 days
export const MAX_PRE_DURATION = 60 * 24 * 60 * 60 // 60 days

export const gatherEvgGenerator: GatherDataGenerator<ListEgvsQuery, FlatEgv> = (params) => {
  const generated: QueryAndProcessFn<ListEgvsQuery, FlatEgv> = (_p: QueryAndProcessParams<FlatEgv>) =>  {
    return params.gather({..._p, getData: params.getData})
  }
  return generated;
}

export const gatherEventsGenerator: GatherDataGenerator<ListEventsQuery, FlatEvent> = (params) => {
  const generated: QueryAndProcessFn<ListEventsQuery, FlatEvent> = (_p: QueryAndProcessParams<FlatEvent>) =>  {
    return params.gather({..._p, getData: params.getData})
  }
  return generated;
}

export const queryAndProcessEgvs: QueryAndProcessRawFn<ListEgvsQuery, FlatEgv> = ({
  subjectId,
  mark,
  start,
  onData,
  debugApi,
  getData
}) => {
    const startTime = (mark - REQUEST_WINDOW_SECONDS < start) ? start : mark - REQUEST_WINDOW_SECONDS

    const egvsQuery = getData({
        id: subjectId,
        startDate: dateTimeFromUtcSecondsSinceEpoch(startTime),
        endDate: dateTimeFromUtcSecondsSinceEpoch(mark),
        debugApi: debugApi
    })
    egvsQuery.then(response=> {
        if (
            response.data?.listEgvs?.response?.records
            && response.data?.listEgvs?.response?.records.length > 0
            && response.data?.listEgvs?.response?.recordType
            && response.data?.listEgvs?.response?.recordVersion
            && response.data?.listEgvs?.response?.userId
        ) {
            const egvs = response.data.listEgvs.response.records as Array<Egv>;
            const {recordType, recordVersion, userId} = response.data.listEgvs.response;
            const flatRecord: FlatRecord = {subjectId, recordType, recordVersion, userId }
            egvs.sort((a, b) => dateTimeStringToSecondsSinceEpoch(a.systemTime) - dateTimeStringToSecondsSinceEpoch(b.systemTime))
            onData(egvs.map(egv => ({...flatRecord, ...egv})))
        }
    })  // No catch here so it bubbles up to the Promise all

    return egvsQuery
}


export const queryAndProcessEvents: QueryAndProcessRawFn<ListEventsQuery, FlatEvent> = ({
  subjectId,
  mark,
  start,
  onData,
  debugApi,
  getData
}) => {
    const startTime = (mark - REQUEST_WINDOW_SECONDS < start) ? start : mark - REQUEST_WINDOW_SECONDS

    const eventsQuery = getData({
        id: subjectId,
        startDate: dateTimeFromUtcSecondsSinceEpoch(startTime),
        endDate: dateTimeFromUtcSecondsSinceEpoch(mark),
        debugApi
    })
    eventsQuery.then(response=> {
        if (
            response.data?.listEvents?.response?.records
            && response.data?.listEvents?.response?.records.length > 0
            && response.data?.listEvents?.response?.recordType
            && response.data?.listEvents?.response?.recordVersion
            && response.data?.listEvents?.response?.userId
        ) {
            const events = response.data.listEvents.response.records as Array<Event>
            const {recordType, recordVersion, userId} = response.data.listEvents.response;
            const flatRecord: FlatRecord = {subjectId, recordType, recordVersion, userId }
            events.sort((a, b) => dateTimeStringToSecondsSinceEpoch(a.systemTime) - dateTimeStringToSecondsSinceEpoch(b.systemTime))
            onData(events.map(event => ({...flatRecord, ...event})))
        }
    })  // No catch here so it bubbles up to the Promise all

  return eventsQuery
}

export const gatherDexcomDataFromApi = <R,F extends FlatRecord> (
  dataRange: DataRange,
  onData: (data: F[]) => void,
  stateSetter: (state: DOWNLOAD_STATE) => void,
  queryGenerator: QueryAndProcessFn<R,F>,
  subject: Subject,
  debugApi: boolean,
) => {
    // End is the time closest to current time (basically it is now, if the subject is still titrating)
    let end = dateTimeStringToSecondsSinceEpoch(dataRange.end.systemTime)
    const start = dateTimeStringToSecondsSinceEpochAws(subject.createdAt) - MAX_PRE_DURATION

    let mark = end
    let loop = true
    let promises = []

    while (loop) {
      const query = queryGenerator({subjectId: subject.id, mark, start, onData, debugApi})
      promises.push(query)

      // Determine whether to continue the loop; move the mark back 30 days.
      loop = (mark - start >= REQUEST_WINDOW_SECONDS)
      mark = mark - REQUEST_WINDOW_SECONDS
    }

    Promise.all(promises).then(() => {
      stateSetter(DOWNLOAD_STATE.COMPLETE)
    }).catch((e) => {
      console.error(e)
      stateSetter(DOWNLOAD_STATE.ERROR)
    })
}

export const arrayToCsv = (data: Array<{[key: string]: any}>) => {
    const headers: String[] = []
    const rows = data.map((row) => {
        const flattenedRow: any = {}
        Object.keys(row).map((basePath) => {
            const cell = row[basePath]
            if (typeof cell === 'object' && cell !== null && !Array.isArray(cell)) {
                return Object.keys(cell).forEach((key) => {
                    const extendedPath = basePath + '.' + key
                    if (headers.indexOf(extendedPath) === -1) {
                        headers.push(extendedPath)
                    }
                    return flattenedRow[extendedPath] = cell[key]
                })
            } else {
                if (headers.indexOf(basePath) === -1) {
                    headers.push(basePath)
                }
                return flattenedRow[basePath] = cell
            }
        })
        return flattenedRow
    }).map((row) => Object.values(row)
        .map(v => v ? `"${v}"` : v) // if the value is null, don't quote it
        .join(',')
    )
    rows.unshift(headers.map((header) => `"${header}"`).join(','))
    return rows.join('\n') + '\n'
}
