import React from "react";
import {Alert, AlertTitle} from "@mui/material";

const NetworkError: React.FunctionComponent<{ show: boolean }> = (props) => {
   return (
       <>
         {props.show && (
           <Alert id={'networkError'} variant={'filled'} severity={'error'} style={{justifyContent:"center", alignItems:"center"}}>
             <AlertTitle>Error</AlertTitle>
             There was an error. Check your network connection and try again.
           </Alert>
         )}
       </>
   )
}

export default NetworkError
