import {
    Algorithm,
    DOWNLOAD_STATE,
    FlatEgv,
    FlatEvent, ListAlgorithmsQuery, ListAlgorithmsQueryVariables,
    ModelSortDirection,
    Recommendation,
    RecommendationsBySubjectAndTimeCreatedQuery,
    RecommendationsBySubjectAndTimeCreatedQueryVariables,
    Subject
} from "../../@dexbasal";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {
    listAlgorithms,
    recommendationsBySubjectAndTimeCreated
} from "../../graphql/base/queries";
import {
    arrayToCsv,
    gatherDexcomDataFromApi, gatherEventsGenerator, gatherEvgGenerator,
    queryAndProcessEgvs,
    queryAndProcessEvents
} from "../../utils/Download";
import {trackError} from "../../analytics/ReactAnalytics";
import DexbasalAPI from "../../api/DexbasalApi";

interface IProps {
    subject: Subject,
    onSuccess: Function,
    onFail: Function,
    debugApi: boolean,
    api: DexbasalAPI
}

interface ILoading {
    egvLoading: DOWNLOAD_STATE,
    eventLoading: DOWNLOAD_STATE,
}

/**
 * All requests, except those made to the /dataRange endpoint, require two
 *   query parameters—startDate and endDate—that specify a time window.
 *   This window can be a maximum of 30 days, and any request with a time
 *   window greater than 30 days will return a 400 (Bad Request) error.
 */

const allIdle = (loadingState: ILoading):boolean => {
    return (
        loadingState.eventLoading === DOWNLOAD_STATE.IDLE &&
        loadingState.egvLoading === DOWNLOAD_STATE.IDLE
    )
}

const allFinished = (loadingState: ILoading):boolean => {
    return (
      (loadingState.eventLoading === DOWNLOAD_STATE.COMPLETE || loadingState.eventLoading === DOWNLOAD_STATE.ERROR) &&
      (loadingState.egvLoading === DOWNLOAD_STATE.COMPLETE || loadingState.egvLoading === DOWNLOAD_STATE.ERROR)
    )
}

const anyError = (states: Array<DOWNLOAD_STATE>):boolean => {
    return states.includes(DOWNLOAD_STATE.ERROR)
}

export const downloadCsv = (basename: String, data: Array<{ [key: string]: any }>) => {
    const csv = arrayToCsv(data)
    const blob = new Blob([csv], {type: 'text/csv'})
    const encodedURI = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', encodedURI)
    link.setAttribute('download', `${basename}.csv`)
    document.body.appendChild(link)
    link.click()
}

const Download: React.FunctionComponent<IProps> = ({subject, onSuccess, onFail, debugApi, api}) => {
    const [dexcomLoading, setDexcomLoading] = useState<ILoading>({
        egvLoading: DOWNLOAD_STATE.IDLE,
        eventLoading: DOWNLOAD_STATE.IDLE
    })
    const [recommendationLoading, setRecommendationLoading] = useState<DOWNLOAD_STATE>(DOWNLOAD_STATE.IDLE)
    const [nextRecommendationToken, setNextRecommendationToken] = useState<string>()
    const [recommendations, setRecommendations] = useState<Recommendation[]>([])
    const [algorithmLoading, setAlgorithmLoading] = useState<DOWNLOAD_STATE>(DOWNLOAD_STATE.IDLE)
    const [nextSubjectAlgorithmToken, setNextSubjectAlgorithmToken] = useState<string>()
    const [algorithms, setAlgorithms] = useState<Algorithm[]>([])
    const [egvs, setEgvs] = useState<FlatEgv[]>([])
    const [events, setEvents] = useState<FlatEvent[]>([])

    const merge = (a: { id: string }[], b: any[]) => {
        let ids = new Set(a.map((d: { id: string }) => d.id))
        return [...a, ...b.filter((d: { id: string }) => !ids.has(d.id))]
    }

    const setEgvState = (state: DOWNLOAD_STATE) => {
        setDexcomLoading(prevState => {
            return {
                ...prevState,
                egvLoading: state
            }
        })
    }

    const setEventState = (state: DOWNLOAD_STATE) => {
        setDexcomLoading(prevState => {
            return {
                ...prevState,
                eventLoading: state
            }
        })
    }

    useEffect(() => {
        if (!allIdle(dexcomLoading)) {
            return
        }

        setDexcomLoading({
            egvLoading: DOWNLOAD_STATE.LOADING,
            eventLoading: DOWNLOAD_STATE.LOADING,
        })

        const dataRangeQuery = api.getDataRange({id: subject.id, debugApi});

        // Run the EGV and Event collection routines when the data range response is gotten
        dataRangeQuery.then((r) => {
            // EGV Data
            if (r.data?.listDataRange?.response?.egvs) {
                gatherDexcomDataFromApi(
                  r.data.listDataRange.response.egvs,
                  (egvs) => setEgvs((v) => v.concat(egvs)),
                  setEgvState,
                  gatherEvgGenerator({gather: queryAndProcessEgvs, getData: (params) => api.getEgvs(params)}),
                  subject,
                  debugApi,
                )
            } else {
                setEgvState(DOWNLOAD_STATE.ERROR)
            }

            // Event Data
            if (r.data?.listDataRange?.response?.events) {
                gatherDexcomDataFromApi(
                    r.data.listDataRange.response.events,
                    (events) => setEvents((e) => e.concat(events)),
                    setEventState,
                    gatherEventsGenerator({gather: queryAndProcessEvents, getData: (params) => api.getEvents(params)}),
                    subject,
                    debugApi
                )
            } else {
                setEventState(DOWNLOAD_STATE.ERROR)
            }
        }).catch((e) => {
            if(debugApi) {
                console.error('Error during download data', e);
            }
            setEgvState(DOWNLOAD_STATE.ERROR)
            setEventState(DOWNLOAD_STATE.ERROR)
            trackError("getDataRange");
        })
    }, [dexcomLoading, subject, setEgvs, setEvents, setRecommendations, debugApi, api])

    useEffect(() => {
        if(recommendationLoading === DOWNLOAD_STATE.IDLE) {
            setRecommendationLoading(DOWNLOAD_STATE.LOADING)

            const recommendationsQuery = api.invoke<RecommendationsBySubjectAndTimeCreatedQuery, RecommendationsBySubjectAndTimeCreatedQueryVariables>(recommendationsBySubjectAndTimeCreated, {
                subjectId: subject.id,
                timeCreated: {le: Math.floor(new Date().getTime() / 1000)},
                sortDirection: ModelSortDirection.DESC,
                nextToken: nextRecommendationToken
            })

            recommendationsQuery.then((response) => {
                let items = response.data?.recommendationsBySubjectAndTimeCreated?.items !== null && response.data?.recommendationsBySubjectAndTimeCreated?.items !== undefined ? response.data?.recommendationsBySubjectAndTimeCreated.items as [Recommendation] : [];
                if (response.data?.recommendationsBySubjectAndTimeCreated?.items.length) {
                    setRecommendations(r => merge(r, items))
                }
                if (response.data?.recommendationsBySubjectAndTimeCreated?.nextToken) {
                    setNextRecommendationToken(response.data?.recommendationsBySubjectAndTimeCreated?.nextToken)
                    setRecommendationLoading(DOWNLOAD_STATE.IDLE)
                } else {
                    setRecommendationLoading(DOWNLOAD_STATE.COMPLETE)
                }
            }).catch(() => {
                setRecommendationLoading(DOWNLOAD_STATE.ERROR)
                trackError("recommendationsBySubjectAndTimeCreated");
            })
        }
    }, [recommendationLoading, nextRecommendationToken, subject, setRecommendations, api])

    useEffect(() => {
        if(algorithmLoading === DOWNLOAD_STATE.IDLE) {
            setAlgorithmLoading(DOWNLOAD_STATE.LOADING)

            console.log("Getting algorithms ")

            const algorithmQuery = api.invoke<ListAlgorithmsQuery, ListAlgorithmsQueryVariables>(listAlgorithms, {
                nextToken: nextSubjectAlgorithmToken
            })

            algorithmQuery.then((response) => {
                let items = response.data?.listAlgorithms?.items !== null && response.data?.listAlgorithms?.items !== undefined ? response.data?.listAlgorithms.items as [Algorithm] : [];
                console.log(items)
                if (response.data?.listAlgorithms?.items.length) {
                    setAlgorithms(r => merge(r, items))
                }
                if (response.data?.listAlgorithms?.nextToken) {
                    setNextSubjectAlgorithmToken(response.data?.listAlgorithms?.nextToken)
                    setAlgorithmLoading(DOWNLOAD_STATE.IDLE)
                } else {
                    setAlgorithmLoading(DOWNLOAD_STATE.COMPLETE)
                }
            }).catch(() => {
                setAlgorithmLoading(DOWNLOAD_STATE.ERROR)
                trackError("subjectAlgorithmsBySubjectAndTimeCreated");
            })
        }
    }, [algorithmLoading, nextSubjectAlgorithmToken, subject, setAlgorithms, api])

    useLayoutEffect(() => {
        const subjectDisplay = subject.displayId || 'unknown';
        if (!allFinished(dexcomLoading) ||
          (recommendationLoading !== DOWNLOAD_STATE.COMPLETE && recommendationLoading !== DOWNLOAD_STATE.ERROR) ||
          (algorithmLoading !== DOWNLOAD_STATE.COMPLETE && algorithmLoading !== DOWNLOAD_STATE.ERROR)
        ) {
            return
        }

        if (anyError([dexcomLoading.egvLoading, dexcomLoading.eventLoading, recommendationLoading, algorithmLoading])) {
            onFail()
            return
        }

        if (recommendations.length) {
            downloadCsv(subjectDisplay + "-recommendation-export", recommendations.map(r => {
                const simpleRecommendation: Recommendation = {...r}
                // @ts-ignore
                delete simpleRecommendation.algorithm; // we just use algorithmId
                return simpleRecommendation;
            }))
        }

        if(algorithms.length) {
            downloadCsv(subjectDisplay+"-algorithm-export", algorithms)
        }

        if (egvs.length) {
            downloadCsv(subjectDisplay + "-egv-export", egvs)
        }

        if (events.length) {
            downloadCsv(subjectDisplay + "-event-export", events)
        }

        if (subject) {
            const simpleSubject: Subject = {...subject}
            delete simpleSubject.apiTryToken;       // ephemeral
            delete simpleSubject.recommendations;   // will be in recommendations csv
            delete simpleSubject.titratorAlgorithm; // titratorVersion is all we need
            downloadCsv(subjectDisplay + "-subject-export", [simpleSubject])
        }

        if (events.length || recommendations.length || egvs.length) {
            onSuccess()
        } else {
            onFail()
        }
        return () => {}
    }, [
        egvs,
        events,
        dexcomLoading,
        onFail,
        onSuccess,
        recommendations,
        recommendationLoading,
        subject,
        algorithmLoading,
        algorithms,
    ])

    return null
}

export default Download
