import {
    AppBar,
    Alert,
    Button,
    Container,
    ListItemText,
    Skeleton,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography
} from "@mui/material";
import {useEffect} from "react";
import SCREENS from "../utils/Screens";
import {Add, ArrowBack, Edit} from "@mui/icons-material";
import {Site} from "../@dexbasal";
import {SiteHandler} from "../utils/Interfaces";
import NetworkError from "./Alerts/NetworkError";
import {trackScreen} from "../analytics/ReactAnalytics";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface IProps {
    loadSites: () => void
    maxHeight: number
    networkError: boolean,
    onNavClick: (screen: SCREENS) => void
    onSiteClick: SiteHandler,
    sites : Site[] | undefined
}

const ManageSites: React.FunctionComponent<IProps> = (props: IProps) => {
    const {loadSites, maxHeight, networkError, onNavClick, onSiteClick, sites} = props
    const loading = !sites

    useEffect(() => {
        trackScreen({screen: SCREENS.MANAGE_SITES})
        if (loading) {
            loadSites()
        }
    })

    return (
        <Container disableGutters={true} maxWidth={false}>
            <AppBar color={'secondary'} position={'static'} sx={{marginBottom: 2}}>
                <Toolbar>
                    <Typography variant={'h6'} sx={{flexGrow: 1}}>
                        {SCREENS.MANAGE_SITES}
                    </Typography>
                    <Button
                        id={'backButton'}
                        variant={'outlined'}
                        color={'inherit'}
                        sx={{position: 'absolute', marginRight: 2}}
                        startIcon={<ArrowBack/>}
                        onClick={() => onNavClick(SCREENS.LIST_USERS)}>
                        Back</Button>
                    <Button variant={'contained'}
                            color={'info'}
                            onClick={() => onNavClick(SCREENS.ADD_SITE)}
                            endIcon={<Add/>}>
                        Add Site
                    </Button>
                </Toolbar>
            </AppBar>

            <Container component={'form'} maxWidth={'md'} sx={{marginTop: 2}} style={{maxHeight: maxHeight === -1 ? 'none' : maxHeight, overflow:'auto'}}>
                {loading ? (
                    [...Array(20)].map((_, idx) =>
                        <Stack key={idx} direction={'row'} sx={{marginTop: 2}}>
                            <Skeleton width={32} height={32} variant={'circular'} sx={{marginLeft: '16px', marginRight: '24px'}}/>
                            <Skeleton>
                                <Typography>fcfd4020-911a-4edb-a1b7-385d467a60fd</Typography>
                            </Skeleton>
                        </Stack>
                    )
                ):(
                    sites.length > 0 ? (
                            <TableContainer>
                                <Table sx={{maxWidth: 'md', maxHeight: maxHeight === -1 ? 'none' : maxHeight, overflow:'auto'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Display ID</TableCell>
                                            <TableCell>Time Zone</TableCell>
                                            <TableCell>Edit Site</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sites.map((site) => {
                                            return (
                                                <StyledTableRow key={site.id}>
                                                    <TableCell>
                                                        <ListItemText primary={site.displayId}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <ListItemText primary={site.timezone}/>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant={'text'}
                                                                color={'info'}
                                                                endIcon={<Edit/>}
                                                                onClick={() => onSiteClick(site)}
                                                        >edit</Button>
                                                    </TableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    ):(
                        <Alert color={'warning'} sx={{marginTop: 5}}>Add Sites to get started.</Alert>
                    )
                )}

                <NetworkError show={networkError}/>
            </Container>
        </Container>
    )
}

export default ManageSites
