import {Avatar, Badge, Tooltip, TooltipProps, Typography } from "@mui/material"
import {getAlgorithmOptions} from "./AlgorithmOptions";
import {Algorithm} from "../@dexbasal";

interface IProps  {
  titratorAlgorithm: Algorithm | null
  placement?: TooltipProps["placement"]
}

const AlgorithmIcon: React.FunctionComponent<IProps> = (props) => {
  const algorithmOptions = props.titratorAlgorithm ? (getAlgorithmOptions().find(algo => props.titratorAlgorithm && (algo.algorithm === props.titratorAlgorithm.version))) : null
  const algorithmVersion = props.titratorAlgorithm ? props.titratorAlgorithm.runnerVersion : "---"

  const placement = props.placement ? props.placement : 'left'

  return (
    <>
      {algorithmOptions &&
        <Badge
          invisible={!algorithmOptions.showBadge}
          overlap={'rectangular'}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
          badgeContent={
            <Avatar sx={{width:15, height:15, border:'1px solid lightgray', bgcolor:'white'}}>
              <Typography sx={{fontSize:9, color:'gray', paddingTop:'1px'}}>
                {algorithmOptions.badgeVersion}
              </Typography>
            </Avatar>
          }
        >
          <Tooltip
              title={"DexBasal-" + algorithmOptions.description+ " " + algorithmVersion}
              placement={placement}
          >
            <Avatar variant={'rounded'} sx={{width:32, height:32, bgcolor:algorithmOptions.color}}>
              {algorithmOptions.icon}
            </Avatar>
          </Tooltip>
        </Badge>
      }
    </>
  )
}

export default AlgorithmIcon