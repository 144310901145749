import {
  ListSubjectsQuery,
  ModelSubjectFilterInput,
  Subject,
  GraphQLAPI,
  ListEgvsQuery,
  ListEventsQuery,
  ListDataRangeQuery,
  ListSubjectsQueryVariables,
  ListEgvsQueryVariables,
  ListEventsQueryVariables,
  ListDataRangeQueryVariables,
  GetDataRangeParams,
  GraphQLResponse,
  GetFnParams,
  PostBasalRecommendationParams,
  PostBasalRecommendationMutationVariables,
  PostBasalRecommendationMutation,
  TitrateMutation, TitrateMutationVariables,
  Site,
  ListSitesQuery,
  ListSitesQueryVariables,
  UserSite,
  ListUserSitesQueryVariables,
  ListUserSitesQuery
} from "../@dexbasal";
import {listSubjectsWithSortedRecommendationsDescending} from "../graphql/custom/queries";
import {listDataRange, listEgvs, listEvents, listSites, listUserSites} from "../graphql/base/queries";
import {postBasalRecommendation, titrate} from "../graphql/base/mutations";

export default class DexbasalAPI {
  private readonly api: GraphQLAPI
  constructor(props: {api: GraphQLAPI}) {
        this.api = props.api;
  }

  async listSites(params: {nextToken?: string}): Promise<Array<Site>> {
    const {nextToken} = params
    const response = await this.api<ListSitesQuery, ListSitesQueryVariables>(listSites, {nextToken})
    if (response.data?.listSites?.items !== undefined) {
      const data: Site[] = []
      for (const s of response.data.listSites.items) {
        if (s !== null) {
          data.push(s)
        }
      }
      const nextToken = response.data.listSites.nextToken
      if (nextToken) {
        return data.concat(await this.listSites({nextToken}))
      } else {
        return data;
      }
    } else {
      return []
    }
  }

  async listUserSites(params: {nextToken?: string}): Promise<Array<UserSite>> {
    const {nextToken} = params
    const response = await this.api<ListUserSitesQuery, ListUserSitesQueryVariables>(listUserSites, {nextToken})
    if (response.data?.listUserSites?.items !== undefined) {
      const data: UserSite[] = []
      for (const uS of response.data.listUserSites.items) {
        if (uS !== null) {
          data.push(uS)
        }
      }
      const nextToken = response.data.listUserSites.nextToken
      if (nextToken) {
        return data.concat(await this.listUserSites({nextToken}))
      } else {
        return data
      }
    } else {
      return []
    }
  }

  async listSubjects(params: {filter?: ModelSubjectFilterInput, nextToken?: string}): Promise<Array<Subject>> {
    const {filter, nextToken} = params;
    const response = await this.api<ListSubjectsQuery, ListSubjectsQueryVariables>(listSubjectsWithSortedRecommendationsDescending, {nextToken, filter})
    if(response.data?.listSubjects?.items !== undefined) {
      const data: Subject[] = [];
      for(const s of response.data.listSubjects.items) {
        if(s !== null) {
          data.push(s);
        }
      }
      const nextToken = response.data.listSubjects.nextToken;
      if(nextToken) {
        return data.concat(await this.listSubjects({filter, nextToken}));
      } else {
        return data;
      }
    } else {
      return [];
    }
  }

  async getEgvs(params: GetFnParams): Promise<GraphQLResponse<ListEgvsQuery>> {
    const {id, startDate, endDate, retry, debugApi} = params;
    await this.pause(retry);
    const response = await this.api<ListEgvsQuery, ListEgvsQueryVariables>(listEgvs, {
      id,
      startDate,
      endDate,
      debug: debugApi
    })
    if(response.data?.listEgvs?.status !== 200) {
      if(retry && retry > 6) {
        throw new Error(`Cannot get Egvs. Status: ${response.data?.listEgvs?.status || 'unknown'}.`)
      }
      return this.getEgvs({id, startDate, endDate, retry: retry ? retry + 1 : 1, debugApi});
    }
    return response;
  }

  async getEvents(params: GetFnParams): Promise<GraphQLResponse<ListEventsQuery>>{
    const {id, startDate, endDate, retry, debugApi} = params;
    await this.pause(retry);
    const response = await this.api<ListEventsQuery, ListEventsQueryVariables>(listEvents, {
      id,
      startDate,
      endDate,
      debug: debugApi
    });
    if(response.data?.listEvents?.status !== 200) {
      if(retry && retry > 6) {
        throw new Error(`Cannot get events. Status: ${response.data?.listEvents?.status || 'unknown'}.`)
      }
      return this.getEvents({id, startDate, endDate, retry: retry ? retry + 1 : 1, debugApi});
    }
    return response;
  }

  async getDataRange(params: GetDataRangeParams): Promise<GraphQLResponse<ListDataRangeQuery>> {
    const {id, retry, debugApi} = params;
    await this.pause(retry);
    const response = await this.api<ListDataRangeQuery, ListDataRangeQueryVariables>(listDataRange, {id, debug: debugApi});
    if(response.data?.listDataRange?.status !== 200) {
      if(retry && retry > 6) {
        throw new Error(`Cannot get events. Status: ${response.data?.listDataRange?.status || 'unknown'}.`)
      }
      return this.getDataRange({id, retry: retry ? retry + 1 : 1, debugApi});
    }
    return response;
  }

  async postDose(params: PostBasalRecommendationParams): Promise<GraphQLResponse<PostBasalRecommendationMutation>> {
    const {id, retry, debugApi, totalDose, timeConfirmed} = params;
    await this.pause(retry);
    const response = await this.api<PostBasalRecommendationMutation, PostBasalRecommendationMutationVariables>(
        postBasalRecommendation, {id, debug: debugApi, totalDose, timeConfirmed}
    );
    if (response.data?.postBasalRecommendation?.status !== 200) {
      if(retry && retry > 6) {
        throw new Error(`Cannot post basal recommendation. Status: ${response.data?.postBasalRecommendation?.status || 'unknown'}.`);
      }
      return this.postDose({id, totalDose, timeConfirmed, retry: retry ? retry + 1 :  1, debugApi});
    }

    return response
  }

  async titrate(params: TitrateMutationVariables): Promise<GraphQLResponse<TitrateMutation>> {
    const response = await this.api<TitrateMutation, TitrateMutationVariables>(
        titrate, params
    );
    if (response.errors) {
      throw new Error(`Cannot trigger titrate. Status: ${response.data?.titrate?.statusCode || response.data.titrate?.errorMessage ? response.data.titrate.errorMessage: 'unknown'}.`);
    }

    return response
  }

  async pause(retry?: number) {
    if(retry) {
      return await new Promise(done => setTimeout(done, retry * 200));
    }
    return Promise.resolve();
  }

  invoke: GraphQLAPI = async (params, variables) => {
    return this.api(params, variables)
  }
}